<template>
  <div class="static-page">
    <Navbar />
    <div class="page-content">
      <div class="page-title">FAQ</div>
      <div class="page-subtitle">Fonctionnement de l'application</div>
      <div class="page-paragraphe">
        L’insee publie de façon mensuelle sur son site la liste des personnes
        décédées :
        <a
          href="https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/"
          target="_blank"
          >https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/</a
        >. Le système recherche, parmi la liste que vous envoyez, les personnes
        qui apparaissent aussi dans la liste de l’insee. Et c’est beaucoup plus
        compliqué qu’il n’y paraît...
      </div>
      <div class="page-subtitle" ref="what-data">
        Quelles données avons-nous besoin et pourquoi ?
      </div>
      <div class="page-paragraphe">
        L’algorithme croise pleins de données pour définir un rapprochement
        entre un individu de votre liste et un individu de la liste insee.
        Chaque correspondance retrouvée est assortie d’un score de concordance.
        Plus ce score est élevé, plus la concordance est forte.
        <br /><br />
        Voici en gras la liste des données utiles à l’algorithme pour rechercher
        des correspondances et pourquoi chacune d’entre elle est utilisée:
        <div class="page-note">
          (Les données à nous envoyer sont strictement limités à celles
          présentant un intérêt pour le service rendu , i.e. intérêt légitime
          pour le service rendu)
        </div>
        <br />
        <ul class="list-page">
          <li>
            <strong
              >Date de prochaine activité prévue dans l’établissement</strong
            >
            : pour définir si un patient est décédé alors qu’il a encore une
            activité prévue dans le futur, donc une activité à annuler.
          </li>
          <li>
            <strong>Date de décès</strong> : pour les patients que vous savez
            être décédés. Cette donnée est utilisée pour :
            <ul>
              <li>
                Lancer la recherche également sur ces patients pour voir si ils
                sont retrouvés et donc calculer les performances du système et
                vous aider à choisir le score seuil
              </li>
              <li>
                Entraîner le modèle de machine learning pour que le système
                s’adapte à vos données
              </li>
            </ul>
          </li>
          <li>
            <strong>Décès connu</strong> : oui quand vous savez que l’individu
            est décédé que vous en connaissiez ou non la date (utile pour la
            même raison que la date de décès connue ci dessus)
          </li>
          <li>
            <strong>Date de requête </strong>: date à laquelle vous avez extrait
            de votre système les informations que vous nous envoyez. Car les
            infos sont vraies à un instant t et changent chaque jour, nous avons
            donc besoin de savoir à quel instant ces infos sont vraies.
          </li>
          <li>
            <strong>Identification du médecin</strong> à prévenir en cas de
            décès. Complètement optionnel.
          </li>
          <li>
            <strong>Dix premiers chiffres du numéro de sécurité sociale</strong>
            : le NIR est aussi appelé numéro de sécurité sociale. Il contient 13
            chiffres. Pour des raisons de confidentialité, nous vous demandons
            de ne nous envoyer que les 10 premiers numéros (NIR 10). Tout
            fichier contenant ne serait-ce qu’un seul NIR complet (de 13
            chiffres) sera refusé en bloc par le système.<br />
            Les 10 premiers chiffres du NIR correspondent au sexe, mois et année
            de naissance et commune de naissance et ne contient donc pas
            d’information sensible.
          </li>
          <li>
            <strong>Date de naissance</strong>
          </li>
          <li>
            <strong>Dernier code postal d’habitation connu</strong>. Cette
            donnée est prise en compte par l'algorithme.<br />
            Si cette donnée n’est pas assez ou pas du tout renseignée dans le
            fichier que vous nous envoyez, l’algo utilisera à la place le code
            postal de votre établissement si vous l’avez renseigné. Si aucun des
            deux n’est disponible, le système fonctionnera quand même mais sera
            moins performant.
          </li>
          <li>
            <strong
              >Le numéro d’identification du patient dans votre
              établissement</strong
            >
            : complètement optionnel, juste pour vous faciliter la réintégration
            des infos retournées par notre solution dans votre système.
          </li>
          <li>
            <strong>Nom de famille</strong>
          </li>
          <li>
            <strong>Nom de famille alternatif</strong> : certains patients sont
            inscrit sous 2 noms, souvent des femmes avec un nom de femme marié
            et un nom de jeune fille, peut importe quel nom est choisi en
            principal et en alternatif.
          </li>
          <li>
            <strong>Prénom(s)</strong> : le premier suffit, mais vous pouvez en
            envoyer autant que vous voulez, the more the merrier!
          </li>
          <li>
            <strong>Date de dernières nouvelles</strong> : dernière date à
            laquelle le patient est venu dans l'établissement. Par définition un
            patient ne peut pas être décédé AVANT cette date mais forcément
            apres.
          </li>
          <li>
            <strong>Sexe</strong>
          </li>
        </ul>
        <br />
        Toutes ces informations ne sont pas indispensables mais plus il y a
        d’infos renseignées, plus le système est efficace et plus il retournera
        d’infos bien utiles.
      </div>
      <div class="page-subtitle">Exemple de données</div>
      <v-data-table
        :headers="headers"
        :items="exampleData"
        :disable-filtering="true"
        disable-pagination
        disable-sort
        dense
        class="elevation-1 my-5"
      >
      </v-data-table>
      <div class="page-subtitle">Format des données</div>
      <div class="page-paragraphe">
        <strong>Date</strong>
        <ul>
          <li>
            Format acceptés :
            <ul>
              <li>Sans séparateur, exemple : YYYYMMDD, DDMMYYYY</li>
              <li>Avec séparateur parmi : /, -, :, .</li>
            </ul>
          </li>
          <li>
            Formats non acceptés :
            <ul>
              <li>Date commençant le mois puis jour MMDDYYYY</li>
            </ul>
          </li>
        </ul>
        <br />
        <strong>Champs Booléens</strong><br />
        Tous les champs qui peuvent prendre les valeurs 0 ou 1 acceptent les
        formats (exemple individu connu décédé).
        <ul>
          <li>Binaires : 0 / 1</li>
          <li>Booléen : False / True</li>
        </ul>
        <br />
        <strong>Genre</strong><br />
        <ul>
          <li>
            Formats acceptés :
            <ul>
              <li>0 / 1 : 0 pour homme, 1 pour femme</li>
              <li>M / F</li>
              <li>H / F</li>
              <li>MR / MME (mr/mme, Mr/Mme)</li>
            </ul>
          </li>
          <li>
            Formats non acceptés :
            <ul>
              <li>1 / 2</li>
              <li>Autres...</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="page-subtitle">
        Quelles sont les données que nous enregistrons et pourquoi ?
      </div>
      <div class="page-paragraphe">
        La plateforme est susceptible d’utiliser et d’enregistrer certaines
        informations que vous lui envoyez.
        <br /><br />
        Mais <strong>uniquement pour les individus décédés</strong>.<br />
        Nous n’enregistrons jamais aucune information concernant les personnes
        vivantes.
        <br /><br />
        Pour les personnes décédées ( et exclusivement pour ces dernières), les
        informations susceptibles d’être enregistrées sont
        <strong>exclusivement limitées à celles-ci</strong> :
        <ul>
          <li>Nom</li>
          <li>Prénom</li>
          <li>Sexe</li>
          <li>Date_de_naissance</li>
          <li>commune_de_naissance</li>
          <li>commune_de_deces</li>
          <li>date_de_deces</li>
        </ul>
        <br />
        Ces informations sont des données publiques, consultables par tout
        internaute sur le site de l’insee car le RGPD ne concerne pas les
        personnes décédées. Par ailleurs, en France, le décès est une donnée
        publique, ce qui signifie que n’importe qui a le droit de savoir si
        n’importe est décédé sans avoir à justifier sa demande.
        <br /><br />
        Nous sommes susceptibles d’enregistrer et d’utiliser les données
        sus-mentionnées afin d’enrichir et de corriger la base des décès.
        <br /><br />
        Par exemple :
        <v-simple-table height="350px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">INSEE</th>
                <th class="text-left" style="color: red">
                  Données envoyées par vous
                </th>
                <th class="text-left" style="color: blue">
                  Données enregistrées par notre système
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in exampleInsee" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.insee }}</td>
                <td>{{ item.vous }}</td>
                <td>{{ item.nous }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br /><br />
        En utilisant ce service, vous acceptez que nous sommes susceptibles
        d’utiliser et de conserver les données susmentionnées.
      </div>

      <div class="page-subtitle">A quoi ça sert ?</div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/faq_dcd_solutions.png"
          alt="Fonctionnalités de l'application"
        />
      </figure>
      <div class="page-section">I. Recherche médicale</div>
      <div class="sub-section">
        1) Mise à jour des survies globales et disparition des perdus de vue
      </div>
      <div class="page-paragraphe">
        La patients inclus dans les études / cohortes / registres sont parfois
        perdus de vue : au bout d’un certain temps, on ne sait plus ce qu’ils
        deviennent.
        <br /><br />
        DCD permet de faire passer en quelques clics toutes les bases de données
        des patients français (ou la cohorte française des études
        internationales) et
        <strong
          >d’étendre les données de survie avec des données à jour et sans
          perdus de vue en survie globale.</strong
        >
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/extension_suivi_os.png"
          alt="Extension suivi OS par DCD"
        />
      </figure>
      <div class="sub-section">
        2) Analyse exploratoire de facteurs de survie
      </div>
      <div class="page-paragraphe">
        En tant que clinicien, j’ai l’impression que les patients qui présentent
        le facteur F est un facteur prédictif de survie.
        <br />
        Je passe ma liste de patient qui présentent F ou pas dans la solution
        DCD et je génére les courbes de survies correspondantes :
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/analyse_explo1.png"
          alt="Analyse Exploratoire de facteur de survie (1)"
        />
        <figcaption>
          <i
            >Mon sentiment est juste. F semble effectivement être un facteur
            pronostic de survie qui mérite d’être exploré. Il s’agit d’une bonne
            piste de recherche.</i
          >
        </figcaption>
      </figure>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/analyse_explo2.png"
          alt="Analyse Exploratoire de facteur de survie (2)"
        />
        <figcaption>
          <i
            >Mon sentiment n’est pas juste, c’était une fausse impression. F ne
            semble pas être un facteur pronostic de survie. C’est probablement
            une mauvaise piste de recherche.</i
          >
        </figcaption>
      </figure>
      <div class="page-paragraphe">
        DCD permet
        <strong
          >d’orienter la recherche par analyse exploratoire de facteurs de
          survie.</strong
        >
      </div>

      <div class="page-section">II. Etablissements de santé</div>
      <div class="sub-section">1) Identito-vigilance</div>
      <div class="page-paragraphe">
        Marquage visible des patients décédés dans les logiciels métiers. Ainsi
        plus de confusion possible entre un patient décédé et un patient
        vivant.<br />
        En supposant par exemple une prévalence de 30% de patients décédés dans
        une database d’établissement, l’utilisation de DCD permettrait de
        <strong>réduire le risque d’erreur d’identito-vigilance de 30%.</strong>
      </div>
      <div class="sub-section">2) Gestion des archives</div>
      <div class="page-paragraphe">
        La taille des dossiers médicaux des patients augmente de façon
        exponentielle. A titre d’exemple, le site statista estime que le volume
        de données médicales a été multiplié par 15 entre 2013 et 2020.<br />
        Cette augmentation de volume est supérieure à l’augmentation historique
        des capacités de stockage. En d’autres termes, l’allocation de toujours
        plus d’espace de stockage, en plus d’avoir un impact écologique non
        négligeable, n’est pas une solution pérenne au problème de
        l’augmentation exponentielle du volume des données médicales.<br />
        Une nouvelle stratégie est nécessaire.
        <br /><br />
        En général les dossiers des patients susceptibles d’avoir une activité
        dans l'hôpital sont stockés sur des systèmes d’accès direct et
        rapide.<br />
        Ceux qui ne sont plus susceptibles d’être consultés peuvent être stockés
        dans des systèmes à grosse capacité, moins coûteux mais aussi plus lents
        et plus difficile d’accès (stockage sur bande par ex).
        <br /><br />
        Finalement, la conservation du dossier n’est plus obligatoire dans
        <a
          href="https://www.legifrance.gouv.fr/jorf/id/JORFARTI000001911528"
          title="https://www.legifrance.gouv.fr/jorf/id/JORFARTI000001911528"
          target="_blank"
        >
          certaines conditions </a
        >. La destruction de tels dossiers peut être utile pour gagner de la
        place sur les serveurs pour les dossiers informatiques et dans les
        locaux d’archivages pour les dossiers papiers. Ces conditions
        nécessitent de connaître la date du décès et la date de dernière venue
        du patient dans l'hôpital. <br /><br />
        L’utilisation de DCD en renvoyant la liste des individus décédés et la
        liste des individus pour lesquels l’archivage est facultatif pourrait
        permettre pour les établissements d’avoir de règles de gestion
        automatique des archives :
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/gestion_archives.png"
          alt="Gestion des archives"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="page-paragraphe">
        DCD permet de
        <strong>mieux gérer son espace de stockage de données médicales</strong>
        dans un contexte d’explosion de leur quantité.
      </div>
      <div class="sub-section">3) Annulation des activités prévues</div>
      <div class="page-paragraphe">
        DCD permet de
        <strong
          >détecter les patients décédés pour lesquels une activité ultérieure
          au décès est prévue.</strong
        >
        <br />
        Cela permet d’annuler cette activité et d’attribuer le créneau à un
        autre patient.
      </div>
      <div class="sub-section">4) Retour expérience praticiens</div>
      <div class="page-paragraphe">cf 7.3</div>
      <div class="sub-section">5) Détection des homonymes</div>
      <div class="page-paragraphe">
        Les vrais homonymes sont à risque d’erreur.<br />
        Si par exemple, un établissement a dans son système d’information deux
        Jean Martin né le 08/09/56 et qu’on note dans le mauvais dossier
        l’allergie à la pénicilline, les conséquences peuvent être
        désastreuses.<br />
        Pour éviter cela, une solution consiste à mettre une alerte, alarme,
        notification à chaque fois qu’on ouvre un dossier qui possède un
        homonyme dans l’établissement.<br />
        Encore faut - il les identifier.
        <br /><br />
        DCD intégre une fonction de <strong>détection d’homonymes.</strong>
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/detection_homonymes.png"
          alt="Détection homonymes"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="sub-section">
        6) Détection de patients inscrits sous 2 identités
      </div>
      <div class="page-paragraphe">
        De la même façon que la présence d’homonyme présente un risque, les
        patients ayant deux dossiers ouverts à leur nom dans un même
        établissement sont à risque d’erreurs.
        <br /><br />
        Reprenons notre exemple d’allergie à la pénicilline, si un patient a
        deux dossiers: A et B dans l’établissement, que cette information est
        noté dans le dossier A et que c’est le dossier B qui est ouvert au
        moment de la prescription d’un antibiotique, les conséquences peuvent
        être graves.
        <br /><br />
        DCD intègre une fonction de
        <strong
          >détection des patients inscrits sous 2 identités différentes.</strong
        >
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/detection_dossiers_multiples.png"
          alt="Détection dossiers multiples"
        />
        <figcaption><i></i></figcaption>
      </figure>

      <div class="page-section">III. Praticiens (médecins)</div>
      <div class="page-paragraphe">
        Cette utilité peut ne pas être évidente pour un non-médecin, elle
        pourrait pourtant permettre une amélioration significative de la prise
        en charge des patients.
        <br /><br />
        Pour prendre ses décisions les médecins se basent sur :
        <ul>
          <li>leur savoir académique (formation initiale et continue)</li>
          <li>leur expérience personnelle</li>
        </ul>
        Mais il n’y a pas d’expérience sans retour d'expérience. Et parmi les
        événements qui constituent le retour d'expérience, le décès est le plus
        grave et le plus important à connaître. Or, nous ne sommes pas toujours
        au courant du décès des patients dont nous avons participé à la prise en
        charge.
        <br /><br />
        DCD permet de prévenir les médecins du décès de leur patient, ce qui
        constitue un retour d'expérience qui leur permettra de s'améliorer et de
        mieux prendre en charge les patients suivants.
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/retour_exp.png"
          alt="Retour expérience"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="page-paragraphe">
        Par ailleurs, sur un plan plus personnel, on aime bien savoir quand nos
        patients décèdent.
        <br /><br />
        Cette fonction est utile pour tous les praticiens : ceux qui exercent
        dans un établissement ou ceux qui exercent en cabinet.
        <br /><br />
        Au-delà du retour d’expérience individuel (apprendre que tel patient est
        décédé) nous avons besoin d’un retour d'expérience sur tout un groupe de
        patient donné.<br />
        Par exemple, la courbe de survie des patients atteints de cancer du
        côlon.
        <br /><br />
        DCD permet un
        <strong
          >retour d’expérience au clinicien, lui permettant d’améliorer ses
          pratiques.</strong
        >
      </div>

      <div class="page-section">IV. Banque et assurance</div>
      <div class="page-paragraphe">
        La LOI n° 2014-617 du 13 juin 2014 relative aux comptes bancaires
        inactifs et aux contrats d'assurance vie en déshérence, dite “<strong
          >loi Eckert</strong
        >” donne obligation aux assurances et aux établissements bancaires de
        vérifier une fois par an si leurs clients sont décédés en consultant le
        Répertoire National d'Identification des Personnes Physiques (RNIPP).
        <br /><br />
        Actuellement, le rapprochement d’identité entre la base client et le
        RNIPP est fait par le système AGIRA.
        <br /><br />
        DCD peut être une solution complémentaire au système AGIRA.
        L’utilisation séquentielle des 2 systèmes permettra d’augmenter le taux
        de détection.
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/loi_eckert.png"
          alt="Loi Eckert"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="page-paragraphe">
        Fonctionnement possible avec / sans stockage :
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/stockage.png"
          alt="Avec ou sans stockage"
        />
        <figcaption><i></i></figcaption>
      </figure>

      <div class="page-section">
        V. Toute entité souhaitant faire du data-cleaning dans sa base de
        données
      </div>
      <div class="page-paragraphe">
        Toute entité souhaitant
        <strong
          >identifier les individus décédés dans sa base de données à visée de
          data-cleaning.</strong
        >
      </div>

      <div class="page-section">
        VI. Toute entité souhaitant être informé du décès d'un ou plusieurs
        individus
      </div>
      <div class="page-paragraphe">
        Le système d’alerte après recherche unitaire ou groupée permet d’<strong
          >être notifié en cas de décès des individus</strong
        >
        renseignés dans la recherche.
      </div>
      <figure class="page-img-wrapper">
        <img
          class="margin-x-auto"
          style="width: 100%"
          src="../assets/img/schema_recherche_unitaire_lineaire_explication.jpg"
          alt="Sytème d'alerte de l'application DCD"
        />
        <figcaption><i></i></figcaption>
      </figure>

      <div class="page-section">
        VII. DCD : une seule solution, de multiples fonctions
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/faq_dcd_solutions.png"
          alt="Fonctionnalités de l'application"
        />
      </figure>

      <div class="page-subtitle">Pourquoi ce système est payant</div>
      <div class="page-paragraphe">
        Le développement et la maintenance de cette solution a un coût.
        <br /><br />
        Pour que ce système puisse continuer à fonctionner, un minimum de
        revenus est nécessaire. Le prix facturé par décès retrouvé nous paraît
        faible au regard de l’utilité de la solution. Cette tarification permet
        par ailleurs de ne payer que pour les informations que nous vous
        renvoyons (vous ne payez que pour ce que vous apprenez).
        <br /><br />
        Par ailleurs, si un jour les coûts du développement initial sont
        couverts par les revenus générés, nous avons prévu de reprendre le
        développement pour ajouter de nouvelles fonctionnalités à la solution
        (on a déjà pleins d’idées mais on est également preneurs des vôtres,
        n’hésitez pas à nous les envoyer à info@risoft.fr).
        <br />
        Si vous appréciez le produit et souhaitez en accélérer le développement
        , n’hésitez pas à faire une donation.
        <br /><br />
        Enfin, le nombre d'utilisateurs et d’utilisation mais également le fait
        que les utilisateurs soient prêts à payer, même une somme symbolique,
        pour utiliser le système, représente une preuve d’utilité qui pourra
        être un argument pour d’éventuelles levées de fonds afin de développer
        et d’améliorer le système.
        <br /><br />
        Nous proposons par ailleurs des prestations sur devis qui nécessitent
        l’intervention d’un informaticien.
      </div>

      <div class="page-subtitle">Prestation sur devis</div>
      <div class="page-subtitle-2">Recherche réalisée par nos soins</div>
      <div class="page-paragraphe paragraphe-text-img">
        <div>
          L’utilisation du système DCD en ligne nécessite que vous extrayiez de
          votre Système d’Information (SI) les informations nécessaires dans un
          format compatible puis que vous les passiez dans le système DCD.
          <br /><br />
          Pour vous éviter d’avoir à effectuer vous–même cette procédure, nous
          pouvons la réaliser pour vous.
          <br /><br />
          Cela nécessitera l’intervention d’un informaticien sur votre SI et se
          fait sur devis.
          <br /><br />
          Vous pouvez nous contacter à l'adresse email :
          <span name="mail-wrapper"></span>
        </div>
        <v-img
          class="homepage-image margin-x-auto"
          lazy-src="../assets/img/schema_recheche_realisee_par_nos_soins.jpg"
          width="85%"
          src="../assets/img/schema_recheche_realisee_par_nos_soins.jpg"
        ></v-img>
      </div>
      <div class="page-subtitle-2 margin-top-100 right-title">API</div>
      <div class="page-paragraphe paragraphe-text-img">
        <v-img
          class="homepage-image float-left"
          lazy-src="../assets/img/schema_API.jpg"
          width="85%"
          height="80%"
          src="../assets/img/schema_API.jpg"
        ></v-img>
        <div>
          Pour vous éviter d’avoir à régulièrement extraire vous–même les donnés
          de votre Système d’Information (SI) puis à les envoyer sur notre
          système DCD, nous pouvons connecter directement votre SI à DCD via une
          API.
          <br /><br />
          Les données de décès vous seront alors régulièrement et
          automatiquement envoyées.
          <br /><br />
          Cette procédure nécessite l’intervention d’un informaticien sur votre
          système d’information et se fait sur devis.
          <br /><br />
          Vous pouvez nous contacter à l'adresse email :
          <span name="mail-wrapper"></span>
        </div>
      </div>
      <div class="page-subtitle-2">Installation en locale</div>
      <div class="page-paragraphe paragraphe-text-img">
        <div>
          Les données à nous envoyer ne sont pas sensibles et notre politique de
          confidentialité est stricte (cf
          <router-link to="/faq">FAQ</router-link>). <br /><br />
          Cependant si vous ne souhaitez pas sortir les données nécessaires de
          votre système d’information, nous pouvons déployer la solution DCD
          chez vous, en local.
          <br /><br />
          Cette procédure nécessite l’intervention d’un informaticien sur votre
          SI et se fait sur devis.
          <br /><br />
          Vous pouvez nous contacter à l'adresse email :
          <span name="mail-wrapper"></span>
        </div>
        <v-img
          class="homepage-image margin-x-auto"
          lazy-src="../assets/img/schema_installation_en_local.jpg"
          width="85%"
          height="100%"
          src="../assets/img/schema_installation_en_local.jpg"
        ></v-img>
      </div>

      <div class="page-subtitle">Qui sommes - nous ?</div>
      <div class="page-paragraphe">
        Selon l’expression consacrée.<br />
        Je suis oncologue - radiothérapeute en exercice à Versailles.
        <br /><br />
        Quand j’ai découvert en décembre 2019 que l’insee publiait en open
        source la liste des individus décédés j’ai immédiatement essayé de
        croiser la liste de l’insee avec celle de mon établissement. Je me suis
        vite rendu compte que cela était beaucoup , beaucoup plus compliqué
        qu’il n’y paraissait. Les difficultés étaient légion : taille de la base
        insee de plusieurs dizaines de millions de lignes, différences
        orthographique des noms, données manquantes ou erronées dans la base de
        l’insee ou dans la mienne, historisation des noms des villes et des
        communes de naissance (les personnes décédées aujourd’hui sont
        généralement nées il y a plusieurs dizaines d’années … dans des villes
        qui ont depuis changé de nom ou de code insee), etc….
        <br /><br />
        J’ai alors découvert un projet mené par des data scientists du ministère
        du nom de MatchID. Le premier cas d’usage de ce projet consistait à
        croiser la base des décès de l’insee avec celle des permis de conduire
        pour inactiver les permis des personnes décédées.
        <br /><br />
        Ce projet donnait une base de travail et de réflexion intéressante mais
        nécessitait encore beaucoup d’amélioration, d’adaptation et de
        simplification d’usage.
        <br /><br />
        Le projet a été designé par moi mais n’ayant pas les compétences
        techniques pour le réaliser, j’ai dû faire appel à une entreprise de
        développeurs pour le réaliser ainsi qu’un data scientist pour le backend
        et un développeur frontend.
      </div>

      <div class="page-subtitle">
        Conservation sur nos serveurs de vos données - Système de notification
        en cas de décès
      </div>
      <div class="page-paragraphe">
        Si vous le souhaitez, vous pouvez enregistrer votre fichier sur nos
        serveurs.
        <br /><br />
        A chaque mise à jour de la base insee des décès, notre système lancera
        la recherche sur votre fichier conservé sur nos serveurs. Vous serez
        alors informé en cas de nouvelles correspondances retrouvées.
        <br /><br />
        Pour des performances optimales, nous vous recommandons de mettre à jour
        régulièrement le fichier que vous nous envoyez.
        <br /><br />
        Votre fichier est chiffré, sécurisé et conservé exclusivement sur des
        serveurs français. Nous nous engageons à l’y conserver en sécurité et à
        ne jamais en partager le contenu avec des tiers. Néanmoins, si vous
        choisissez d'enregistrer fichier sur notre serveur, nous vous demandons
        que ce dernier ne contienne exclusivement que les données nécessaires au
        service (nom, prénom, DDN, etc cf
        <a class="page-link" @click="scrollMeTo('what-data')"
          >Quelles données nous envoyer et pourquoi ?</a
        >)
      </div>

      <div class="page-subtitle">Certification</div>
      <div class="page-paragraphe">
        Notre systeme ne fait qu’une seule chose : croiser la base des décès
        publiée par l’insee sur son site :
        <a
          href="https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/"
          title="https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/"
          target="_blank"
          >https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/
        </a>
        avec le fichier que vous nous envoyez (et on vous assure que c’est
        beaucoup, beaucoup plus compliqué qu’il n’y paraît).<br />
        Les données de l’insee n’étant pas certifiantes, DCD ne l’est pas non
        plus. Pour une certification d’un ou des décès retrouvés, vous devez
        demander un certificat de décès.<br />
        En France, le décès est une donnée publique, ce qui signifie que
        n’importe qui a le droit de savoir si n’importe est décédé sans avoir à
        justifier sa demande.
      </div>

      <div class="page-subtitle">Compte Utilisateur - Machine learning</div>
      <div class="page-paragraphe">
        Pourquoi la création d’un compte utilisateur est nécessaire ?
        <br /><br />
        Le système utilise un machine learning qui s’adapte à votre base de
        données. Ce machine learning est spécifique à votre base.
        <br /><br />
        Nous vous conseillons fortement d’utiliser un seul et même compte
        utilisateur pour une même base de données, sans quoi, les résultats
        générés risquent d’être fortement aléatoires.
      </div>

      <div class="page-subtitle">Sécurité et Confidentialité - résumé</div>
      <div class="page-paragraphe">
        <ul>
          <li>
            <strong>NIR ( numéro de sécu)</strong> : Seuls les 10 premiers
            chiffres peuvent être renseignés. Pour des raisons de
            confidentialité les 3 derniers chiffres du NIR ne peuvent pas être
            renseigné. Le système refusera tout fichier contenant ne serais-ce
            qu'un seul NIR complet
          </li>
          <li>
            <strong>Données médicales</strong> : nous demandons expressément aux
            utilisateurs de n'envoyer AUCUNE donnée médicale (diagnostic ou
            autre), mais uniquement des données administratives.
          </li>
          <li>
            <strong>Partage des données</strong> : nous nous engageons à ce que
            les données envoyées par les utilisateurs ne sortent jamais de nos
            serveurs et ne soient jamais communiquées à des tiers.
          </li>
          <li>
            <strong>Pseudo</strong> : la création d'un compte d'établissement
            est indispensable pour l'utilisation du système, cependant pour un
            maximum de confidentialité, nous conseillons aux établissements
            utilisateurs d'utiliser un pseudo.
          </li>
          <li>
            <strong
              >Utilisation par nous des données envoyées par les
              utilisateurs</strong
            >
            : nous sommes susceptibles d'utiliser les données envoyées par les
            utilisateurs pour corriger et enrichir la base insee. Mais
            exclusivement pour des personnes décédées et exclusivement limitées
            aux données publiques présentes sur le site de l'Insee.
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

export default {
  name: "Faq",
  components: {
    Navbar,
    Footer,
  },
  data: () => ({
    exampleData: [
      {
        id: 1765,
        lastname: "Dupont",
        alternatif_name: "Durand",
        prenom: "Maire",
        next: "14/07/2021",
        date_deces: "",
        deces_connu: "",
        medecin: "Dr. Douguy",
        nir: "2025675011",
        date_naissance: "16/02/1956",
        date_derniere: "10/01/2020",
        sexe: "F",
        code_postal: "78000",
        date_requete: "11/04/2021",
      },
      {
        id: 18654,
        lastname: "Martin",
        alternatif_name: "Lenorman",
        prenom: "Odile",
        next: "",
        date_deces: "12/07/2021",
        deces_connu: "1",
        medecin: "Dr. Charier",
        nir: "2084992021",
        date_naissance: "14/08/1949",
        date_derniere: "04/03/2016",
        sexe: "F",
        code_postal: "92140",
        date_requete: "11/04/2021",
      },
      {
        id: "...",
        lastname: "...",
        alternatif_name: "...",
        prenom: "...",
        next: "...",
        date_deces: "...",
        deces_connu: "...",
        medecin: "...",
        nir: "...",
        date_naissance: "...",
        date_derniere: "...",
        sexe: "...",
        code_postal: "...",
        date_requete: "...",
      },
    ],
    headers: [
      { text: "ID", value: "id" },
      { text: "Nom", value: "lastname" },
      { text: "Nom alternatif", value: "alternatif_name" },
      { text: "Prénom", value: "prenom" },
      { text: "Date prochaine activité", value: "next" },
      { text: "Décès", value: "date_deces" },
      { text: "Décès connu", value: "deces_connu" },
      { text: "Médecin", value: "medecin" },
      { text: "NIR", value: "nir" },
      { text: "Date de naisssance", value: "date_naissance" },
      { text: "Date de dernière activité", value: "date_derniere" },
      { text: "Sexe", value: "sexe" },
      { text: "Code postal patient", value: "code_postal" },
      { text: "Date de requête", value: "date_requete" },
    ],
    exampleInsee: [
      {
        name: "Nom",
        insee: "Dumont",
        vous: "Dumont",
        nous: "Dumont",
      },
      {
        name: "Prénom",
        insee: "Sopie",
        vous: "Sophie",
        nous: "Sopie = Sophie",
      },
      {
        name: "Date de naissance",
        insee: "27/22/34",
        vous: "27/02/34",
        nous: "27/02/34",
      },
      {
        name: "Commune de naissance",
        insee: "92308",
        vous: "92308",
        nous: "92308",
      },
      {
        name: "Date de décès",
        insee: "23/08/98",
        vous: "",
        nous: "23/08/98",
      },
      {
        name: "Commune de décès",
        insee: "75020",
        vous: "",
        nous: "75020",
      },
    ],
  }),
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
  mounted: function () {
    // Created with js to avoid the spam of the email
    var name = "info";
    var domain = "risoft.fr";
    var wrapperArray = Array.from(document.getElementsByName("mail-wrapper"));
    wrapperArray.forEach((wrapper) => {
      var mail = document.createElement("a");
      mail.setAttribute("href", `mailto:${name}@${domain}`);
      mail.innerText = `${name}@${domain}`;
      wrapper.appendChild(mail);
    });
  },
};
</script>

<style scoped>
</style>