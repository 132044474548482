<template>
  <v-navigation-drawer
    app
    fixed
    floating
    mini-variant
    permanent
    style="width: 65px"
    class="navigation-menu"
  >
    <div class="mt-5">
      <router-link
        class="sidebar-title sidebar-link"
        to="/"
        title="Page d'accueil"
        >DCD</router-link
      >
    </div>
    <v-list nav class="mt-2">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.href"
        :title="item.title"
        active-class="sidebar-button-active"
        class="icon-div"
        style="border-radius: 10px; margin-bottom: 15px"
      >
        <v-list-item-icon>
          <v-icon color="white">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <!-- <v-list-item-content>
                    <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                </v-list-item-content> -->
      </v-list-item>
    </v-list>
    <v-list nav class="bottom-list">
      <v-list-item
        v-for="userItem in userItems"
        :key="userItem.title"
        link
        :to="userItem.href"
        :title="userItem.title"
        active-class="sidebar-button-active"
        class="icon-div"
        style="border-radius: 10px; margin-bottom: 20px"
      >
        <v-list-item-icon>
          <v-icon color="white">{{ userItem.icon }}</v-icon>
        </v-list-item-icon>
        <!-- <v-list-item-content>
                    <v-list-item-title class="primary--text">{{ userItem.title }}</v-list-item-title>
                </v-list-item-content> -->
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiFileSearchOutline,
  mdiAccountSearch,
  mdiAccountCog,
  mdiCurrencyEur,
  mdiLogout,
  mdiShieldAccount,
  mdiHelpCircle,
  mdiCalculator,
} from "@mdi/js";
import { mdiBellRing } from "@mdi/js";
import { TokenService } from "../../services/Storage";

export default {
  name: "Sidebar",
  data: () => ({
    items: [
      {
        title: "Recherche unitaire",
        href: "/application/singlesearch",
        icon: mdiAccountSearch,
      },
      {
        title: "Recherche groupée",
        href: "/application/bulksearch",
        icon: mdiFileSearchOutline,
      },
      {
        title: "Centre de notifications",
        href: "/application/notification",
        icon: mdiBellRing,
      },
      {
        title: "Historique des paiements",
        href: "/application/paiements",
        icon: mdiCurrencyEur,
      },
      { title: "FAQ", href: "/faq", icon: mdiHelpCircle },
      {
        title: "Prestation sur devis",
        href: "/prestation-sur-devis",
        icon: mdiCalculator,
      },
    ],
    userItems: [
      {
        title: "Paramètres utilisateur",
        href: "/application/usersettings",
        icon: mdiAccountCog,
      },
      { title: "Déconnexion", href: "/logout", icon: mdiLogout },
    ],
  }),
  mounted: function () {
    if (this.isAdmin) {
      var adminIcon = {
        title: "Admin",
        href: "/application/admin",
        icon: mdiShieldAccount,
      };
      this.items.push(adminIcon);
    }
  },
  computed: {
    isAdmin: function () {
      // TODO
      return TokenService.getClientAdmin() == "true";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.sidebar-title {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
}

.sidebar-link {
  text-decoration: none;
  margin-top: 20px;
}

.navigation-menu {
  background-color: #014a55 !important;
  justify-content: center;
  text-align: center;
}

.sidebar-button-active {
  background: none rgba(255, 255, 255, 0.14);
}

.icon-div {
  width: 48px;
  height: 48px;
  margin: auto;
  justify-content: center;
}

.icon-div > div {
  margin: auto;
}

.bottom-list {
  position: absolute;
  bottom: 0;
}

.v-list-item::before {
  border-radius: 10px !important;
}
</style>