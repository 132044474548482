<template>
  <div class="file-search-wrapper-div">
    <div class="file-search-title">
      Étape 5. <span class="ml-3">Valider</span>
    </div>
    <div class="description">
      Cette page contient 3 parties :
      <ul>
        <li>
          <strong>Performances</strong> : rappel des performance au seuil
          choisi.
        </li>
        <li>
          <strong>Statistiques</strong> : résumé des résultats obtenus au seuil
          choisi.
        </li>
        <li>
          <strong>Données</strong> : affichage des individus trouvés décédés
          dans vos données au seuil choisi. Vous pouvez exporter ces données
          dans un fichier csv.
        </li>
      </ul>
    </div>
    <div id="performance-part mb-10">
      <div class="filesearch-subtitle mt-5">Performances</div>
      <div>
        Au seuil <strong>S = {{ threshold }}</strong>
        <span v-if="prevalence != '-'"
          >et avec une prévalence de <strong>P = {{ prevalence }}</strong></span
        >
      </div>
      <div class="table-grid">
        <v-simple-table class="threshold-table">
          <template>
            <thead>
              <tr>
                <th></th>
                <th>Décès certains</th>
                <th>Vivants certains</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Identifiés décédés</td>
                <td>
                  Vrai Positifs :
                  <span class="enhanced-text">{{
                    results ? results.performances.true_positive : ""
                  }}</span>
                </td>
                <td>
                  Faux Positifs :
                  <span class="enhanced-text">{{
                    results ? results.performances.false_positive : ""
                  }}</span>
                </td>
              </tr>
              <tr>
                <td>Non identifiés décédés</td>
                <td>
                  Faux Négatifs :
                  <span class="enhanced-text">{{
                    results ? results.performances.false_negative : ""
                  }}</span>
                </td>
                <td>
                  Vrai Négatifs :
                  <span class="enhanced-text">{{
                    results ? results.performances.true_negative : ""
                  }}</span>
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>
                  Décès certains :
                  <span class="enhanced-text">
                    {{
                      results
                        ? results.performances.true_positive +
                          results.performances.false_negative
                        : ""
                    }}
                  </span>
                </td>
                <td>
                  Vivants certains :
                  <span class="enhanced-text">
                    {{
                      results
                        ? results.performances.false_positive +
                          results.performances.true_negative
                        : ""
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table class="threshold-table">
          <template>
            <thead>
              <tr>
                <th></th>
                <th>Valeurs au seuil S</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Sensibilité
                  <Tooltip
                    :icon="iconTooltip"
                    color="button"
                    :textArray="tooltips.sensitivity"
                    position="right"
                  />
                </td>
                <td>
                  <span class="enhanced-text center">{{
                    results && results.performances.sensitivity
                      ? results.performances.sensitivity
                      : "-"
                  }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  Spécificité
                  <Tooltip
                    :icon="iconTooltip"
                    color="button"
                    :textArray="tooltips.specificity"
                    position="right"
                  />
                </td>
                <td>
                  <span class="enhanced-text">{{
                    results && results.performances.specificity
                      ? results.performances.specificity
                      : "-"
                  }}</span>
                </td>
              </tr>
              <!-- <tr>
                                <td>VPP <Tooltip :icon="iconTooltip" color="button" :textArray="tooltips.vpp" position='right' /></td>
                                <td><span class="enhanced-text">{{ results.performances.positive_predictive_value ? results.performances.positive_predictive_value : '-' }}</span></td>
                            </tr>
                            <tr>
                                <td>VPN <Tooltip :icon="iconTooltip" color="button" :textArray="tooltips.vpn" position='right' /></td>
                                <td><span class="enhanced-text">{{ results.performances.negative_predictive_value ? results.performances.negative_predictive_value : '-'}}</span></td>
                            </tr> -->
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <div id="stats-part">
      <div class="filesearch-subtitle mt-15">Statistiques</div>
      <div class="stats-grid">
        <div v-for="stat in stats1" :key="stat.formalName" class="stats-card">
          <div class="stats-value">
            {{ results[stat.name] ? results[stat.name] : "0" }}
          </div>
          <div class="stats-name">{{ stat.formalName }}</div>
        </div>
      </div>
      <div class="stats-grid-2">
        <div v-for="stat in stats2" :key="stat.formalName" class="stats-card">
          <div class="stats-value">
            {{ results[stat.name] ? results[stat.name] : "0" }}
          </div>
          <div class="stats-name">{{ stat.formalName }}</div>
        </div>
      </div>
    </div>

    <div id="results-part">
      <div class="filesearch-subtitle mt-16">Données</div>
      <div v-if="results && results.number_deads == 0">
        Aucune personne décédée n'a été trouvée dans vos données pour ce seuil.
        Essayez de changer celui-ci !
      </div>
      <div v-if="results && results.number_deads > 0 && !deadPeopleExtract">
        Chargement des données...
      </div>
      <div v-if="results && results.number_deads > 0 && deadPeopleExtract">
        <div class="mb-8">
          <span class="enhanced-text"
            >{{ results ? results.number_deads : "" }} </span
          >personnes décédées ont été trouvées. Vous pouvez consulter dans le
          tableau ci-dessous les 10 premiers résultats. Pour consulter
          l'ensemble des données, vous devez télécharger le fichier CSV.
        </div>
        <v-data-table
          :headers="headers"
          :items="deadPeopleExtract"
          :search="search"
          :items-per-page="10"
          hide-default-footer
          show-expand
          :expanded.sync="expanded"
          :single-expand="false"
          :customSort="customSort"
          item-key="dcd_patient_id"
        >
          <template v-slot:expanded-item="{ item }" class="extanded-row">
            <td :colspan="2" class="expanded-col">
              <tr class="expanded-title">
                INFORMATIONS DÉCÈS
              </tr>
              <div
                v-for="name1 in fieldsNames1"
                :key="name1"
                class="expanded-categorie"
              >
                {{ name1 }}
              </div>
            </td>
            <td :colspan="2" class="expanded-col">
              <div class="expanded-title"></div>
              <div
                v-for="value1 in fieldsValues1"
                :key="value1"
                class="expanded-value"
              >
                {{ item[value1] }}
              </div>
            </td>
            <td :colspan="2" class="border-left expanded-col">
              <div class="expanded-title mb-1">INFORMATIONS DÉCÈS</div>
              <div
                v-for="name2 in fieldsNames2"
                :key="name2"
                class="expanded-categorie"
              >
                {{ name2 }}
              </div>
            </td>
            <td :colspan="2" class="expanded-col">
              <div class="expanded-title"></div>
              <div
                v-for="value2 in fieldsValues2"
                :key="value2"
                class="expanded-value"
              >
                {{ item[value2] }}
              </div>
            </td>
          </template>
        </v-data-table>
        <div class="my-8">
          Vous pouvez télécharger :
          <ul>
            <li>
              Votre fichier complété avec les informations sur les décès s'il y
              en a.
            </li>
            <li>
              Votre fichier avec uniquement les personnes trouvées comme
              décédées, complété avec les informations sur les décès.
            </li>
          </ul>
          <p>
            Votre fichier sera irréversiblement effacé de nos serveurs d’ici une
            heure sauf si vous souhaitez créer une alerte sur ce fichier.
          </p>
        </div>
        <div class="wrapper-buttons">
          <div
            class="action-btn left-btn pointer-on-hover"
            @click="handleDownloadFile"
          >
            <v-icon color="white">{{ iconDownload }}</v-icon
            >Télécharger le fichier complet
          </div>

          <div
            @click="handleDownloadFilteredFile"
            class="action-btn-bis right-btn pointer-on-hover"
          >
            <v-icon color="button">{{ iconDownload }}</v-icon
            >Télécharger les décès identifiés
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="downloadDialog" width="600px">
      <DownloadModal
        :price="results ? results.pricing : 0"
        :number-results="results ? results.number_unknown_deads : 0"
        title="Téléchargement"
        v-on:process-payment="processPaymentDownload"
        v-on:download-sample="downloadSample"
      />
    </v-dialog>
    <v-dialog v-model="notificationDialog" width="600px">
      <NotificationModal
        v-on:close-dialog="notificationDialog = false"
        v-on:start-notification="startNotification"
        :textArray="notificationText"
        :title="notificationTitle"
        :image="notificationImage"
        :validationText="validationText"
      />
    </v-dialog>

    <v-dialog v-model="notificationTemporaryDialog" width="600px">
      <NotificationModal
        v-on:close-dialog="notificationTemporaryDialog = false"
        v-on:start-notification="sendEmail"
        :textArray="notificationTemporaryText"
        :title="notificationTitle"
        :image="notificationImage"
        :validationText="validationTemporaryText"
      />
    </v-dialog>

    <div class="previous-step pointer-on-hover mt-3" @click="goPreviousStep">
      Étape précédente
    </div>
    <!-- <div class="colored-btn accent-btn mt-3 width-notification" @click="notificationDialog = true">Créer une alerte sur votre fichier</div> -->
    <div
      class="colored-btn accent-btn mt-3 width-notification"
      @click="notificationTemporaryDialog = true"
    >
      Créer une alerte sur votre fichier
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mdiHelpCircle, mdiDownload } from "@mdi/js";
import Tooltip from "../../../components/layout/Tooltip";
import DownloadModal from "../../../components/layout/DownloadModal";
import NotificationModal from "../../../components/layout/NotificationModal";
import { utils } from "../../../services/Utils";
import { API } from "../../../services/Api";
import { TokenService } from "../../../services/Storage";
import FileStorage from "../../../services/FileStorage";

export default {
  name: "ValidateFile",
  components: {
    Tooltip,
    DownloadModal,
    NotificationModal,
  },
  data: () => ({
    iconTooltip: mdiHelpCircle,
    iconDownload: mdiDownload,
    downloadDialog: false,
    onlyDeadData: false,
    notificationDialog: false,
    downloadOngoing: false,
    notificationTemporaryDialog: false,
    notificationTemporaryText: [
      "La feature est actuellement désactivée. Si elle vous intéresse, merci d'envoyer un emial à info@risoft.fr",
    ],
    validationTemporaryText: "Faire une demande",
    notificationText: [
      `Nous pouvons conserver sur nos serveurs le fichier que vous nous 
            avez envoyé et refaire une recherche tous les mois lors de la publication 
            des nouveaux décès sur le site de l’INSEE. Vous serez notifié par 
            mail en cas d’apparition de nouvelles correspondances (option payante).`,
      `Cette recherche se fera au score seuil que vous avez choisi lors de la recherche actuelle.
            Nous nous engageons à conserver en sécurité votre fichier, 
            à ne jamais communiquer à qui que se soit les données qu’il contient`,
    ],
    notificationTitle: `Créer une alerte sur ce fichier`,
    notificationImage: `../assets/img/Option_notification_fichier_VDA_complexe.png`,
    validationText: `Créer une alerte`,
    thresholdText: [
      `Seuil permettant de dire si une personne est décédée (score de confiance de l'algorithme au dessus), ou encore vivante (score en dessous).`,
      `Par défaut nous prenons la valeur 0,7. Vous pouvez changer cette valeur, plus elle sera élevée, plus les résultats 
            de personnes décédés seront certains mais plus vous risquez de passer à côté d'identifications de décès.`,
      `Vous pouvez modifier le seuil en cliquant directement sur la valeur du graph ci-contre.`,
    ],
    prevalenceText: [
      `La prévalence est l'estimation de la proportion d'indivius décédés dans le dataset.`,
      `Elle est pour l'instant fixée à 0,3.`,
      // `Par défaut, nous prennons une prévalence de 0,3. Vous pouvez la changer si vous avez une estimation de celle-ci dans vos données.`
    ],
    awaitingChange: false,
    tooltips: {
      sensitivity: [
        `Probabilité qu’un individu soit retrouvé décédé sachant qu’il est décédé.`,
      ],
      specificity: [
        `Probabilité qu’un individu ne soit PAS retrouvé décédé sachant qu’il n’est PAS décédé.`,
      ],
      // "vpp": [`Valeur Prédictive Positive : probabilité que l'indiviu soit vraiment décédé sachant qu'il a été identifié décédé par l'outil.`],
      // "vpn": [`Valeur Prédictive Négative : probabilité que l'indiviu soit vraiment vivant sachant qu'il n'a PAS été retrouvé par l'outil.`]
    },
    stats1: [
      {
        name: "number_deads",
        formalName: "Décès total, y compris ceux que vous connaissez déjà",
        value: 0,
      },
      {
        name: "number_unknown_deads",
        formalName: "Nombre de décès nouvellement identifiés par le système",
        value: 0,
      },
      {
        name: "number_meeting_to_cancel",
        formalName: "Activités à annuler",
        value: 0,
      },
      // {
      //     name: "number_dead_men",
      //     formalName: "Résultats hommes",
      //     value: 0,
      // },
    ],
    stats2: [
      // {
      //     name: "number_dead_women",
      //     formalName: "Résultats femmes",
      //     value: 0,
      // },
      {
        name: "number_duplicated_patients",
        formalName: "Individus en double dans votre liste",
        value: 0,
      },
      {
        name: "number_homonym_patients",
        formalName: "Individus de votre liste comportant des homonymes",
        value: 0,
      },
      // {
      //     name: "extact_match",
      //     formalName: "Exact match",
      //     value: 0,
      // }
    ],
    search: "",
    expanded: [],
    headers: [
      { text: "Nom", value: "dcd_patient_matchid_nom_famille" },
      { text: "Prénom", value: "dcd_patient_matchid_prenom" },
      { text: "Sexe", value: "patient_sexe" },
      // { text: 'Naisssance', value: 'patient_DDN' },
      { text: "Décès", value: "dcd_patient_matchid_date_deces" },
      { text: "Ville du décès", value: "dcd_patient_matchid_ville_deces" },
      {
        text: "Code postal du décès",
        value: "dcd_patient_matchid_ville_code_insee_deces",
      },
      { text: "Score de confiance", value: "dcd_patient_score" },
      { text: `Plus d'information`, value: "data-table-expand" },
    ],
    fieldsNames1: [
      "Nom",
      "Prénom(s)",
      "Doublon",
      "Homonymes",
      "Sans nom",
      "Personne décédée",
      "Date du décès",
      "Ville du décès",
      "Code postal du décès",
      "Pays du décès",
      "Age lors du décès",
      "Durée depuis décès",
    ],
    fieldsValues1: [
      "dcd_patient_matchid_nom_famille",
      "dcd_patient_matchid_prenom",
      "dcd_patient_doublon",
      "dcd_patient_homonyme",
      "dcd_match_sans_nom",
      "dcd_patient_decede",
      "dcd_patient_matchid_date_deces",
      "dcd_patient_matchid_ville_deces",
      "dcd_patient_matchid_ville_code_insee_deces",
      "dcd_patient_matchid_pays_deces",
      "dcd_patient_matchid_age_deces",
      "dcd_duree_depuis_deces",
    ],
    fieldsNames2: [
      "ID",
      "Score de confiance",
      "Valeur du seuil",
      "Certificat du décès",
      "Age dernière nouvelle",
      "Durée depuis dernière nouvelle",
      "Activité à annuler",
      "Age requête",
      "Archivage optionnel",
      "Date dernière publication INSEE",
      "Match avec le NIR",
    ],
    fieldsValues2: [
      "dcd_patient_id",
      "dcd_patient_score",
      "dcd_client_seuil_decision",
      "dcd_patient_matchid_certificat_deces",
      "dcd_patient_age_derniere_nouvelle",
      "dcd_duree_depuis_derniere_nouvelle",
      "dcd_activite_a_annuler",
      "dcd_patient_age_requete",
      "dcd_archivage_optionnel",
      "dcd_date_derniere_publication_insee",
      "dcd_patient_NIR_is_matching",
    ],
  }),
  created() {
    // this.$store.dispatch("getStripeKey");
    // this.loadData()  // TODO see if needed later
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapFields([
      "fileSearch.step",
      "fileSearch.fileSent",
      "fileSearch.fileName",
      "fileSearch.data",
      "fileSearch.dataArray",
      "fileSearch.separator",
      "fileSearch.jobId",
      "fileSearch.threshold",
      "fileSearch.prevalence",
      "fileSearch.results",
      "fileSearch.deadPeopleExtract",
      "stripe",
      "fileSearch.storedValues",
    ]),
    fileLength() {
      console.log(
        "FILE LENTH",
        FileStorage.getFileData(),
        FileStorage.getFileData().fileLength
      );
      return this.storedValues ? FileStorage.getFileData().fileLength : null;
    },
  },
  methods: {
    goPreviousStep() {
      if (!this.downloadOngoing) {
        this.$store.commit("decreaseStep", 4);
      }
    },
    filterDeadPeople(data) {
      return data.filter((personData) => personData.dcd_patient_id);
    },
    customSort: function (items, index, isDesc) {
      return utils.completeSorting(items, index, isDesc);
    },
    handleDownloadFile() {
      // When user click to download complete file
      if (!this.downloadOngoing) {
        if (this.results.number_unknown_deads > 10000000) {
          this.downloadDialog = true;
          this.onlyDeadData = false;
        } else {
          this.downloadOngoing = true;
          document.body.style.cursor = "wait";
          this.getDeadData()
            .then((data) => {
              console.log("GET DATA HANDLDOWNLOADFILE", data);
              data = this.formatData(data);
              this.processDownload("complet", data);
            })
            .catch(() => {
              var messageData = {
                text: "Une erreur est survenue. Veuillez réessayer.",
                type: "error",
              };
              this.$store.dispatch("displayMessage", messageData);
              this.downloadOngoing = false;
              document.body.style.cursor = "default";
            });
        }
      }
    },
    handleDownloadFilteredFile() {
      // When user click to download only dead data
      if (!this.downloadOngoing) {
        if (this.results.number_unknown_deads > 10000000) {
          this.onlyDeadData = true;
          this.downloadDialog = true;
        } else {
          this.downloadOngoing = true;
          document.body.style.cursor = "wait";
          this.getDeadData()
            .then((data) => {
              console.log("GET DATA HANDLDOWNLOADFILE", data);
              data = this.filterDeadPeople(data);
              data = this.formatData(data);
              console.log("HANDLE FLITERED BEFORE ");
              this.processDownload("deces_identifies", data);
            })
            .catch(() => {
              var messageData = {
                text: "Une erreur est survenue. Veuillez réessayer.",
                type: "error",
              };
              this.$store.dispatch("displayMessage", messageData);
              this.downloadOngoing = false;
              document.body.style.cursor = "default";
            });
        }
      }
    },
    getDeadData() {
      // query backend to get the dead people data
      return new Promise((resolve, reject) => {
        API.get(`bulk/search/?job_id=${this.jobId}`)
          .then((response) => {
            console.log("RESPONSE getDEAD DATA", response);
            if (response.status == 200) {
              var data =
                typeof response.data.meta.data == "string"
                  ? JSON.parse(response.data.meta.data)
                  : response.data.meta.data;
              resolve(data);
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() => this.getDeadData())
                .catch(() => {
                  var messageData = {
                    text: "Une erreur est survenue lors de la connexion au serveur",
                    type: "error",
                  };
                  this.$store.dispatch("displayMessage", messageData);
                  reject();
                });
            } else {
              var messageData = {
                text: "Une erreur est survenue. Veuillez réessayer.",
                type: "error",
              };
              this.$store.dispatch("displayMessage", messageData);
              reject();
            }
          })
          .catch(() => {
            var messageData = {
              text: "Une erreur est survenue. Veuillez réessayer.",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
            reject();
          });
      });
    },
    formatData(data) {
      // data is an array of JSONs which have all the cols values as keys
      var cols = [
        "client_patient_id",
        "patient_nom_famille",
        "patient_nom_alternatif",
        "patient_prenom",
        "patient_DDN",
        "patient_pays_naissance",
        "patient_ville_naissance",
        "patient_sexe",
        "patient_code_postal",
        "patient_date_derniere_nouvelle",
        "patient_date_prochain_RDV",
        "patient_date_deces_connu",
        "patient_NIR",
        "patient_medecin",
        "patient_connu_dcd",
        "dcd_patient_id",
        "dcd_patient_doublon",
        "dcd_patient_homonyme",
        "dcd_patient_matchid_nom_famille",
        "dcd_patient_matchid_prenom",
        "dcd_patient_decede",
        "dcd_patient_matchid_date_deces",
        "dcd_patient_matchid_certificat_deces",
        "dcd_patient_matchid_ville_code_insee_deces",
        "dcd_patient_matchid_ville_deces",
        "dcd_patient_matchid_pays_deces",
        "dcd_patient_score",
        "dcd_patient_age_derniere_nouvelle",
        "dcd_patient_matchid_age_deces",
        "dcd_patient_age_requete",
        "dcd_duree_depuis_derniere_nouvelle",
        "dcd_duree_depuis_deces",
        "dcd_archivage_optionnel",
        "dcd_activite_a_annuler",
        "dcd_date_derniere_publication_insee",
        "dcd_client_seuil_decision",
        "dcd_patient_NIR_is_matching",
      ];
      var processedData = [cols.join(",").replaceAll("matchid", "match")];
      data.forEach((result) => {
        var newRow = "";
        cols.forEach((col, i) => {
          var value = String(result[col]);
          value = value.replaceAll(",", " ").replace("null", "");
          newRow = i == 0 ? value : newRow + "," + value;
        });
        processedData.push(newRow);
      });
      return processedData.join("\n");
    },
    processDownload(nameTag, data) {
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(data);
      anchor.target = "_blank";
      anchor.download = this.fileName.length
        ? `${this.fileName}_${nameTag}_DCD.csv`
        : `fichier_${nameTag}_DCD.csv`;
      anchor.click();
      anchor.remove();
      this.downloadOngoing = false;
      document.body.style.cursor = "default";
    },

    downloadSample() {
      // Download the first 1000 values
      if (!this.downloadOngoing) {
        console.log("ENTER SAMMMPLE");
        this.downloadDialog = false;
        this.downloadOngoing = true;
        document.body.style.cursor = "wait";
        this.getDeadData()
          .then((data) => {
            data = this.onlyDeadData ? this.filterDeadPeople(data) : data;
            data = data.slice(0, 1000);
            data = this.formatData(data);
            console.log("SAMPLE", this.onlyDeadData, data);
            this.processDownload(
              this.onlyDeadData
                ? "echantillon_deces_identifies"
                : "echantillon_complet",
              data
            );
          })
          .catch(() => {
            var messageData = {
              text: "Une erreur est survenue. Veuillez réessayer.",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
            this.downloadOngoing = false;
            document.body.style.cursor = "default";
          });
      }
    },
    getUserPayementData() {
      // Get the data to process to the stripe payment (data on the client, on the product, quantity, etc)
      return new Promise((resolve, reject) => {
        var clientEmail = TokenService.getClientEmail();
        var userData = {};
        API.get(`user/search?email=${clientEmail}`)
          .then((response) => {
            if (response.status == 200) {
              var userData = {
                client: {
                  client_firstname: response.data.client_firstname,
                  client_lastname: response.data.client_lastname,
                  client_email: clientEmail,
                  client_activity_domain: response.data.client_activity_domain,
                },
              };
              resolve(userData);
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() =>
                  this.getUserPayementData().then((resp) => resolve(resp))
                )
                .catch(() => {
                  reject(userData);
                });
            } else {
              reject(userData);
            }
          })
          .catch(() => {
            reject(userData);
          });
      });
    },
    // NOT USED
    async processPaymentDownload() {
      // Process the payment to download the file (> 1000 lines)
      this.downloadDialog = false;
      var requestData = await this.getUserPayementData();
      requestData["product"] = "Bulk Search";
      requestData["quantity"] = this.results.number_unknown_deads - 1000;
      // requestData["pricing"] = this.pricing
      console.log("PAYEMENT download", requestData);
      API.post("payment/checkout", requestData) // TODO w8 Jean 'checkout-stripe'
        .then((response) => {
          console.log("RESPONSE", response);
          if (response.status == 200) {
            // faire un await + variable global
            this.callStripeCheckout(response).then((resp) => {
              console.log("AFTER PAYMENT", resp);
              if (resp.error) {
                var messageData = { text: resp.error, type: "error" };
                this.$store.dispatch("displayMessage", messageData);
              } else {
                this.getDeadData()
                  .then((data) => {
                    data = this.onlyDeadData
                      ? this.filterDeadPeople(data)
                      : data;
                    data = this.formatData(data);
                    console.log("DATA AFTER PAYMENT", this.onlyDeadData, data);
                    this.processDownload(
                      this.onlyDeadData ? "deces_identifies" : "complet",
                      data
                    );
                  })
                  .catch(() => {
                    var messageErrorData = {
                      text: "Une erreur est survenue. Veuillez contacter l'administarteur du site.",
                      type: "error",
                    };
                    this.$store.dispatch("displayMessage", messageErrorData);
                  });
              }
            });
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.processPaymentDownload())
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            var messageData = {
              text: "Une erreur est survenue, veuillez réessayer.",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
          }
        })
        .catch(() => {
          var messageData = {
            text: "Une erreur est survenue, veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        });
    },
    async callStripeCheckout(response) {
      console.log("RESPPP STRIPE", response);
      return this.stripe.redirectToCheckout({
        sessionId: response.data.session_id,
      });
    },
    async startNotification() {
      if (!this.downloadOngoing) {
        console.log("AUTOMATION MODAL");
        // await this.$store.dispatch("getStripeKey");
        this.notificationDialog = false;
        this.processPaymentNotification();
      }
    },
    async processPaymentNotification() {
      // Process the payment for the notification of the bulk search
      console.log("ENTER PAYMENT NOTIF");
      var requestData = await this.getUserPayementData();
      requestData["product"] = "Bulk Search Automation";
      requestData["quantity"] = this.dataArray
        ? this.dataArray.length
        : this.fileLength;
      requestData["task_id"] = [this.jobId];
      console.log("request DAAATA", requestData);
      console.log("PAYEMENT BULLLK", requestData);
      API.post("payment/checkout", requestData)
        .then((response) => {
          console.log("RESPONSE processPaymentNotification", response);
          if (response.status == 200) {
            // redirect to stripe payement page
            this.callStripeCheckout(response).then((resp) => {
              console.log("AFTER PAYMENT", resp);
              if (resp.error) {
                var messageData = { text: resp.error, type: "error" };
                this.$store.dispatch("displayMessage", messageData);
              } else {
                this.createFileNotification();
              }
            });
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.processPaymentNotification())
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            var messageData = {
              text: "Une erreur est survenue, veuillez réessayer.",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
          }
        })
        .catch(() => {
          var messageData = {
            text: "Une erreur est survenue, veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        });
    },
    createFileNotification() {
      let formData = new FormData();
      formData.append("file", this.fileSent);
      console.log("file SENTTT", this.fileSent, this.fileName);
      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      var clientEmail = TokenService.getClientEmail();
      var clientId = TokenService.getClientId();
      const requestData = {
        method: "post",
        url: `https://api.risoft.fr/v1.0/cron/bulk/create?client_id=${clientId}&client_email=${clientEmail}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      API.customRequest(requestData)
        .then((response) => {
          console.log("RESP NOTIF FILE", response);
          if (response.status == 200) {
            // If the notification is well created, then enables it
            this.enableFileNotification(response)
              .then(() => {
                var messageDataSuccess = {
                  text: "L'opération a réussi. Vous serez notifié des nouveaux résultats.",
                  type: "success",
                };
                this.$store.dispatch("displayMessage", messageDataSuccess);
              })
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.createFileNotification())
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            var messageData = {
              text: "Une erreur est survenue. Veuillez réessayer.",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
          }
        })
        .catch(() => {
          var messageData = {
            text: "Une erreur est survenue. Veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        });
    },
    enableFileNotification(fileNotificationResponse) {
      // Enable the file notification after payment and the creation of the cron job
      var data = {
        client_id: TokenService.getClientId(),
        client_email: TokenService.getClientEmail(),
        task_id: [fileNotificationResponse.data.task_id],
        filename: this.fileName,
      };
      console.log("ENABLE REQUEST DATA", data);
      return new Promise((resolve, reject) => {
        API.post("cron/enable", data)
          .then((response) => {
            console.log("RESPONSE ENABLE FILE NOTIF", response);
            if (response.status == 200) {
              resolve(response);
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() =>
                  this.enableFileNotification(fileNotificationResponse)
                )
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    loadData() {
      // Load the data to the database -> nothing todo in front
      API.get(`bulk/load/?job_id=${this.jobId}`).then((response) => {
        if (response.status == 499) {
          API.refreshToken().then(this.loadData());
        }
      });
    },
    sendEmail() {
      const emailSub = "Demande de notification pour mon fichier";
      window.location.href = `mailto:info@risoft.fr&subject=${emailSub}`;
      this.notificationTemporaryDialog = false;
    },
  },
};
</script>

<style scoped>
.table-grid {
  display: grid;
  grid-template-columns: 50% 45%;
  grid-column-gap: 5%;
  margin: 20px auto 100px auto;
}

.threshold-table {
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  height: fit-content;
}

.enhanced-text {
  /* color: rgba(0,0,0,0.9); */
  color: #258b9b;
  font-weight: 500;
}

td:not(:last-child),
th:not(:last-child) {
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

.tooltip-table {
  float: right;
}

.stats-grid {
  margin-top: 20px;
  margin-bottom: 15px;
  display: grid;
  /* grid-template-columns: 200px 200px 200px 200px; */
  grid-template-columns: 200px 200px 200px;
  grid-column-gap: 20px;
  place-content: center;
}

.stats-grid-2 {
  /* grid-template-columns: 200px 200px 200px; */
  grid-template-columns: 200px 200px;
  margin-top: 20px;
  margin-bottom: 15px;
  display: grid;
  grid-column-gap: 20px;
  place-content: center;
}

.stats-card {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.stats-value {
  font-size: 24px;
  color: #258b9b;
}

.wrapper-buttons {
  height: 80px;
  width: 700px;
  margin: auto;
}

.action-btn {
  float: left;
  margin-bottom: 20px;
  background-color: #0f65b0;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.action-btn-bis {
  float: right;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #0f65b0;
  color: #0f65b0;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.width-notification {
  width: 300px;
  float: right;
}
</style>