<template>
  <div class="static-page">
    <Navbar />
    <div class="page-content">
      <div class="page-title">À propos</div>
      <div class="page-subtitle">Fonctionnement de l'application</div>
      <div class="page-paragraphe">
        L’insee publie de façon mensuelle sur son site la liste des personnes
        décédées :
        <a
          href="https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/"
          target="_blank"
          >https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/</a
        >. Le système recherche, parmi la liste que vous envoyez, les personnes
        qui apparaissent aussi dans la liste de l’insee. Et c’est beaucoup plus
        compliqué qu’il n’y paraît... <br /><br />
        DCD est une application qui permet de trouver des personnes décédées
        dans votre fichier CSV, en se basant sur un algorithme de rapprochement
        entre vos données et la base de l'Insee. Nous avons besoin pour cela de
        certaines données utiles à l'algorithme. Vous pouvez vous réferrer à la
        page
        <router-link to="/faq">FAQ</router-link> pour plus d'informations.
      </div>
      <div class="page-subtitle">A quoi ça sert ?</div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/faq_dcd_solutions.png"
          alt="Fonctionnalités de l'application"
        />
      </figure>
      <div class="page-section">I. Recherche médicale</div>
      <div class="sub-section">
        1) Mise à jour des survies globales et disparition des perdus de vue
      </div>
      <div class="page-paragraphe">
        La patients inclus dans les études / cohortes / registres sont parfois
        perdus de vue : au bout d’un certain temps, on ne sait plus ce qu’ils
        deviennent.
        <br /><br />
        DCD permet de faire passer en quelques clics toutes les bases de données
        des patients français (ou la cohorte française des études
        internationales) et
        <strong
          >d’étendre les données de survie avec des données à jour et sans
          perdus de vue en survie globale.</strong
        >
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/extension_suivi_os.png"
          alt="Extension suivi OS par DCD"
        />
      </figure>
      <div class="sub-section">
        2) Analyse exploratoire de facteurs de survie
      </div>
      <div class="page-paragraphe">
        En tant que clinicien, j’ai l’impression que les patients qui présentent
        le facteur F est un facteur prédictif de survie.
        <br />
        Je passe ma liste de patient qui présentent F ou pas dans la solution
        DCD et je génére les courbes de survies correspondantes :
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/analyse_explo1.png"
          alt="Analyse Exploratoire de facteur de survie (1)"
        />
        <figcaption>
          <i
            >Mon sentiment est juste. F semble effectivement être un facteur
            pronostic de survie qui mérite d’être exploré. Il s’agit d’une bonne
            piste de recherche.</i
          >
        </figcaption>
      </figure>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/analyse_explo2.png"
          alt="Analyse Exploratoire de facteur de survie (2)"
        />
        <figcaption>
          <i
            >Mon sentiment n’est pas juste, c’était une fausse impression. F ne
            semble pas être un facteur pronostic de survie. C’est probablement
            une mauvaise piste de recherche.</i
          >
        </figcaption>
      </figure>
      <div class="page-paragraphe">
        DCD permet
        <strong
          >d’orienter la recherche par analyse exploratoire de facteurs de
          survie.</strong
        >
      </div>

      <div class="page-section">II. Etablissements de santé</div>
      <div class="sub-section">1) Identito-vigilance</div>
      <div class="page-paragraphe">
        Marquage visible des patients décédés dans les logiciels métiers. Ainsi
        plus de confusion possible entre un patient décédé et un patient
        vivant.<br />
        En supposant par exemple une prévalence de 30% de patients décédés dans
        une database d’établissement, l’utilisation de DCD permettrait de
        <strong>réduire le risque d’erreur d’identito-vigilance de 30%.</strong>
      </div>
      <div class="sub-section">2) Gestion des archives</div>
      <div class="page-paragraphe">
        La taille des dossiers médicaux des patients augmente de façon
        exponentielle. A titre d’exemple, le site statista estime que le volume
        de données médicales a été multiplié par 15 entre 2013 et 2020.<br />
        Cette augmentation de volume est supérieure à l’augmentation historique
        des capacités de stockage. En d’autres termes, l’allocation de toujours
        plus d’espace de stockage, en plus d’avoir un impact écologique non
        négligeable, n’est pas une solution pérenne au problème de
        l’augmentation exponentielle du volume des données médicales.<br />
        Une nouvelle stratégie est nécessaire.
        <br /><br />
        En général les dossiers des patients susceptibles d’avoir une activité
        dans l'hôpital sont stockés sur des systèmes d’accès direct et
        rapide.<br />
        Ceux qui ne sont plus susceptibles d’être consultés peuvent être stockés
        dans des systèmes à grosse capacité, moins coûteux mais aussi plus lents
        et plus difficile d’accès (stockage sur bande par ex).
        <br /><br />
        Finalement, la conservation du dossier n’est plus obligatoire dans
        <a
          href="https://www.legifrance.gouv.fr/jorf/id/JORFARTI000001911528"
          title="https://www.legifrance.gouv.fr/jorf/id/JORFARTI000001911528"
          target="_blank"
        >
          certaines conditions </a
        >. La destruction de tels dossiers peut être utile pour gagner de la
        place sur les serveurs pour les dossiers informatiques et dans les
        locaux d’archivages pour les dossiers papiers. Ces conditions
        nécessitent de connaître la date du décès et la date de dernière venue
        du patient dans l'hôpital. <br /><br />
        L’utilisation de DCD en renvoyant la liste des individus décédés et la
        liste des individus pour lesquels l’archivage est facultatif pourrait
        permettre pour les établissements d’avoir de règles de gestion
        automatique des archives :
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/gestion_archives.png"
          alt="Gestion des archives"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="page-paragraphe">
        DCD permet de
        <strong>mieux gérer son espace de stockage de données médicales</strong>
        dans un contexte d’explosion de leur quantité.
      </div>
      <div class="sub-section">3) Annulation des activités prévues</div>
      <div class="page-paragraphe">
        DCD permet de
        <strong
          >détecter les patients décédés pour lesquels une activité ultérieure
          au décès est prévue.</strong
        >
        <br />
        Cela permet d’annuler cette activité et d’attribuer le créneau à un
        autre patient.
      </div>
      <div class="sub-section">4) Retour expérience praticiens</div>
      <div class="page-paragraphe">cf 7.3</div>
      <div class="sub-section">5) Détection des homonymes</div>
      <div class="page-paragraphe">
        Les vrais homonymes sont à risque d’erreur.<br />
        Si par exemple, un établissement a dans son système d’information deux
        Jean Martin né le 08/09/56 et qu’on note dans le mauvais dossier
        l’allergie à la pénicilline, les conséquences peuvent être
        désastreuses.<br />
        Pour éviter cela, une solution consiste à mettre une alerte, alarme,
        notification à chaque fois qu’on ouvre un dossier qui possède un
        homonyme dans l’établissement.<br />
        Encore faut - il les identifier.
        <br /><br />
        DCD intégre une fonction de <strong>détection d’homonymes.</strong>
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/detection_homonymes.png"
          alt="Détection homonymes"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="sub-section">
        6) Détection de patients inscrits sous 2 identités
      </div>
      <div class="page-paragraphe">
        De la même façon que la présence d’homonyme présente un risque, les
        patients ayant deux dossiers ouverts à leur nom dans un même
        établissement sont à risque d’erreurs.
        <br /><br />
        Reprenons notre exemple d’allergie à la pénicilline, si un patient a
        deux dossiers: A et B dans l’établissement, que cette information est
        noté dans le dossier A et que c’est le dossier B qui est ouvert au
        moment de la prescription d’un antibiotique, les conséquences peuvent
        être graves.
        <br /><br />
        DCD intègre une fonction de
        <strong
          >détection des patients inscrits sous 2 identités différentes.</strong
        >
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/detection_dossiers_multiples.png"
          alt="Détection dossiers multiples"
        />
        <figcaption><i></i></figcaption>
      </figure>

      <div class="page-section">III. Praticiens (médecins)</div>
      <div class="page-paragraphe">
        Cette utilité peut ne pas être évidente pour un non-médecin, elle
        pourrait pourtant permettre une amélioration significative de la prise
        en charge des patients.
        <br /><br />
        Pour prendre ses décisions les médecins se basent sur :
        <ul>
          <li>leur savoir académique (formation initiale et continue)</li>
          <li>leur expérience personnelle</li>
        </ul>
        Mais il n’y a pas d’expérience sans retour d'expérience. Et parmi les
        événements qui constituent le retour d'expérience, le décès est le plus
        grave et le plus important à connaître. Or, nous ne sommes pas toujours
        au courant du décès des patients dont nous avons participé à la prise en
        charge.
        <br /><br />
        DCD permet de prévenir les médecins du décès de leur patient, ce qui
        constitue un retour d'expérience qui leur permettra de s'améliorer et de
        mieux prendre en charge les patients suivants.
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/retour_exp.png"
          alt="Retour expérience"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="page-paragraphe">
        Par ailleurs, sur un plan plus personnel, on aime bien savoir quand nos
        patients décèdent.
        <br /><br />
        Cette fonction est utile pour tous les praticiens : ceux qui exercent
        dans un établissement ou ceux qui exercent en cabinet.
        <br /><br />
        Au-delà du retour d’expérience individuel (apprendre que tel patient est
        décédé) nous avons besoin d’un retour d'expérience sur tout un groupe de
        patient donné.<br />
        Par exemple, la courbe de survie des patients atteints de cancer du
        côlon.
        <br /><br />
        DCD permet un
        <strong
          >retour d’expérience au clinicien, lui permettant d’améliorer ses
          pratiques.</strong
        >
      </div>

      <div class="page-section">IV. Banque et assurance</div>
      <div class="page-paragraphe">
        La LOI n° 2014-617 du 13 juin 2014 relative aux comptes bancaires
        inactifs et aux contrats d'assurance vie en déshérence, dite “<strong
          >loi Eckert</strong
        >” donne obligation aux assurances et aux établissements bancaires de
        vérifier une fois par an si leurs clients sont décédés en consultant le
        Répertoire National d'Identification des Personnes Physiques (RNIPP).
        <br /><br />
        Actuellement, le rapprochement d’identité entre la base client et le
        RNIPP est fait par le système AGIRA.
        <br /><br />
        DCD peut être une solution complémentaire au système AGIRA.
        L’utilisation séquentielle des 2 systèmes permettra d’augmenter le taux
        de détection.
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/loi_eckert.png"
          alt="Loi Eckert"
        />
        <figcaption><i></i></figcaption>
      </figure>
      <div class="page-paragraphe">
        Fonctionnement possible avec / sans stockage :
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/stockage.png"
          alt="Avec ou sans stockage"
        />
        <figcaption><i></i></figcaption>
      </figure>

      <div class="page-section">
        V. Toute entité souhaitant faire du data-cleaning dans sa base de
        données
      </div>
      <div class="page-paragraphe">
        Toute entité souhaitant
        <strong
          >identifier les individus décédés dans sa base de données à visée de
          data-cleaning.</strong
        >
      </div>

      <div class="page-section">
        VI. Toute entité souhaitant être informé du décès d'un ou plusieurs
        individus
      </div>
      <div class="page-paragraphe">
        Le système d’alerte après recherche unitaire ou groupée permet d’<strong
          >être notifié en cas de décès des individus</strong
        >
        renseignés dans la recherche.
      </div>
      <figure class="page-img-wrapper">
        <img
          class="homepage-image margin-x-auto max-width-75"
          src="../assets/img/schema_recherche_unitaire_lineaire_explication.jpg"
          alt="Sytème d'alerte de l'application DCD"
        />
        <figcaption><i></i></figcaption>
      </figure>

      <div class="page-section">
        VII. DCD : une seule solution, de multiples fonctions
      </div>
      <figure class="page-img-wrapper">
        <img
          class="page-image"
          src="../assets/img/faq_dcd_solutions.png"
          alt="Fonctionnalités de l'application"
        />
      </figure>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

export default {
  name: "About",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
.max-width-75 {
  max-width: 75vw;
}
</style>