<template>
  <v-tooltip right max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="color"
        v-bind="attrs"
        v-on="on"
        :class="position == 'right' ? 'float-right' : ''"
        :small="small"
      >
        {{ icon }}
      </v-icon>
    </template>
    <p v-for="text in textArray" :key="text">{{ text }}</p>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: ["icon", "color", "textArray", "position", "small"],
  computed: {},
};
</script>

<style scoped>
.float-right {
  float: right;
}
</style>