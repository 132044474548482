<template>
  <div class="file-search-wrapper-div">
    <div class="file-search-title">
      Étape 3. <span class="ml-3">Traitement</span>
    </div>
    <div class="loading-card">
      <div v-if="loadingValue < 100 && !error" class="title-loading-card">
        Traitement en cours...
      </div>
      <div v-if="loadingValue == 100" class="title-loading-card">
        Traitement terminé !
      </div>
      <div v-if="loadingValue < 100 && error" class="title-loading-card">
        Traitement interrompu
      </div>
      <div class="circular-loading-wrapper">
        <v-progress-circular
          :rotate="-90"
          :size="150"
          :width="10"
          :value="loadingValue"
          color="button"
        >
          <span class="value-loading">{{ loadingValue }}%</span>
        </v-progress-circular>
      </div>
      <div class="comment-loading" v-if="loadingValue < 100 && !error">
        Merci de ne pas fermer ou rafraichir la page.
      </div>
      <div v-if="loadingValue < 100 && !error" class="div-announce info-color">
        <v-icon class="mx-2 info-color-icon">{{ iconLoading }}</v-icon
        >Traitement des données en cours...
      </div>
      <div
        v-if="loadingValue == 100 && !error"
        class="mt-15 div-announce success-color"
      >
        <v-icon class="mx-2 success-loading-color-icon">{{
          iconChecked
        }}</v-icon
        >Traitement terminé
      </div>
      <div
        v-if="error"
        class="
          mt-xs-8 mt-sm-11 mt-md-11 mt-lg-16 mt-xl-16
          div-announce
          error-color
        "
      >
        <v-icon class="mx-2 error-loading-color-icon">{{ iconAlert }}</v-icon
        >{{ errorMessage }}
      </div>
    </div>

    <div v-if="loadingValue == 100">
      <div class="previous-step pointer-on-hover" @click="goPreviousStep">
        Étape précédente
      </div>

      <div @click="goNextStep" class="next-step pointer-on-hover">
        Étape suivante
      </div>
    </div>
    <div v-if="error">
      <div class="previous-step pointer-on-hover" @click="goPreviousStep">
        Étape précédente
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mdiCheckCircle, mdiClockTimeFour, mdiAlertCircle } from "@mdi/js";
import { API } from "../../../services/Api";
import FileStorage from "../../../services/FileStorage";

export default {
  name: "LoadFile",
  data: () => ({
    iconChecked: mdiCheckCircle,
    iconLoading: mdiClockTimeFour,
    iconAlert: mdiAlertCircle,
    error: false,
    response: null,
    errorMessage: "",
    defautErrorMessage: `Une erreur est survenue dans le chargement du fichier. Veuillez réessayer. 
            Si l'erreur persite, veuillez contacter les adminisatrateurs du site.`,
  }),
  methods: {
    goPreviousStep() {
      this.$store.commit("decreaseStep", 2);
      window.scrollTo(0, 0);
    },
    goNextStep() {
      this.$store.commit("increaseStep", 4);
      window.scrollTo(0, 0);
    },
    queryApi() {
      API.get(`bulk/search/?job_id=${this.jobId}`)
        .then((response) => {
          console.log("REP PROGRESS", response);
          if (response.status == 200) {
            if (response.data.status == 509) {
              this.errorMessage = `Votre fichier a été mis sur liste d'attente. Son traitement commencera 
                        dès que possible. Veuillez revenir ultérieurement.`;
              this.error = true;
              this.saveInLocalStorage();
            } else if (response.data.status == 404 && this.storedValues) {
              console.log("RESET STORED DATAA");
              this.resetStoredValues();
            } else {
              this.updateProgress(response);
            }
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.queryApi())
              .catch(() => {
                this.error = true;
                this.errorMessage = this.defautErrorMessage;
              });
          } else {
            this.error = true;
            this.errorMessage = this.defautErrorMessage;
          }
        })
        .catch(() => {
          if (!this.error) {
            this.error = true;
            this.errorMessage = this.errorMessage
              ? this.errorMessage
              : this.defautErrorMessage;
          }
        });
    },
    updateProgress(response) {
      // Update the loading bar or set the error
      console.log("RESPINSE0", response, response.data.status);
      this.response = response;
      if (
        response.data.meta.status &&
        response.data.meta.status != "CANCELLED" &&
        response.data.status != 404
      ) {
        if (
          response.data.meta.status != "PRE-PROCESSING" &&
          response.data.meta.status != "POST-PROCESSING"
        ) {
          // Do not change the loading bar for the preprocessing, just for the treatment
          this.$store.commit("setFileSearchValue", [
            "loadingValue",
            response.data.meta.progress ? response.data.meta.progress : 0,
          ]);
        }
        if (
          response.data.meta.progress <= 100 &&
          response.data.meta.status != "PROCESSED"
        ) {
          // TODO set a condition on the file size
          setTimeout(
            function () {
              this.queryApi();
            }.bind(this),
            5000
          );
        }
        this.error = false;
      } else {
        this.error = true;
        this.errorMessage =
          Object.prototype.hasOwnProperty.call(response.data.meta, "message") &&
          response.data.message != "null"
            ? response.data.meta.message
            : this.defautErrorMessage;
      }
    },
    saveInLocalStorage() {
      // When the file is in the file, ie its processing will start later
      // Save the file information in the local storage instead of vuex
      // to have persistence
      var dataToSave = {
        step: this.step,
        acceptCGU: true,
        fileName: this.fileName,
        dataColMapping: this.dataColMapping,
        separator: this.separator,
        jobId: this.jobId,
        columns: this.columns,
        storedValues: true,
        fileLength: this.dataArray.length,
      };
      FileStorage.saveFileData(dataToSave);
      this.store.commit("setFileSearchValue", ["storedValues", true]);
    },
    resetStoredValues() {
      this.$store.commit("setFileSearchValue", ["file", null]);
      this.$store.commit("resetFileVariables");
      this.$store.commit("setFileSearchValue", ["step", 1]);
      FileStorage.removeFileData();
      var messageData = {
        text: "Votre fichier a expiré. Veuillez recommencer le processus.",
        type: "error",
      };
      this.$store.dispatch("displayMessage", messageData);
    },
  },
  computed: {
    ...mapFields([
      "fileSearch.step",
      "fileSearch.file",
      "fileSearch.fileName",
      "fileSearch.columns",
      "fileSearch.dataColMapping",
      "fileSearch.data",
      "fileSearch.dataArray",
      "fileSearch.separator",
      "fileSearch.loadingValue",
      "fileSearch.jobId",
      "fileSearch.storedValues",
    ]),
  },
  mounted() {
    this.error = false;
    setTimeout(
      function () {
        this.queryApi();
      }.bind(this),
      3000
    );
  },
};
</script>

<style scoped>
.loading-card {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  margin-top: 25px;
  margin-bottom: 20px;
  padding: 10px;
  height: 340px;
}

.title-loading-card {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}

.circular-loading-wrapper {
  text-align: center;
  margin-top: 20px;
}

.value-loading {
  font-size: 32px;
}

.comment-loading {
  text-align: center;
  margin-top: 20px;
}

.div-announce {
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}
</style>