<template>
  <div class="progression-wrapper" id="filesearch-progression-wrapper">
    <template v-for="iteration in textArray.length">
      <Arrow
        :key="iteration"
        :iter="iteration"
        :color="step == iteration"
        :text="textArray[iteration - 1]"
      />
      <ArrowJoint
        :key="iteration + '-joint'"
        v-if="iteration != textArray.length"
        :color1="step == iteration ? '' : 'white'"
        :color2="step == iteration + 1 ? '' : 'white'"
      />
    </template>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Arrow from "./layout/design/Arrow";
import ArrowJoint from "./layout/design/ArrowJoint";

export default {
  name: "FileSearchProgression",
  components: {
    Arrow,
    ArrowJoint,
  },
  props: {
    textArray: { type: Array },
  },
  computed: {
    ...mapFields(["fileSearch.step"]),
  },
};
</script>

<style scoped>
.progression-wrapper {
  display: flex;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
</style>