<template>
  <div class="arrow">
    <div class="main-arrow-div" :class="color ? 'colored-arrow' : ''">
      <!-- TODO IF TIME -->
      <!-- <div class="number-icon">{{ iter }}</div> -->
      <div class="step">Étape {{ iter }}.</div>
      <div class="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Arrow",
  components: {},
  props: {
    color: {
      type: Boolean,
      default: false,
    },
    text: { type: String },
    iter: {},
  },
};
</script>

<style scoped>
.arrow {
  display: flex;
  width: calc((100vw - 65px - 80px) / 4);
}

.main-arrow-div {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
}

.colored-arrow {
  background-color: #0f65b0;
  width: 100%;
}

.colored-arrow > div {
  color: white;
}

.number-icon {
  margin-top: auto;
  margin-bottom: auto;
}

.step {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
  color: #7d848b;
  font-size: 15px;
}

.text {
  margin-top: auto;
  margin-bottom: auto;
  color: #7d848b;
  font-size: 16px;
  font-weight: 600;
}
</style>