import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import store from './store/index'
import { TokenService } from './services/Storage.js'
import { API } from './services/Api.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltRight, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePapaParse from 'vue-papa-parse'

library.add(faLongArrowAltRight, faPlus, faMinus)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

require('./assets/css/style.css')

API.init("https://api.risoft.fr/v1.0/")

// If token exists set header so the user is connected
if (TokenService.getToken()) {
  API.setHeader()
}
Vue.use(VuePapaParse)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
