<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2" style="color: #2b3e4a">
      Téléchargement
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-5">
      <div class="info-color flex box-message">
        <v-icon color="button" class="mr-3"> {{ infoIcon }}</v-icon>
        <div>
          Votre fichier dépasse les <strong>1000 résultats</strong>, le paiement
          est nécessaire pour télécharger plus de
          <strong>1000 résultats</strong>.
        </div>
      </div>
      <div class="payment-table">
        <div class="border-bottom pa-3">
          <span class="mb-5 semi-bold">RÉCAPITULATIF DE COMMANDE</span>
          <div class="flex semi-bold space-between pt-3">
            <span>{{ numberResults }} résultats</span>
            <span>{{ price / 100 }}€</span>
          </div>
        </div>
        <div class="center my-5">
          <div
            class="action-btn pointer-on-hover"
            @click="$emit('process-payment')"
          >
            Procéder au paiement
          </div>
          <div
            class="action-btn-bis right-btn pointer-on-hover"
            @click="$emit('download-sample')"
          >
            Télécharger l'échantillon (maximum 1000 résultats)
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiInformation } from "@mdi/js";

export default {
  name: "DownloadModal",
  props: ["numberResults", "price"],
  data: () => ({
    infoIcon: mdiInformation,
  }),
};
</script>

<style scoped>
.box-message {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 30px;
}

.payment-table {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f4f4f4;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.space-between {
  justify-content: space-between;
}

.action-btn {
  margin: 10px auto;
  background-color: #0f65b0;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
}

.action-btn-bis {
  background-color: #fff;
  border: 1px solid #0f65b0;
  color: #0f65b0;
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
  margin: 10px auto;
}
</style>