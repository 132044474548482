<template>
  <div class="pa-16">
    <v-card>
      <v-card-title class="text-h5 success-color"> Achat réussi </v-card-title>

      <v-card-text class="pt-5">
        Merci pour votre achat !
        <br /><br />
        Le téléchargement du fichier devrait commencer !
        <div class="mt-16 pt-5 mb-5 center">
          <router-link
            to="/application/singlesearch"
            class="colored-btn blue-btn"
            >Retour à l'application</router-link
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "OrderSuccess",
  mounted() {
    // TODO SEE HOW TO LAUNCH THE DOWNLOAD -> be sure user not forced url and have payed
    // TODO see HOW to diferentiate the 3 payment cases
    console.log("STRIIIP SUCCES", this.stripe);
  },
  computed: {
    ...mapFields(["stripe"]),
  },
};
</script>

<style scoped>
</style>