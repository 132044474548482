<template>
  <div>
    <router-link v-if="!isModal" to="/" class="ma-8"
      >Retour à la page d'accueil</router-link
    >
    <v-card v-bind:class="{ pagecard: !modal }">
      <form id="connection-form">
        <v-card-title class="center">
          <span class="headline">Connexion</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <input
                  id="email-connection"
                  name="email-connection"
                  v-model="email"
                  placeholder="EMAIL"
                  required
                  type="email"
                  @keyup.enter="submitConnection"
                  class="input-full-width"
                />
              </v-col>
              <v-col cols="12">
                <input
                  id="password-connection"
                  name="password-connection"
                  v-model="password"
                  placeholder="MOT DE PASSE"
                  required
                  type="password"
                  @keyup.enter="submitConnection"
                  class="input-full-width"
                />
              </v-col>
            </v-row>
            <div v-if="errors && errors.length" class="error-div">
              <div v-for="error in errors" :key="error">
                <div class="error-alert">{{ error }}</div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-row class="center" dense style="margin: 0">
          <v-col cols="10" class="center">
            <v-btn
              text
              @click="submitConnection"
              color="white"
              class="blue-background"
              width="200"
            >
              <span class="basic-button-text">Connexion</span>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <div text @click="manageSwitchInscription" class="mb-3">
              <span class="basic-button-text underlined pointer-on-hover"
                >S'inscrire</span
              >
            </div>
          </v-col>
          <v-col cols="10">
            <div text @click="forgottenPassword" class="mb-3">
              <span
                class="
                  basic-button-text
                  underlined
                  pointer-on-hover
                  forgotten-password
                "
                >Mot de passe oublié ?</span
              >
            </div>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </div>
</template>

<script>
import { API } from "../services/Api.js";

export default {
  name: "Connection",
  props: { isModal: Boolean },
  data: () => ({
    errors: [],
    email: null,
    password: null,
  }),
  methods: {
    async submitConnection() {
      var dataValidated = this.validateDataLogin();
      if (dataValidated) {
        var data = {
          client_email: this.email,
          client_password: this.password,
        };
        try {
          await this.$store.dispatch("login", data)
          console.log("PROCESSS SUCCESS, now router");
          this.$router.push("/application/singlesearch");
        } catch(e) {
          console.log("ERREUR CONNECTION", e)
          if (e.status == 499) {
              await API.refreshToken()
              await this.submitConnection()
            } else if (
              e &&
              Object.prototype.hasOwnProperty.call(e, "data") &&
              Object.prototype.hasOwnProperty.call(e.data, "status") &&
              e.data.status == 401
            ) {
              this.errors = [
                "La combinaison email / mot de passe est erronée. Veuillez réessayer",
              ];
            } else {
              this.errors = ["Une erreur est survenue, veuillez réessayer."];
            }
        }
      } else {
        this.errors = ["Veuillez remplir l'email et le mot de passe."];
      }
    },
    validateDataLogin() {
      return this.email.length > 0 && this.password.length > 0;
    },
    manageSwitchInscription() {
      this.modal
        ? this.$emit("switchToInscription")
        : this.$router.push({ name: "Inscription" });
    },
    forgottenPassword() {
      this.$router.push({
        name: 'PasswordForgotten'
      })
    },
  },
  computed: {
    modal: function () {
      return this.isModal;
    },
  },
  watch: {
    errors: function (val) {
      console.log("VALL EROR", val);
      if (val.length > 0) {
        console.log("ENTERR");
        this.password = "";
        setTimeout(
          function () {
            this.errors = [];
          }.bind(this),
          5000
        );
      }
    },
  },
};
</script>

<style scoped>
.forgotten-password {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}
</style>