<template>
  <div>
    <Sidebar />
    <div class="app-container">
      <div class="fileSearch-headers">
        <div class="heading pt-7">Panel utilisateur</div>
        <div class="header-description-text">
          Modifiez ici vos informations telle que votre mot de passe ou la mise
          en place de votre mise à jour mensuelle.
        </div>
      </div>
      <div class="grid-2-cards">
        <div class="card-settings">
          <div class="title-card">Vos identifiants DCD</div>
          <div class="card-data" style="grid-template-rows: 280px auto">
            <v-row>
              <v-col cols="12">
                <label class="field-label">Email</label>
                <input
                  v-model="userEmail"
                  label="email"
                  disabled
                  class="input-full-width font-size-14"
                />
              </v-col>
              <v-col cols="12">
                <label class="field-label">Mot de passe actuel</label>
                <input
                  class="input-full-width font-size-14"
                  name="user-password-field"
                  v-model="password"
                  type="password"
                  @change="setBlackBorder"
                  placeholder="VOTRE MOT DE PASSE"
                />
              </v-col>
              <v-col cols="12">
                <label class="field-label">Nouveau mot de passe</label>
                <input
                  class="input-full-width font-size-14"
                  name="user-password-field"
                  v-model="newPassword"
                  type="password"
                  @change="setBlackBorder"
                  placeholder="NOUVEAU MOT DE PASSE"
                />
              </v-col>
              <v-col cols="12">
                <label class="field-label">Confirmation du mot de passe</label>
                <input
                  class="input-full-width font-size-14"
                  name="user-password-field"
                  v-model="newPasswordConfirmation"
                  type="password"
                  @change="setBlackBorder"
                  placeholder="CONFIRMATION MOT DE PASSE"
                />
              </v-col>
            </v-row>
            <div>
              <div
                v-if="errorsPassword && errorsPassword.length"
                class="error-div mb-2"
              >
                <div v-for="errorP in errorsPassword" :key="errorP">
                  <div class="error-alert">{{ errorP }}</div>
                </div>
              </div>
              <div
                v-if="successPassword && errorsPassword.length == 0"
                id="success-div-setting-password"
                class="success-color div-rounded"
              >
                Votre mot de passe a bien été mis à jour.
              </div>
              <v-row class="center mt-3" dense>
                <v-col cols="12" class="center">
                  <v-btn
                    text
                    @click="submitPassword"
                    color="white"
                    class="blue-background"
                    width="200"
                  >
                    <span class="basic-button-text"
                      >Changer de mot de passe</span
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="card-settings">
          <div class="title-card">Vos informations personnelles</div>
          <div class="card-data" style="grid-template-rows: 280px auto">
            <div>
              <v-row>
                <v-col cols="6">
                  <label class="field-label">Nom</label>
                  <input
                    id="lastName-settings"
                    name="usersetting-field"
                    v-model="userData.client_lastname"
                    placeholder="NOM"
                    type="text"
                    @change="setBlackBorder"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="6">
                  <label class="field-label">Prénom</label>
                  <input
                    id="firstName-settings"
                    name="usersetting-field"
                    v-model="userData.client_firstname"
                    placeholder="PRENOM"
                    type="text"
                    @change="setBlackBorder"
                    class="input-full-width font-size-14"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label class="field-label">Code Postal</label>
                  <input
                    id="zipcode-settings"
                    name="usersetting-field"
                    v-model="userData.client_postal_code"
                    placeholder="CODE POSTAL"
                    type="number"
                    @change="setBlackBorder"
                    class="input-full-width font-size-14"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label class="field-label">Domaine d'activité</label>
                  <select
                    id="activity-settings"
                    name="usersetting-field"
                    @change="changeSelect"
                    class="input-full-width font-size-14"
                  >
                    <option
                      v-for="activity in activityOptions"
                      :selected="
                        userData.client_activity_domain
                          ? userData.client_activity_domain.split(' :')[0] ==
                            activity
                          : false
                      "
                      :key="activity"
                      :value="activity"
                    >
                      {{ activity }}
                    </option>
                  </select>
                </v-col>
              </v-row>
              <v-row v-if="otherInput">
                <v-col cols="12">
                  <label class="field-label"
                    >Précision du domaine d'activité</label
                  >
                  <input
                    type="text"
                    placeholder="NOM DU DOMAINE"
                    id="other-input"
                    v-model="otherInputValue"
                    class="input-full-width font-size-14"
                    @change="setActivityOther"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <label class="field-label">Logiciel médical utilisé</label>
                  <input
                    type="text"
                    placeholder="NOM DU LOGICIEL MÉDICAL"
                    id="software-input"
                    v-model="userData.client_medical_software"
                    class="input-full-width font-size-14"
                  />
                </v-col>
              </v-row>
            </div>
            <div>
              <div
                id="success-div-setting-data"
                class="success-color display-none div-rounded"
              >
                Vos informations ont bien été mises à jour.
              </div>
              <div v-if="errors && errors.length" class="error-div">
                <div v-for="error in errors" :key="error">
                  <div class="error-alert">{{ error }}</div>
                </div>
              </div>
              <v-row class="center mt-3" dense>
                <v-col cols="12" class="center">
                  <v-btn
                    text
                    @click="submitUserData"
                    color="white"
                    class="blue-background"
                    width="200"
                  >
                    <span class="basic-button-text"
                      >Valider ces informations</span
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Footer from './layout/Footer'
import Sidebar from "../../components/layout/Sidebar";
import { TokenService } from "../../services/Storage";
import { API } from "../../services/Api";

export default {
  name: "UserSettings",
  components: {
    // Footer,
    Sidebar,
  },
  data: () => ({
    userData: [],
    activityOptions: ["Domaine médical", "Autre"],
    otherInput: false,
    otherInputValue: "",
    errors: [],
    succesData: false,
    password: "",
    newPassword: "",
    newPasswordConfirmation: "",
    errorsPassword: [],
    successPassword: false,
  }),
  computed: {
    userEmail() {
      return TokenService.getClientEmail();
    },
    autoProcessing() {
      // TODO
      return this.userData.client_auto_processing;
    },
  },
  async created() {
    await this.setUserData();
  },
  methods: {
    async setUserData() {
      try {
        const userEmail = TokenService.getClientEmail()
        const response = await API.get(`user/search?email=${userEmail}`)
        if (response.status == 200) {
          this.userData = response.data.data
          Object.prototype.hasOwnProperty.call(
            this.userData,
            "client_activity_domain"
          ) && this.userData.client_activity_domain
            ? ""
            : (this.userData["client_activity_domain"] =
                this.activityOptions[0]);
          this.otherInput =
            this.userData &&
            this.userData.client_activity_domain &&
            this.userData.client_activity_domain.includes("Autre");
          this.otherInputValue =
            this.otherInput && this.userData.client_activity_domain
              ? this.userData.client_activity_domain.split(": ")[1]
              : "";
        } else if (response.status == 499) {
          API.refreshToken()
              .then(() => this.setUserData())
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
        }
      } catch(e) {
        var messageData = {
          text: "Une erreur est survenue, veuillez réessayer",
          type: "error",
        };
        this.$store.dispatch("displayMessage", messageData);
      }
    },
    changeSelect(e) {
      this.setBlackBorder(e);
      this.otherInput = e.target.value == "Autre";
      this.userData.client_activity_domain = e.target.value;
    },
    setBlackBorder(e) {
      e.target.style.borderColor = "rgba(0,0,0,0.3)";
    },
    setActivityOther(e) {
      this.setBlackBorder(e);
      if (this.otherInput) {
        this.otherInputValue = e.target.value;
        this.userData.client_activity_domain =
          "Autre : " + this.otherInputValue;
      }
    },
    submitUserData() {
      this.validateUserData();
      if (this.successData) {
        var data = {
          client_email: this.userData.client_email,
          client_firstname: this.userData.client_firstname,
          client_lastname: this.userData.client_lastname,
          client_postal_code: this.userData.client_postal_code,
          client_activity_domain: this.userData.client_activity_domain,
          client_medical_software: this.userData.client_medical_software,
        };
        API.put("user/", data)
          .then((response) => {
            if (response.status == 200) {
              this.manageSuccessDiv("success-div-setting-data");
              console.log("CHANGE USER DATA", data, response);
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() => this.submitUserData())
                .catch(() => {
                  var messageData = {
                    text: "Une erreur est survenue lors de la connexion au serveur",
                    type: "error",
                  };
                  this.$store.dispatch("displayMessage", messageData);
                });
            } else {
              this.errors.push("Une erreur est survenue.");
            }
          })
          .catch(() => {
            this.errors.push("Une erreur est survenue.");
          });
      }
    },
    validateUserData() {
      this.errors = [];
      var mappingValues = {
        Nom: this.userData.client_lastname,
        Prénom: this.userData.client_firstname,
        Zipcode: this.userData.client_postal_code,
        "Domaine d'activité": this.userData.client_activity_domain,
      };
      var inputs = Array.from(
        document.querySelectorAll("[name='usersetting-field']")
      );
      inputs.forEach((input) => (input.style.borderColor = "rgba(0,0,0,0.3)"));
      var keys = Object.keys(mappingValues);
      this.successData = true;
      Object.values(mappingValues).forEach((val, i) => {
        if (!val) {
          this.errors.push(
            `Le champ "${keys[i]}" est vide, veuillez le compléter.`
          );
          this.successData = false;
          inputs[i].style.borderColor = "red";
        }
        if (
          i == 3 &&
          val.includes("Autre") &&
          this.otherInputValue.length < 1
        ) {
          this.errors.push(`Veuillez préciser le domaine d'activité.`);
          this.successData = false;
          document.getElementById("other-input").style.borderColor = "red";
        }
      });
    },
    manageSuccessDiv(id) {
      // TODO TEST
      var div = document.getElementById(id);
      div.classList.remove("display-none");
      setTimeout(function () {
        div.classList.add("display-none");
      }, 5000);
    },
    submitPassword() {
      this.validatePasswordData();
      if (this.successPassword) {
        var data = {
          client_email: this.userData.client_email,
          client_password: this.password,
          client_new_password: this.newPassword,
        };
        API.put("user/new_password", data)
          .then((response) => {
            if (response.status == 200) {
              this.manageSuccessDiv("success-div-setting-password");
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() => this.submitPassword())
                .catch(() => {
                  var messageData = {
                    text: "Une erreur est survenue lors de la connexion au serveur",
                    type: "error",
                  };
                  this.$store.dispatch("displayMessage", messageData);
                });
            } else {
              this.errorsPassword.push(
                "Une erreur est survenue, veuillez réessayer."
              );
            }
            this.resetPasswordForm();
          })
          .catch(() => {
            this.errorsPassword.push(
              "Une erreur est survenue, veuillez réessayer."
            );
          });
      } else {
        this.errorsPassword.push("Le mot de passe actuel n'est pas valide.");
      }
    },
    validatePasswordData() {
      this.errorsPassword = [];
      this.successPassword = false;
      var mappingValues = {
        "Mot de passe": this.password,
        "Nouveau mot de passe": this.newPassword,
        "Confirmation du nouveau mot de passe": this.newPasswordConfirmation,
      };
      var inputs = Array.from(
        document.querySelectorAll("[name='user-password-field']")
      );
      inputs.forEach((input) => (input.style.borderColor = "rgba(0,0,0,0.3)"));
      var keys = Object.keys(mappingValues);
      Object.values(mappingValues).forEach((val, i) => {
        if (!val) {
          this.errorsPassword.push(
            `Veuillez remplir le champ "${keys[i]}" pour pouvoir changer de mot de passe.`
          );
          inputs[i].style.borderColor = "red";
        } else if (i == 1 && val.length < 6) {
          // New password has fewer than 6 characters
          this.errorsPassword.push(
            `Le champ "${keys[i]}" doit au moins comporter 6 caractères.`
          );
          inputs[i].style.borderColor = "red";
        } else if (i == 2 && val.length >= 6 && val != this.newPassword) {
          this.errorsPassword.push(
            `La confirmation du mot de passe n'est pas bonne.`
          );
          inputs[i].style.borderColor = "red";
          this.newPassword = "";
          this.newPasswordConfirmation = "";
        }
      });
      if (!this.errorsPassword.length) {
        this.successPassword = true;
      }
    },
    resetPasswordForm() {
      this.password = "";
      this.newPassword = "";
      this.newPasswordConfirmation = "";
    },
  }
};
</script>

<style scoped>
.grid-2-cards {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  padding: 20px;
  text-align: center;
}

.card-settings {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  height: fit-content;
}

.card-data {
  position: relative;
  height: 100%;
  margin-top: 15px;
  display: grid;
}

.align-bottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 23px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
}

.field-label {
  float: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.action-btn,
.action-btn > a {
  background-color: #0f65b0;
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
  width: 220px;
  font-size: 0.875rem;
  font-weight: 500;
  margin: auto;
}

.div-rounded {
  padding: 5px;
  margin: 10px 0px;
  border-radius: 5px;
}
</style>