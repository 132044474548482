<template>
  <div class="static-page">
    <Navbar />
    <div class="page-content">
      <div class="page-title">Prestation sur devis</div>
      <div class="page-subtitle">Recherche réalisée par nos soins</div>
      <div class="page-paragraphe paragraphe-text-img">
        <div>
          L’utilisation du système DCD en ligne nécessite que vous extrayiez de
          votre Système d’Information (SI) les informations nécessaires dans un
          format compatible puis que vous les passiez dans le système DCD.
          <br /><br />
          Pour vous éviter d’avoir à effectuer vous–même cette procédure, nous
          pouvons la réaliser pour vous.
          <br /><br />
          Cela nécessitera l’intervention d’un informaticien sur votre SI et se
          fait sur devis.
          <br /><br />
          Vous pouvez nous contacter à l'adresse email :
          <span name="mail-wrapper"></span>
        </div>
        <v-img
          class="homepage-image margin-x-auto"
          lazy-src="../assets/img/schema_recheche_realisee_par_nos_soins.jpg"
          width="85%"
          src="../assets/img/schema_recheche_realisee_par_nos_soins.jpg"
        ></v-img>
      </div>
      <div class="page-subtitle margin-top-100 right-title">API</div>
      <div class="page-paragraphe paragraphe-text-img">
        <v-img
          class="homepage-image float-left"
          lazy-src="../assets/img/schema_API.jpg"
          width="85%"
          height="80%"
          src="../assets/img/schema_API.jpg"
        ></v-img>
        <div>
          Pour vous éviter d’avoir à régulièrement extraire vous–même les donnés
          de votre Système d’Information (SI) puis à les envoyer sur notre
          système DCD, nous pouvons connecter directement votre SI à DCD via une
          API.
          <br /><br />
          Les données de décès vous seront alors régulièrement et
          automatiquement envoyées.
          <br /><br />
          Cette procédure nécessite l’intervention d’un informaticien sur votre
          système d’information et se fait sur devis.
          <br /><br />
          Vous pouvez nous contacter à l'adresse email :
          <span name="mail-wrapper"></span>
        </div>
      </div>
      <div class="page-subtitle">Installation en locale</div>
      <div class="page-paragraphe paragraphe-text-img">
        <div>
          Les données à nous envoyer ne sont pas sensibles et notre politique de
          confidentialité est stricte (cf
          <router-link to="/faq">FAQ</router-link>). <br /><br />
          Cependant si vous ne souhaitez pas sortir les données nécessaires de
          votre système d’information, nous pouvons déployer la solution DCD
          chez vous, en local.
          <br /><br />
          Cette procédure nécessite l’intervention d’un informaticien sur votre
          SI et se fait sur devis.
          <br /><br />
          Vous pouvez nous contacter à l'adresse email :
          <span name="mail-wrapper"></span>
        </div>
        <v-img
          class="homepage-image margin-x-auto"
          lazy-src="../assets/img/schema_installation_en_local.jpg"
          width="85%"
          height="100%"
          src="../assets/img/schema_installation_en_local.jpg"
        ></v-img>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

export default {
  name: "Quote",
  components: {
    Navbar,
    Footer,
  },
  mounted: function () {
    // Created with js to avoid the spam of the email
    var name = "info";
    var domain = "risoft.fr";
    var wrapperArray = Array.from(document.getElementsByName("mail-wrapper"));
    wrapperArray.forEach((wrapper) => {
      var mail = document.createElement("a");
      mail.setAttribute("href", `mailto:${name}@${domain}`);
      mail.innerText = `${name}@${domain}`;
      wrapper.appendChild(mail);
    });
  },
};
</script>

<style scoped>
</style>