<template>
  <div>
    <router-link v-if="!isModal" to="/" class="ma-8">Retour à la page d'accueil</router-link>
    <v-card v-bind:class="{ pagecard: !modal }">
      <form id="inscription-form" >
        <v-card-title class="center">
          <span class="headline">Inscription</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              Pour le bon fonctionnement de l'application, nous vous recommandons d'utiliser 
              un compte distinct pour chaque source de donnnées (si vous envoyez des données 
              issues de 2 etablissements différents, il faut créer 2 comptes différents).
            </p>
            <v-row>
              <v-col cols="12">
                <input id="email-inscription" name="email-inscription" @change="setBlackBorder"
                  v-model="email" placeholder="EMAIL" required type="email"
                  class="input-full-width">
              </v-col>
              <v-col>
                <input id="lastName-inscription" name="lastName-inscription" @change="setBlackBorder"
                  v-model="lastName" placeholder="NOM" required type="text"
                  class="input-full-width">
              </v-col>
              <v-col>
                <input id="firstName-inscription" name="firstName-inscription" @change="setBlackBorder"
                  v-model="firstName" placeholder="PRENOM" required type="text"
                  class="input-full-width">
              </v-col>
              <v-col cols=12>
                <input name="zipcode-inscription"
                  v-model="zipcode" placeholder="CODE POSTAL DE l'ÉTABLISSEMENT" type="number"
                  class="input-full-width-tooltip">
                  <Tooltip :icon="iconTooltip" color="button" :textArray="codePostalText" />
              </v-col>
              <v-col cols=6>
                <select name="activity-inscription"
                  :class="!activityDomain ? 'light-color' : ''"
                  @change="changeSelect"
                  class="input-full-width">
                  <option value="" selected hidden disabled>DOMAINE D'ACTIVITÉ</option>
                  <option value="Domaine médical" style="color: rgba(0,0,0)">Domaine médical</option>
                  <option value="Autre" style="color: rgba(0,0,0)">Autre</option>
                </select>
              </v-col>
              <v-col v-if="activityDomain && otherInput" cols=6>
                <input type="text" placeholder="PRÉCISEZ LE NOM DU DOMAINE"
                    class="input-full-width" @change="setOtherActivity"/>
              </v-col>
              <v-col v-if="activityDomain && !otherInput" cols=6>
                <input id="software-inscription" name="software-inscription" @change="setBlackBorder"
                  v-model="software" placeholder="NOM DU LOGICIEL MEDICAL"
                  class="input-full-width">
              </v-col>
              <v-col cols="12">
                <input id="password-inscription" name="password-inscription" @change="setBlackBorder"
                  v-model="password" placeholder="MOT DE PASSE" required type="password"
                  class="input-full-width">
              </v-col>
              <v-col cols="12">
                <input id="password-confirmation-inscription" name="password-confirmation-inscription" @change="setBlackBorder"
                  v-model="passwordConfirmation" placeholder="CONFIRMER MOT DE PASSE" required type="password"
                  class="input-full-width">
              </v-col>
              <v-col cols="12" style="height: 30px">
                <v-checkbox v-model="privacyCheckbox" class="pa-0 ma-0" style="float: left;">
                </v-checkbox>
                <div>
                  J'ai pris connaissance des 
                  <router-link to="/cgu" target="_blank">conditions générales d'utilisation</router-link>
                  de la plateforme.
                </div>
              </v-col>
            </v-row>
            <div v-if="errors && errors.length" class="error-div">
              <div v-for="error in errors" :key="error">
                <div class="error-alert">{{ error }}</div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-row class="center" dense style="margin: 0">
          <v-col cols="10" class="center">
            <v-btn text @click="submitInscription" color="white" class="blue-background" width="200">
              <span class="basic-button-text">S'inscrire</span>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <div text @click="manageSwitchConnection" class="mb-3">
              <span class="basic-button-text underlined pointer-on-hover">Connexion</span>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </div>
</template>

<script>
import Tooltip from '../components/layout/Tooltip'
import { mdiHelpCircle } from '@mdi/js';


export default {
  name: 'Inscription',
  components: {
    Tooltip,
  },
  props: {'isModal': Boolean},
  data: () => ({
    errors: [],
    email: null,
    lastName: null,
    firstName: null,
    zipcode: null,
    activityDomain: null,
    software: "", // Can be empty so sent as this
    password: null,
    passwordConfirmation: null,
    privacyCheckbox: false,
    otherInput: false,
    iconTooltip: mdiHelpCircle,
    codePostalText: [`Le code postal est celui de l'établissement 
    dont sont issues les données. Si les données sont issues de plusieurs 
    établissements de code postaux différents, laisser le champ vide.`]
  }),
  methods: {
    validateDataInscription() {
      //Check that all the required fields are filled 
      // And that the passwords are ok
      // otherwise turn the inputs in red + message
      this.errors = []
      var mappingValues = {
        "Email": this.email, 
        "Nom": this.lastName, 
        "Prénom": this.firstName,
        "Zipcode": this.zipcode,
        "Domaine d'activité": this.activityDomain,
        "Mot de passe": this.password, 
        "Confirmation mot de passe": this.passwordConfirmation
      };
      var inputs = Array.from(document.getElementById("inscription-form").querySelectorAll("INPUT, SELECT"))
      inputs.forEach((input) => input.style.borderColor = "rgba(0,0,0,0.3)")
      var keys = Object.keys(mappingValues);
      var success = true
      Object.values(mappingValues).forEach((val, i) => {
        if (!val) {
          this.errors.push(`Le champ "${keys[i]}" est vide, veuillez le compléter.`)
          success = false
          inputs[i].style.borderColor = "red"
        }
      });
      if (this.email && !this.validEmail(this.email)) {
        this.errors.push(`Votre adresse email n'est pas valide.`);
        success = false
        inputs[0].style.borderColor = "red"
      }
      if (mappingValues["Mot de passe"] && mappingValues["Mot de passe"].length < 6) {
        success = false
        this.errors.push(`Le mot de passe est trop court. Il faut au moins 6 caractères.`)
        inputs[3].style.borderColor = "red"
      }
      if (
        mappingValues["Mot de passe"] 
        && mappingValues["Confirmation mot de passe"] 
        && mappingValues["Mot de passe"] != mappingValues["Confirmation mot de passe"]
      ) {
        success = false
        this.errors.push(`Les mots de passes sont différents.`)
        inputs[3].style.borderColor = "red"
        inputs[4].style.borderColor = "red"
      }
      if (!this.privacyCheckbox) {
        success = false
        this.errors.push('Veuillez accepter la politique de confidentialité')
      }
      return success
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setBlackBorder (evt) {
      evt.target.style.borderColor = "rgba(0,0,0,0.3)"
    },
    async submitInscription () {
      var dataValidated = this.validateDataInscription();
      if (dataValidated) {
        var data = {
          "client_firstname": this.firstName,
          "client_lastname": this.lastName,
          "client_email": this.email,
          "client_password": this.password,
          "client_postal_code": this.zipcode,
          "client_activity_domain": this.activityDomain,
          "client_medical_software": this.software,
          "client_bulk_price": 5,
        }
        try {
          await this.$store.dispatch("register", data);
          this.$router.push('/application/singlesearch');
        } catch(e) {
          console.log("ERROR", e)
          this.errors = e ? e : ["Une erreur est survenue, veuillez réessayer."]
        }
      }
    },
    manageSwitchConnection () {
      this.modal ? this.$emit('switchToConnection') : this.$router.push({name: 'Connection'})
    },
    changeSelect(e) {
      console.log("changge select", e.target.value)
      this.otherInput = e.target.value == "Autre"
      console.log("OHTERT", this.otherInput)
      this.activityDomain = this.otherInput ? "Autre" : e.target.value

    },
    setOtherActivity(e) {
      if (this.otherInput) {
        this.activityDomain = "Autre : " + e.target.value
      }
    }
   },
  computed: {
    modal: function () {
      return this.isModal
    }
  }
}

</script>

<style scoped>

.light-color {
  color: rgba(0,0,0,0.52);
  font-weight: 450;
}


</style>