<template>
  <v-snackbar
    v-model="message"
    :timeout="timeout"
    :color="messageType == 'error' ? '#E70A24' : '#0F8A00'"
  >
    {{ textMessage }}

    <template v-slot:action>
      <v-icon @click="removeSnackbar">
        {{ closeIcon }}
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
// import { mapState } from 'vuex'
import { mapFields } from "vuex-map-fields";
import { mdiClose } from "@mdi/js";

export default {
  name: "Message",
  data: () => ({
    timeout: 6000,
    closeIcon: mdiClose,
  }),
  computed: {
    ...mapFields(["message", "textMessage", "messageType"]),
  },
  methods: {
    removeSnackbar() {
      this.$store.commit("removeMessage");
    },
  },
};
</script>