import axios from 'axios';
import { TokenService, SessionStorage } from './Storage.js'


const API = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(resource) {
    return axios.get(resource)
  },

  post(resource, data) {
    return axios.post(resource, data)
  },

  put(resource, data) {
    return axios.put(resource, data)
  },

  delete(resource) {
    return axios.delete(resource)
  },
  refreshToken() {
    const client_refresh_token = SessionStorage.getClientRefreshToken();
    if (!client_refresh_token) {
      TokenService.removeAllKeys();
      this.$router.push({ name: "Connection" });
    } else {
      const data = {
        method: 'POST',
        url: "https://api.risoft.fr/v1.0/user/refresh_token",
        data: {
          'client_email': TokenService.getClientEmail(),
          'client_refresh_token': client_refresh_token
        }
      }
      return new Promise((resolve, reject) => {
        axios(data)
          .then(response => {
            console.log("REFRESH REP", response)
            if (response.status == 200 && response.data.status == 201) {
              console.log("OKKK REFRESH")
              TokenService.saveToken(response.data.data.client_token)
              this.setHeader()
              resolve(response)
            } else {
              console.log("REJECT REFRSH")
              reject(response)
            }
          })
          .catch(e => {
            console.log("EROOR DURING REFRESH", e)
            reject(e)
          })
      })
    }
  },
  checkUserIsAdmin() {
    return new Promise((resolve, reject) => {
      API.refreshToken()
        .then(() => {
          var clientEmail = TokenService.getClientEmail()
          API.get(`user/search?email=${clientEmail}`)
            .then(response => {
              if (response.status == 200) {
                resolve(response.data.client_is_admin)
              } else {
                reject()
              }
            })
            .catch(() => reject())
        })
        .catch(() => {
          reject()
        })
    })
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data)

  }
}

export { API }
