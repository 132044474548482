<template>
  <div class="static-page">
    <Navbar />
    <div class="page-content">
      <div class="page-title">Confidentialité</div>
      <div class="page-subtitle">
        Conservation sur nos serveurs de vos données - Système de notification
        en cas de décès
      </div>
      <div class="page-paragraphe">
        Si vous le souhaitez, vous pouvez enregistrer votre fichier sur nos
        serveurs.
        <br /><br />
        A chaque mise à jour de la base insee des décès, notre système lancera
        la recherche sur votre fichier conservé sur nos serveurs. Vous serez
        alors informé en cas de nouvelles correspondances retrouvées.
        <br /><br />
        Pour des performances optimales, nous vous recommandons de mettre à jour
        régulièrement le fichier que vous nous envoyez.
        <br /><br />
        Votre fichier est chiffré, sécurisé et conservé exclusivement sur des
        serveurs français. Nous nous engageons à l’y conserver en sécurité et à
        ne jamais en partager le contenu avec des tiers. Néanmoins, si vous
        choisissez d'enregistrer fichier sur notre serveur, nous vous demandons
        que ce dernier ne contienne exclusivement que les données nécessaires au
        service (nom, prénom, DDN, etc cf
        <router-link class="page-link" to="/faq"
          >Quelles données nous envoyer et pourquoi ?</router-link
        >)
      </div>

      <div class="page-subtitle">Certification</div>
      <div class="page-paragraphe">
        Notre systeme ne fait qu’une seule chose : croiser la base des décès
        publiée par l’insee sur son site :
        <a
          href="https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/"
          title="https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/"
          target="_blank"
          >https://www.data.gouv.fr/fr/datasets/fichier-des-personnes-decedees/
        </a>
        avec le fichier que vous nous envoyez (et on vous assure que c’est
        beaucoup, beaucoup plus compliqué qu’il n’y paraît).<br />
        Les données de l’insee n’étant pas certifiantes, DCD ne l’est pas non
        plus. Pour une certification d’un ou des décès retrouvés, vous devez
        demander un certificat de décès.<br />
        En France, le décès est une donnée publique, ce qui signifie que
        n’importe qui a le droit de savoir si n’importe est décédé sans avoir à
        justifier sa demande.
      </div>

      <div class="page-subtitle">Compte Utilisateur - Machine learning</div>
      <div class="page-paragraphe">
        Pourquoi la création d’un compte utilisateur est nécessaire ?
        <br /><br />
        Le système utilise un machine learning qui s’adapte à votre base de
        données. Ce machine learning est spécifique à votre base.
        <br /><br />
        Nous vous conseillons fortement d’utiliser un seul et même compte
        utilisateur pour une même base de données, sans quoi, les résultats
        générés risquent d’être fortement aléatoires.
        <br /><br />
        Cependant, pour un maximum de confidentialité, nous vous conseillons une
        inscription sous pseudo.<br />
        Par exemple, ne pas utiliser le vrai nom de votre hôpital mais à la
        place 'la clinique de la Forêt Noire' ou 'Grey Sloan Memorial Hospital'
        en fonction de vos affinités cinématographiques.
      </div>

      <div class="page-subtitle">Sécurité et Confidentialité - résumé</div>
      <div class="page-paragraphe">
        <ul>
          <li>
            <strong>NIR ( numéro de sécu)</strong> : Seuls les 10 premiers
            chiffres peuvent être renseignés. Pour des raisons de
            confidentialité les 3 derniers chiffres du NIR ne peuvent pas être
            renseigné. Le système refusera tout fichier contenant ne serais-ce
            qu'un seul NIR complet
          </li>
          <li>
            <strong>Données médicales</strong> : nous demandons expressément aux
            utilisateurs de n'envoyer AUCUNE donnée médicale (diagnostic ou
            autre), mais uniquement des données administratives.
          </li>
          <li>
            <strong>Partage des données</strong> : nous nous engageons à ce que
            les données envoyées par les utilisateurs ne sortent jamais de nos
            serveurs et ne soient jamais communiquées à des tiers.
          </li>
          <li>
            <strong>Pseudo</strong> : la création d'un compte d'établissement
            est indispensable pour l'utilisation du système, cependant pour un
            maximum de confidentialité, nous conseillons aux établissements
            utilisateurs d'utiliser un pseudo.
          </li>
          <li>
            <strong
              >Utilisation par nous des données envoyées par les
              utilisateurs</strong
            >
            : nous sommes susceptibles d'utiliser les données envoyées par les
            utilisateurs pour corriger et enrichir la base insee. Mais
            exclusivement pour des personnes décédées et exclusivement limitées
            aux données publiques présentes sur le site de l'Insee.
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

export default {
  name: "Privacy",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
</style>