<template>
  <div class="static-page mt-1">
    <Navbar />
    <div class="page-content">
      <div class="page-title">Réinitialisation du mot de passe</div>
      <form class="center pwd-grid" @submit="submitPasswordReset">
        <input
          v-model="newPassword"
          class="input-pwd font-size-14"
          name="user-password-field"
          type="password"
          placeholder="NOUVEAU MOT DE PASSE"
          @change="setBlackBorder"
        />
        <input
          v-model="newPasswordConfirmation"
          class="input-pwd font-size-14 mt-5"
          name="user-password-field"
          type="password"
          placeholder="CONFIRMATION MOT DE PASSE"
          @change="setBlackBorder"
        />
        <div
          v-if="errorsPassword && errorsPassword.length"
          class="error-div mb-2"
        >
          <div v-for="errorP in errorsPassword" :key="errorP">
            <div class="error-alert">{{ errorP }}</div>
          </div>
        </div>
                  

        <VRow class="mt-8 mx-auto full-width center">
          <v-btn
              dense
              text
              color="white"
              class="blue-background mr-5"
              width="150"
              type="submit"
            >
            <span class="basic-button-text">Valider</span>
          </v-btn>
          <v-btn
              outlined
              color="rgba(0, 0, 0, 0.6)"
              width="150"
              @click="backHomepage"
            >
            <span class="basic-button-text">Annuler</span>
          </v-btn>
        </VRow>
      </form>
      <v-alert 
        v-if="message"
        :type="messageType"
        class="mt-8 alert-div center ma-auto"
      >
      {{ message }}
      </v-alert>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
import { API } from "@/services/Api";

export default {
  name: "ResetPassword",
  components: { Navbar, Footer },
  data() {
    return {
      newPassword: "",
      newPasswordConfirmation: "",
      errorsPassword: [],
      message: "",
      messageType: "",
      successPassword: false,
    };
  },
  methods: {
    backHomepage() {
      this.$router.push({
        name: "Homepage"
      })
    },
    async submitPasswordReset(e) {
      e.preventDefault();
      this.message = "";
      this.validatePasswordData();
      if (!this.successPassword) {
        return;
      } else {
        try {
          await API.post(
            "user/reset_password",
            {
              client_password: this.newPassword, 
              client_password_recover_token: this.$route.query.token || ""
            }
          );
          this.message = "Votre mot de passe a bien été changé, veuillez-vous connecter.";
          this.messageType = "success";
          this.resetPassword();
        } catch(e) {
          this.message = "Une erreur est survenue, veuillez réessayer.";
          this.messageType = "error";
          this.resetPassword();
        }
      }
    },
    validatePasswordData() {
      this.errorsPassword = [];
      this.successPassword = false;
      var mappingValues = {
        "Nouveau mot de passe": this.newPassword,
        "Confirmation du nouveau mot de passe": this.newPasswordConfirmation,
      };
      var inputs = Array.from(
        document.querySelectorAll("[name='user-password-field']")
      );
      inputs.forEach((input) => (input.style.borderColor = "rgba(0,0,0,0.3)"));
      var keys = Object.keys(mappingValues);
      Object.values(mappingValues).forEach((val, i) => {
        if (!val) {
          this.errorsPassword.push(
            `Veuillez remplir le champ "${keys[i]}" pour pouvoir changer de mot de passe.`
          );
          inputs[i].style.borderColor = "red";
        }
        if (val.length < 6) {
          // New password has fewer than 6 characters
          this.errorsPassword.push(
            `Le champ "${keys[i]}" doit au moins comporter 6 caractères.`
          );
          inputs[i].style.borderColor = "red";
        } 
        if (val.length >= 6 && val != this.newPassword) {
          this.errorsPassword.push(
            `La confirmation du mot de passe n'est pas bonne.`
          );
          inputs[i].style.borderColor = "red";
        }
      });
      if (!this.errorsPassword.length) {
        this.successPassword = true;
      } else {
        this.resetPassword();
      }
    },
    setBlackBorder(e) {
      e.target.style.borderColor = "rgba(0,0,0,0.3)";
    },
    resetPassword() {
      this.newPassword = "";
      this.newPasswordConfirmation = "";
    }
  },
};
</script>

<style scoped>
.input-pwd {
  width: 350px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 5px;
}

.alert-div {
  width: 500px;
}

.pwd-grid {
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
}
</style>