import { render, staticRenderFns } from "./SingleSearch.vue?vue&type=template&id=2169028a&scoped=true&"
import script from "./SingleSearch.vue?vue&type=script&lang=js&"
export * from "./SingleSearch.vue?vue&type=script&lang=js&"
import style0 from "./SingleSearch.vue?vue&type=style&index=0&id=2169028a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2169028a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCol,VDataTable,VDialog,VDivider,VIcon,VRow,VSnackbar})
