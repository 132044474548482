<template>
  <div id="arrow-joint-wrapper" :class="superposition ? 'width-40' : ''">
    <div
      name="part1"
      :class="
        superposition
          ? 'arrow-color-1'
          : color1 == 'white'
          ? 'arrow-start-no-color'
          : 'arrow-start-color'
      "
    ></div>
    <div name="part2" :class="superposition ? 'arrow-color-2' : ''"></div>
  </div>
</template>

<script>
export default {
  name: "ArrowJoint",
  props: {
    color1: {
      type: String,
    },
    color2: {
      type: String,
    },
  },
  computed: {
    superposition: function () {
      return this.color1 == "white" && this.color2 == "white";
    },
  },
};
</script>

<style scoped>
.arrow-start-color {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #0f65b0;
}

.arrow-start-no-color {
  width: 0;
  height: 0;
  border-top: 25px solid #0f65b0;
  border-bottom: 25px solid #0f65b0;
  border-left: 25px solid transparent;
}

.arrow-color-1 {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid rgba(0, 0, 0, 0.1);
}

.arrow-color-2 {
  width: 0;
  position: absolute;
  margin-top: 3px;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 22px solid white;
}

.width-40 {
  width: 40px;
}
</style>