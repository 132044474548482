<template>
  <div>
    <div
      :absolute="true"
      height="50"
      color="accent"
      class="app-footer footer-text"
    >
      <div>
        <span class="footer-title">DCD</span>
        <span class="footer-copyright"> Copyright 2021</span>
      </div>
      <v-spacer></v-spacer>
      <router-link class="footer-link" to="/prestation-sur-devis"
        >Prestation sur devis</router-link
      >
      <router-link class="footer-link" to="/cgu">CGU</router-link>
      <router-link class="footer-link" to="/faq">FAQ</router-link>
      <router-link class="footer-link" to="/confidentialite"
        >Confidentialité</router-link
      >
      <router-link class="footer-link" to="/contact">Contact</router-link>
      <router-link class="footer-link" to="/about">À propos</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.app-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 30px 16px;
  align-items: center;
  flex: 0 1 auto !important;
  flex-wrap: wrap;
}

.footer-text > div > span {
  color: #2b3e4a;
  margin-right: 15px;
}

.footer-link {
  color: #2b3e4a;
  margin-right: 15px;
}

.footer-link:hover {
  cursor: pointer;
}

.footer-title {
  font-weight: 700;
  font-size: 20px;
}

.footer-copyright {
}
</style>