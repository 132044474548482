<template>
  <div class="pa-16">
    <v-card>
      <v-card-title class="text-h5 error-color" style="color: #2b3e4a">
        Achat annulé
      </v-card-title>

      <v-card-text class="pt-5">
        Votre achat a été annulé.
        <div class="mt-16 pt-5 mb-5 center">
          <router-link
            to="/application/singlesearch"
            class="colored-btn blue-btn"
            >Retour à l'application</router-link
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "OrderCancelled",
};
</script>

<style scoped>
</style>