import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        mainColor: "#EFF3F4",
        primary: "#2B3E4A",
        white: "#FFF",
        accent: "#258B9B",
        button: "#0F65B0",
        successLigh: "#ECF7EB",
        infoLight: "#E8F2FA",
        errorLight: "#F1BFBF",
        successBody: "#0F8A00",
        infoBody: "#0F65B0",
        errorBody: "#E70A24",
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
