<template>
  <div class="static-page">
    <Navbar />
    <div class="page-content">
      <div class="page-title">Contact</div>
      <div class="page-subtitle">Qui sommes - nous ?</div>
      <div class="page-paragraphe">
        Selon l’expression consacrée.<br />
        Je suis oncologue - radiothérapeute en exercice à Versailles.
        <br /><br />
        Quand j’ai découvert en décembre 2019 que l’insee publiait en open
        source la liste des individus décédés j’ai immédiatement essayé de
        croiser la liste de l’insee avec celle de mon établissement. Je me suis
        vite rendu compte que cela était beaucoup , beaucoup plus compliqué
        qu’il n’y paraissait. Les difficultés étaient légion : taille de la base
        insee de plusieurs dizaines de millions de lignes, différences
        orthographique des noms, données manquantes ou erronées dans la base de
        l’insee ou dans la mienne, historisation des noms des villes et des
        communes de naissance (les personnes décédées aujourd’hui sont
        généralement nées il y a plusieurs dizaines d’années … dans des villes
        qui ont depuis changé de nom ou de code insee), etc….
        <br /><br />
        J’ai alors découvert un projet mené par des data scientists du ministère
        du nom de MatchID. Le premier cas d’usage de ce projet consistait à
        croiser la base des décès de l’insee avec celle des permis de conduire
        pour inactiver les permis des personnes décédées.
        <br /><br />
        Ce projet donnait une base de travail et de réflexion intéressante mais
        nécessitait encore beaucoup d’amélioration, d’adaptation et de
        simplification d’usage.
        <br /><br />
        Le projet a été designé par moi mais n’ayant pas les compétences
        techniques pour le réaliser, j’ai dû faire appel à une entreprise de
        développeurs pour le réaliser ainsi qu’un data scientist pour le backend
        et un développeur frontend.
      </div>
      <div class="page-subtitle">Contact</div>
      <div class="page-paragraphe">
        Vous pouvez me contacter par email à l'addresse :
        <span id="mail-wrapper"></span>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

export default {
  name: "Contact",
  components: {
    Navbar,
    Footer,
  },
  mounted: function () {
    // Created with js to avoid the spam of the email
    var name = "info";
    var domain = "risoft.fr";
    var wrapper = document.getElementById("mail-wrapper");
    var mail = document.createElement("a");
    mail.setAttribute("href", `mailto:${name}@${domain}`);
    mail.innerText = `${name}@${domain}`;
    wrapper.appendChild(mail);
    // wrapper.innerHTML = `<a href="mailto:${name}@${domain}>ici</a>`
    console.log("MOUNTED EMAIL", wrapper, mail);
  },
};
</script>

<style scoped>
</style>