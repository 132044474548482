<template>
  <div class="file-search-wrapper-div">
    <div class="file-search-title">
      Étape 4. <span class="ml-3">Paramétrage de la recherche</span>
    </div>
    <div class="description">
      Nous avons comparé la liste que vous nous avez envoyée avec les 25
      millions de décès recensés par l’insee.
      <br />
      Chaque correspondance retrouvée entre un individu de votre liste et une
      identité de la liste des décès de l’insee est assortie d’un score de
      concordance.
      <br />
      Plus le score est élevé, plus la concordance est importante.
      <br />
      Vous devez choisir le score seuil au-delà duquel vous souhaitez considérez
      qu’il s’agit d’une vraie correspondance, c’est-à-dire un score seuil
      au-dessus duquel vous considérez qu’il s’agit bien du même individu.
      <br />
      Plus ce seuil est élevé, moins vous risquez de fausses correspondances
      mais plus vous risquez de passer à coté de vraies correspondances.
      <br /><br />
      <v-img
        class="homepage-image margin-x-auto my-8"
        lazy-src="../../../assets/img/schema_explication_score.jpg"
        width="55%"
        src="../../../assets/img/schema_explication_score.jpg"
        sync
      ></v-img>

      Pour vous aider dans ce choix, nous avons calculé les performances de
      notre outil sur VOTRE jeu de données. Pour chaque Score Seuil S :
      <ul>
        <li>
          Nous avons lancé la recherche également sur les individus que vous
          savez déjà être décédés (colonne nombre de morts certains). Parmi ces
          derniers, ceux qui sont retrouvés sont considérés comme des Vrais
          Positif (VP) et ceux qui ne le sont pas sont considérés comme des Faux
          Négatifs (FN).
        </li>
        <li>
          Par ailleurs, nous avons considéré les individus dont la date de
          dernières nouvelles est supérieure à la dernière date du dernier décès
          publié par l’insee, il sont dénommés vivants certains. Ceux-ci ne
          peuvent par définition pas se retrouver dans la liste des décès de
          l’insee. Si un de ces individu est retrouvé il s’agit donc d’un Faux
          Positif (FP), si il n’est pas retrouvé, il s’agit d’un Vrai Négatif
          (VN).
        </li>
      </ul>
      <br />
      Pour chaque score Seuil S, nous avons donc le nombre de VP, FN, FP et VN ;
      ce qui nous permet de calculer la Sensibilité (Se = probabilité qu’un
      individu soit retrouvé décédé sachant qu’il l’est vraiment) et la
      Spécificité (Sp = probabilité qu’un individu soit retrouvé vivant sachant
      qu’il l’est vraiment).
      <br />
      Enfin, nous avons calculé la proportion à priori de décès dans votre
      échantillon en nous basant sur les tables de mortalité en population
      générale en fonction de l’age et du sexe (p).
      <br />
      Cette valeur est donc certainement sous-estimée si votre liste est celle
      d’un centre de cancérologie par exemple. Vous pouvez donc remplacer cette
      valeur de p par celle que vous estimez.
      <br />
      Cette valeur p ne sert qu’à calculer la Valeur Prédictive Positive (VPP =
      probabilité que l’individu soit vraiment décédé sachant que le système le
      retrouve décédé) et la Valeur Prédictive Négative (VPN = probabilité que
      l’individu soit vraiment vivant sachant que le système le retrouve
      vivant).
      <br /><br />
      L’ensemble de ces résultats vous sont présentés dans le tableau ci-dessous
      afin de vous permettre de choisir votre score seuil. Ce dernier doit être
      compris entre 0,5 et 1.
      <br /><br />
      <strong
        >Attention : le système utilise du machine learning, nous vous
        conseillons donc de ne pas utiliser un même compte client pour des
        sources de données différentes, sans quoi le machine learning risque de
        générer des résultats très aléatoires.
      </strong>
    </div>

    <v-data-table
      v-if="tableSuccess"
      :headers="headers"
      :items="tableValues"
      hide-default-footer
      class="elevation-1 my-8"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ headers }">
        <div :key="h.value" class="flex">
          <span class="mr-1">{{ h.text }}</span>
          <Tooltip
            v-if="h.helpText.length"
            :icon="iconTooltip"
            color="button"
            :textArray="h.helpText"
            small
          />
        </div>
      </template>
    </v-data-table>

    <div v-else class="div-announce info-color">
      <v-icon class="mx-2 info-color-icon">{{ iconAlert }}</v-icon>
      Les performances n'ont pas pu être calculées, vous ne connaissez pas de
      patients décédés certains.
    </div>

    <div class="threshold-grid">
      <div class="parametrize-grid">
        <div class="mr-3">Choix du seuil :</div>
        <input
          v-model="threshold"
          type="number"
          step="0.01"
          max="1"
          min="0.5"
          class="threshold-input mr-3"
        />
        <Tooltip
          :icon="iconTooltip"
          color="button"
          :textArray="thresholdText"
        />
        <div class="mr-3">Calcul de la prévalence : </div>
            <input disabled v-model="prevalence" class="threshold-input mr-3" />
            <Tooltip :icon="iconTooltip" color="button" :textArray="prevalenceText" />
      </div>
    </div>

    <div>
      <div
        class="previous-step pointer-on-hover"
        @click="goPreviousStep"
        id="previous-button"
      >
        Étape précédente
      </div>

      <div
        @click="goNextStep"
        style="width: 300px"
        id="next-button"
        class="next-step pointer-on-hover"
      >
        Validation du seuil et étape suivante
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mdiHelpCircle, mdiAlertCircle } from "@mdi/js";
import Tooltip from "../../../components/layout/Tooltip";
import { API } from "../../../services/Api";

export default {
  name: "ParametrizeFile",
  components: {
    Tooltip,
  },
  data: () => ({
    chartData: null,
    width: 500,
    height: 300,
    options: null,
    iconTooltip: mdiHelpCircle,
    validationProcessing: false,
    tableSuccess: true,
    iconAlert: mdiAlertCircle,
    thresholdText: [
      `Choix du seuil du score de similarité à partir duquel vous considérez qu’il s’agit bien du même individu.`,
      `Si vos données ne permettent pas de calculer les performances de façon fiable (trop peu de 
            vivants certains pour calculer la specificité ou trop peu de morts certains pour calculer la sensibilité), 
            nous vous conseillons d’utiliser un score de 0,7 qui donne généralement de bonnes`,
    ],
    prevalenceText: [
      `Nous avons calculé la proportion à priori de décès dans votre échantillon en nous basant 
            sur les tables de mortalité en population générale en fonction de l’age et du sexe (p).`,
      // `Cette valeur est donc certainement sous-estimée si votre liste est celle d’un centre
      // de cancérologie par exemple. Vous pouvez donc remplacer cette valeur de p par celle que vous estimez.`,
      `Cette valeur p ne sert qu’a calculer la Valeur Prédictive Positive (VPP = probabilité 
            que l’individu soit vraiment décédé sachant que le système le retrouve décédé) et 
            la Valeur Prédictive Négative ( VPN = probabilité que l’individu soit vraiment vivant 
            sachant que le système le retrouve vivant).`,
    ],
    awaitingChange: false,
    tooltips: {
      sensitivity: [
        `Probabilité qu’un individu soit retrouvé décédé sachant qu’il est décédé.`,
      ],
      specificity: [
        `Probabilité qu’un individu ne soit PAS retrouvé décédé sachant qu’il n’est PAS décédé.`,
      ],
      vpp: [
        `Valeur Prédictive Positive : probabilité que l'indiviu soit vraiment décédé sachant qu'il a été identifié décédé par l'outil.`,
      ],
      vpn: [
        `Valeur Prédictive Négative : probabilité que l'indiviu soit vraiment vivant sachant qu'il n'a PAS été retrouvé par l'outil.`,
      ],
    },
    tableValues: [],
    headers: [
      {
        text: "Score Seuil S",
        value: "decision_threshold",
        helpText: [],
      },
      {
        text: "Nombre de morts certains",
        value: "number_certainly_dead",
        helpText: [],
      },
      {
        text: "Vrais Positifs",
        value: "true_positive",
        helpText: [
          `Nombre de morts certains bien retrouvés par le système au seuil S.`,
        ],
      },
      {
        text: "Faux Négatifs",
        value: "false_negative",
        helpText: [
          `Nombre de morts certains non retrouvés par le système au seuil S.`,
        ],
      },

      {
        text: "Nombre de vivants certains",
        value: "number_certainly_alive",
        helpText: [],
      },
      {
        text: "Faux Positifs",
        value: "false_positive",
        helpText: [
          `Nombre de vivants certains retrouvés par le système au seuil S.`,
        ],
      },
      {
        text: "Vrais Négatifs",
        value: "true_negative",
        helpText: [
          `Nombre de vivants certains non retrouvés par le système au seuil S.`,
        ],
      },
      {
        text: "Sensibilité",
        value: "sensitivity",
        helpText: [
          `Sensibilité : Probabilité qu’un individu soit retrouvé décédé sachant qu’il est décédé.`,
        ],
      },
      {
        text: "Spécificité",
        value: "specificity",
        helpText: [
          `Spécificité : Probabilité qu’un individu ne soit PAS retrouvé décédé sachant qu’il n’est PAS décédé.`,
        ],
      },
      // {
      //     text: 'VPP',
      //     value: 'positive_predictive_value',
      //     helpText: [`Valeur Prédictive Positive : Probabilité que l'indiviu soit vraiment décédé sachant qu'il a été identifié décédé par l'outil.`]
      // },
      // {
      //     text: 'VPN',
      //     value: 'negative_predictive_value',
      //     helpText: [`Valeur Prédicitve Négative : Probabilité que l'indiviu soit vraiment vivant sachant qu'il n'a PAS été retrouvé par l'outil.`]
      // },
    ],
  }),
  computed: {
    ...mapFields([
      "fileSearch.step",
      "fileSearch.jobId",
      "fileSearch.threshold",
      "fileSearch.prevalence",
      "fileSearch.results",
    ]),
  },
  watch: {
    validationProcessing: function () {
      // Reset validation ongoing after 3 minutes
      setTimeout(
        function () {
          this.validationProcessing = false;
        }.bind(this),
        180000
      );
    },
  },
  mounted() {
    this.getTableData();
    this.initStats();
  },
  methods: {
    goPreviousStep() {
      if (!this.validationProcessing) {
        this.$store.commit("decreaseStep", 3);
      }
    },
    async fetchStats() {
      let response = await API.get(
        `bulk/search/stats/?job_id=${this.jobId}&decision_threshold=${this.threshold}`
      )
      if (response.status == 200) {
        var resData =
          typeof response.data == "string"
            ? JSON.parse(response.data)
            : response.data;
        this.$store.commit("setFileSearchValue", ["results", resData]);
        console.log("RES DATA", resData)
        if (resData?.performances?.prevalence) {
          console.log("PREVALENCE", resData?.performances?.prevalence)
          this.$store.commit("setFileSearchValue", ["prevalence", resData?.performances?.prevalence]);
        }
        return response
      } else if (response.status == 499) {
        await API.refreshToken();
        await this.fetchStats();
      }
    },
    async initStats() {
      try {
        await this.fetchStats();
      } catch(e) {
        console.log("error", e)
        var messageData = {
          text: "Une erreur est survenue, veuillez réessayer.",
          type: "error",
        };
        await this.$store.dispatch("displayMessage", messageData);
      }
    },
    async goNextStep() {
      if (!this.validationProcessing) {
        document.body.style.cursor = "wait";
        this.validationProcessing = true;
        try {
          await this.fetchStats();
          await this.$store.dispatch("updatetDataFileSearch") // inscrease the step also
        } catch(e) {
          console.log("error", e)
          var messageData = {
            text: "Une erreur est survenue, veuillez réessayer.",
            type: "error",
          };
          await this.$store.dispatch("displayMessage", messageData);
        } finally {
          document.body.style.cursor = "default";
          this.validationProcessing = false;
        }
      }
    },
    async getTableData() {
      this.tableSuccess = true;
      try {
        const response = await API.get(`bulk/search/performances?job_id=${this.jobId}`)
        console.log("REPP", response, this.jobId);
        if (response.status == 200) {
          if (response.data.status == 200) {
            this.tableValues =
              response.data.performances != null
                ? response.data.performances
                : [];
          } else {
            console.log("PAS DE TABLE");
            this.tableSuccess = false;
          }
        } else if (response.status == 499) {
            await API.refreshToken()
            await this.getTableData()
        } 
      } catch(e) {
        console.log("error", e)
        var messageData = {
          text: "Une erreur est survenue, veuillez réessayer.",
          type: "error",
        };
        await this.$store.dispatch("displayMessage", messageData);
        }
    }
  },
};
</script>

<style scoped>
.threshold-grid {
  margin-bottom: 30px;
  margin-top: 5px;
}

.parametrize-grid {
  margin: 20px 0px 20px 10px;
  display: grid;
  width: 320px;
  grid-template-columns: auto auto auto;
  grid-row-gap: 30px;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}

.div-announce {
  padding: 10px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>