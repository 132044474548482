export const utils = {
  completeSorting: function (items, index, isDesc) {
    items.sort((a, b) => {
      if (index[0] == 'matchid_date_naissance' || index[0] == 'matchid_date_deces' || index.includes("date")) {
        var splitA = a[index].split("/").reverse()
        var splitB = b[index].split("/").reverse()
        if (isDesc[0]) {
          return splitB[0] != splitA[0] ? splitB[0] - splitA[0] :
            splitB[1] != splitA[1] ? splitB[1] - splitA[1] :
              splitB[2] - splitA[2]
          // return new Date(b[index]) - new Date(a[index]);
        } else {
          return splitB[0] != splitA[0] ? splitA[0] - splitB[0] :
            splitB[1] != splitA[1] ? splitA[1] - splitB[1] :
              splitA[2] - splitB[2]
        }
      } else {
        if (typeof a[index] !== 'undefined' && typeof a[index] == 'string') {
          console.log("pAS NUMBER")
          if (!isDesc[0]) {
            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
          } else {
            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
          }
        } else if (typeof a[index] !== 'undefined' && typeof a[index] == 'number') {
          console.log("NUMBER")
          if (!isDesc[0]) {
            return a[index] - b[index];
          } else {
            return b[index] - a[index];
          }
        }
      }
    });
    return items;
  },
  calculateSize: function (size) {
    return size / 1000 < 1 ? `${size} octets` :
      size / 1000 < 1000 ? `${(size / 1000).toFixed(1)} ko` :
        size / 1000000 < 1000 ? `${(size / 1000000).toFixed(1)} Mo` :
          `${(size / 10 ** 9).toFixed(1)} Go`
  }
}