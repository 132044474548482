<template>
  <v-app>
    <router-view></router-view>
    <Message />
  </v-app>
</template>

<script>
import Message from "./components/layout/Message";

export default {
  name: "App",
  data: () => ({
    //
  }),
  components: {
    Message,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #2b3e4a;
}

html {
  overflow-y: auto !important;
}

#app {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #2b3e4a;
}
</style>
