import Vue from 'vue'
import Router from 'vue-router'
import Homepage from '@/views/Homepage'
import About from '@/views/About'
import Privacy from '@/views/Privacy'
import Faq from '@/views/Faq'
import Contact from '@/views/Contact'
import Terms from '@/views/Terms'
import Quote from '@/views/Quote'
import SingleSearch from '@/views/application/SingleSearch'
import Connection from '@/views/Connection.vue'
import Inscription from '@/views/Inscription.vue'
import FileSearch from '@/views/application/FileSearch'
import Notification from '@/views/application/Notification'
import Payements from '@/views/application/Payements'
import OrderSuccess from '@/views/application/OrderSuccess'
import OrderCancelled from '@/views/application/OrderCancelled'
import Admin from '@/views/application/Admin'
import UserSettings from '@/views/application/UserSettings'
import Logout from '@/views/Logout'
import PasswordForgotten from '@/views/PasswordForgotten'
import ResetPassword from "@/views/ResetPassword"
import { TokenService, SessionStorage } from '@/services/Storage.js'


Vue.use(Router)


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Homepage',
      component: Homepage,
      meta: {
        public: true,
      }
    },
    {
      path: '/connexion',
      name: 'Connection',
      component: Connection,
      meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/password-forgotten',
      name: 'PasswordForgotten',
      component: PasswordForgotten,
      meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/reset-password/:token?',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/inscription',
      name: 'Inscription',
      component: Inscription,
      meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        public: true,
      }
    },
    {
      path: '/confidentialite',
      name: 'Privacy',
      component: Privacy,
      meta: {
        public: true,
      }
    },
    {
      path: '/faq',
      name: 'Faq',
      component: Faq,
      meta: {
        public: true,
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        public: true,
      }
    },
    {
      path: '/cgu',
      name: 'Terms',
      component: Terms,
      meta: {
        public: true,
      }
    },
    {
      path: '/prestation-sur-devis',
      name: 'Quote',
      component: Quote,
      meta: {
        public: true,
      }
    },
    {
      path: '/application/singlesearch',
      name: 'SingleSearch',
      component: SingleSearch,
    },
    {
      path: '/application/bulksearch',
      name: 'FileSearch',
      component: FileSearch,
    },
    {
      path: '/application/paiements',
      name: 'Payements',
      component: Payements,
    },
    {
      path: '/application/notification',
      name: 'Notification',
      component: Notification,
    },
    {
      path: '/application/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        admin: true
      }
    },
    {
      path: '/application/usersettings',
      name: 'UserSettings',
      component: UserSettings,
    },
    {
      path: '/success',
      name: 'OrderSuccess',
      component: OrderSuccess,
    },
    {
      path: '/cancel',
      name: 'OrderCancelled',
      component: OrderCancelled,
    },


  ]
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const adminPage = to.matched.some(record => record.meta.admin)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!SessionStorage.getClientRefreshToken();
  // TODO REMOVE
  const isUserAdmin = TokenService.getClientAdmin()
  if (!isPublic && !loggedIn) {
    return next({
      path: '/connexion',
      query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
    })
  }
  if (adminPage && loggedIn && isUserAdmin == "false") {
    return next({
      path: '/application/singlesearch',
      query: { redirect: to.fullpath }
    })
  }
  // // var isUserAdmin = false  // can be changed after refresh token is user is admin
  // // // Refresh the user token to see if email and cookie match
  // // // The if it s ok, check if user is admin
  // // API.checkUserIsAdmin()
  // // .then(response => {
  // //     console.log("REPPP ADMIN", response)
  // //     if (!isPublic && !loggedIn) {
  // //         return next({
  // //           path:'/connexion',
  // //           query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
  // //         })
  // //       } 
  // //       if (adminPage && loggedIn && isUserAdmin == "false") {
  // //           return next({
  // //               path:'/application/singlesearch',
  // //               query: {redirect: to.fullpath}
  // //           })
  // //       }
  // // })
  // // .catch(() => {
  // //     // Display error message and if admin apge, redirect to another (singleSearch)
  // //     var messageData = {"text" : "Une erreur est survenue lors de la connexion au serveur", 'type': 'error'}
  // //     this.$store.dispatch('displayMessage', messageData)
  // //     if (!isPublic && !loggedIn) {
  // //         return next({
  // //             path:'/connexion',
  // //             query: {redirect: to.fullPath}
  // //         })
  // //     } 
  // //     if (adminPage && loggedIn) {
  // //         return next({
  // //             path:'/application/singlesearch',
  // //             query: {redirect: to.fullpath}
  // //         })
  // //     }
  // // })


  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }
  next();
})

export default router;
