<template>
  <div>
    <Sidebar />
    <div class="app-container">
      <div class="fileSearch-headers">
        <div class="heading pt-3">Paiements</div>
        <div class="header-description-text">
          Consultez ici l'historique de vos paiements.
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="tableValues"
        :customSort="customSort"
        hide-default-footer
        class="elevation-1 payment-table"
      >
        <template v-slot:[`item.amount`]="{ item }">
          <span>{{ item.amount / 100 }}</span>
        </template>
        <template v-slot:[`item.payment_date`]="{ item }">
          <span>{{ formatDate(item.payment_date) }}</span>
        </template>
        <template v-slot:[`item.product`]="{ item }">
          <span>{{ formatProduct(item.product) }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import Sidebar from "../../components/layout/Sidebar";
import { utils } from "../../services/Utils";
import { API } from "../../services/Api";
import { TokenService } from "../../services/Storage";

export default {
  name: "Payements",
  components: {
    Sidebar,
  },
  data: () => ({
    headers: [
      { text: "Valeur (€)", value: "amount", align: "center" },
      { text: "Date", value: "payment_date", align: "center" },
      { text: "Type de recherche", value: "product", align: "center" }, // Bulk search or single search
    ],
    // Need the price as number
    tableValues: [],
  }),
  methods: {
    customSort: function (items, index, isDesc) {
      return utils.completeSorting(items, index, isDesc);
    },
    getTransactions: function () {
      var clientEmail = TokenService.getClientEmail();
      API.get(`payment/transactions?client_email=${clientEmail}`)
        .then((response) => {
          console.log("LOOOAD PAYMENT", response);
          if (response.status == 200) {
            this.tableValues = response.data.transactions;
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.getTransactions())
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur.",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            this.errors.push(
              `Une erreur est survenue lors du chargement des données... Veuillez réessayer.`
            );
          }
        })
        .catch(() => {
          this.errors.push(
            `Une erreur est survenue lors du chargement des données... Veuillez réessayer.`
          );
        });
    },
    formatDate(value) {
      return value ? new Date(value).toLocaleDateString() : "";
    },
    formatProduct(value) {
      return value && value == "Single Search Automation"
        ? "Notification d'une recherche unitaire"
        : value && value == "Bulk Search Automation"
        ? "Notification d'une recherche groupée"
        : value && value == "Bulk Search"
        ? "Recherche groupée"
        : "-";
    },
  },
  mounted: function () {
    this.getTransactions();
  },
};
</script>

<style scoped>
.payment-table {
  position: relative;
  top: 30px;
  width: 700px;
  margin: auto;
  text-align: center;
}
</style>