<template>
  <div>
    <Sidebar />
    <div class="app-container">
      <div class="fileSearch-headers">
        <div class="heading pt-3">Recherche groupée dans un fichier</div>
        <div
          class="header-description-text"
          v-html="headerText[step - 1]"
        ></div>
      </div>
      <FileSearchProgression :textArray="texts" steps="5" />
      <ImportFile v-if="step == 1" />
      <ConfigureFile v-if="step == 2" />
      <LoadFile v-if="step == 3" />
      <ParametrizeFile v-if="step == 4" />
      <ValidateFile v-if="step == 5" />
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import FileSearchProgression from "../../components/FileSearchProgression";
import Sidebar from "../../components/layout/Sidebar";
import ImportFile from "./fileSearch/ImportFile";
import ConfigureFile from "./fileSearch/ConfigureFile";
import LoadFile from "./fileSearch/LoadFile";
import ValidateFile from "./fileSearch/ValidateFile";
import ParametrizeFile from "./fileSearch/ParametrizeFile";
import FileStorage from "../../services/FileStorage";

export default {
  name: "FileSearch",
  components: {
    FileSearchProgression,
    Sidebar,
    ImportFile,
    ConfigureFile,
    LoadFile,
    ValidateFile,
    ParametrizeFile,
  },
  data: () => ({
    texts: ["Fichier", "Configuration", "Traitement", "Paramétrer", "Valider"],
    headerText: [
      `Vous pouvez ici uploader un fichier csv contenant une personne par ligne, 
            DCD va rechercher ces individus dans la base INSEE des décès.
            <br><br>
            La première  étape consiste à extraire de votre système 
            d’information et à enregistrer dans un fichier 
            csv les données d’utilité.<br> Pour savoir quelles 
            données extraire et à quel format, consulter la page 
            <a href='/faq' target='_blank'><strong>FAQ</strong></a>.`,

      `La deuxieme étape consiste à nous indiquer quelle colonne de votre 
            fichier contient quelle information. C’est ce qu’on appelle apparier les colonnes.
            <br><br>
            Votre fichier peut contenir des colonnes avec des informations sans 
            utilité pour la recherche, il suffit de ne pas apparier ces colonnes, 
            elles seront simplement ignorées lors du traitement mais seront toujours 
            présentes dans le fichier final que vous obtiendrez. Pour savoir quelles 
            données extraire et à quel format, consulter la page 
            <a href='/faq' target='_blank'><strong>FAQ</strong></a>.`,

      ``,

      ``,

      ``,
    ],
  }),
  created: function () {
    // Manage the case there is a file already stored in the localStorage
    // due to a waiting file
    if (FileStorage.hasFileStored()) {
      var fileStorage = FileStorage.getFileData();
      console.log("CREATED FILE SEARCH", fileStorage);
      Object.entries(fileStorage).forEach((entry) => {
        console.log("LOOOP", entry[0], entry[1]);
        this.$store.commit("setFileSearchValue", [entry[0], entry[1]]);
      });
    }
    console.log("ALOOORS", this.step);
  },
  computed: {
    ...mapFields([
      "fileSearch.step",
      "fileSearch.file",
      "fileSearch.fileName",
      "fileSearch.data",
      "fileSearch.dataArray",
      "fileSearch.separator",
      "fileSearch.fileSent",
    ]),
  },
};
</script>

<style scoped>
</style>