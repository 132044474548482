import Vue from 'vue'
// import { use } from 'vue/types/umd';
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';
import { API } from '../services/Api'
import FileStorage from '../services/FileStorage';
import { SessionStorage, TokenService } from '../services/Storage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userStatus: '',
    // stripe: null,
    message: false,
    textMessage: '',
    messageType: 'error',
    singleSearch: {
      filters: [],
      results: [],
      lastName: '',
      firstName: '',
      gender: '',
      birthDate: '',
      birthCity: '',
      birthZipcode: '',
      birthDep: '',
      birthCountry: '',
      deathDate: '',
      deathAge: '',
      deathCity: '',
      deathZipcode: '',
      deathDep: '',
      deathCountry: '',
    },
    fileSearch: {
      step: 1,
      acceptCGU: false,
      file: null,
      fileName: '',
      data: '',
      dataColMapping: {},
      fileSent: null,
      storedValues: false,
      dataArray: [],
      separator: null,
      loadingValue: 0,
      needLoading: true,
      jobId: '',
      threshold: 0.7,
      prevalence: "-",
      results: {},
      deadPeopleExtract: [],
      columns: [],
      defaultColumns: [
        {
          name: 'Identifiant du patient',
          formalName: 'client_patient_id',
          matching: '',
          helpText: [
            `Le numéro d’identification du patient dans votre établissement est optionnel.`,
            `Il permet de vous faciliter la réintégration des informations retournées par notre solution dans votre système.`
          ]
        },
        {
          name: 'Nom*',
          formalName: 'patient_nom_famille',
          matching: '',
          helpText: [`Champ nécéssaire.`]
        },
        {
          name: 'Nom de jeune fille',
          formalName: 'patient_nom_alternatif',
          matching: '',
          helpText: [``]
        },
        {
          name: 'Prénom(s)*',
          formalName: 'patient_prenom',
          matching: '',
          helpText: [
            `Champ nécéssaire. Le premier prénom suffit, mais vous pouvez en envoyer autant que vous voulez, the more the merrier!`
          ]
        },
        {
          name: 'Date de naissance*',
          formalName: 'patient_DDN',
          matching: '',
          helpText: [
            `Date de naissance du patient - champ nécéssaire. Les différents formats acceptées pour un champ "date" sont :`,
            `   - sans séparateur, ex: YYYYMMDD, DDMMYYYY`,
            `   - avec séparateur parmi les suivants : '/', '-', ':', '.'`,
            `A noter que les dates commençant par le mois ne sont pas acceptées.`
          ]
        },
        { name: 'Pays de naissance', formalName: 'patient_pays_naissance', matching: '', helpText: [``] },
        { name: 'Ville de naissance', formalName: 'patient_ville_naissance', matching: '', helpText: [``] },
        {
          name: 'Sexe',
          formalName: 'patient_sexe',
          matching: '',
          helpText: [
            `Sexe du patient. Les différentes valeurs acceptées sont`,
            `   - 0 / 1 (avec 0 pour homme, et 1 pour femme)`,
            `   - M / F`,
            `   - H / F`,
            `   - MR / MME (mr/mme, Mr/Mme)`,
          ]
        },
        {
          name: 'Code postal', // TODO SEE idf code INSEE
          formalName: 'patient_code_postal',
          matching: '',
          helpText: [
            `Dernier code postal d’habitation connu. Cette donnée est prise en compte par l'algorithme.`,
            `Si cette donnée n’est pas assez ou pas du tout renseignée dans le fichier que vous nous envoyez, 
                l’algorithme utilisera à la place le code postal de votre établissement, si vous l’avez renseigné. 
                Si aucun des deux n’est disponible, le système fonctionnera quand même mais sera moins performant.`
          ]
        },
        {
          name: 'Date de dernières nouvelles',
          formalName: 'patient_date_derniere_nouvelle',
          matching: '',
          helpText: [
            `La date de dernières nouvelles ou date du dernier rendez-vous correspond à dernière date 
                à laquelle le patient est venu dans l'établissement. 
                Par définition un patient ne peut pas être décédé AVANT cette date, mais forcément après.`
          ]
        },
        {
          name: 'Date du prochain rendez-vous',
          formalName: 'patient_date_prochain_RDV',
          matching: '',
          helpText: [
            `La date de prochaine activité prévue dans l’établissement permet de savoir 
                s'il y a une activité dans le futur à annuler pour un patient décédé.`
          ]
        },
        {
          name: 'Date de décès',
          formalName: 'patient_date_deces_connu',
          matching: '',
          helpText: [
            `La date de décès pour les patients que vous savez être décédés. Cette donnée est utilisée pour :`,
            `   - Lancer la recherche également sur ces patients pour voir s'ils sont retrouvés et donc calculer 
                les performances du système et vous aider à choisir le score seuil`,
            `   - Entraîner le modèle de machine learning pour que le système s’adapte à vos données`
          ]
        },
        {
          name: 'NIR (10 premiers chiffres)',
          formalName: 'patient_NIR',
          matching: '',
          helpText: [
            `Le NIR est aussi appelé numéro de sécurité sociale. 
                Il contient 13 chiffres. Pour des raisons de confidentialité, nous vous demandons de ne nous envoyer que 
                les 10 premiers numéros (NIR 10). Tout fichier contenant ne serait-ce qu’un seul NIR complet (avec 13 chiffres) 
                sera refusé en bloc par le système.`,
            `Les 10 premiers chiffres du NIR correspondent au sexe, mois et année de naissance et commune de 
                naissance et ne contient donc pas d’information sensible.`
          ]
        },
        {
          name: 'Médecin à prévenir',
          formalName: 'patient_medecin',
          matching: '',
          helpText: [`Identification du médecin à prévenir en cas de décès. Champ complètement optionnel.`]
        },
        {
          name: 'Décès connu',
          formalName: 'patient_connu_dcd',
          matching: '',
          helpText: [
            `Décès connu : colonne avec des valeurs binaires : 0 / 1 ("1" pour "oui", "0" pour "non").`,
            `'Oui' quand vous savez que l’individu est décédé, que vous en connaissiez ou non la date.`,
            `Cela est utile pour la même raison que la date de décès connue, c'est-à-dire pour calculer les performance 
                du sytème et améliorer la performance des résutlats du modèle de machine learning sur vos données.`
          ]
        },
        {
          name: `Date d'extraction des données`,
          formalName: 'client_extraction_date', // TODO verify the name
          matching: '',
          type: 'input',
          helpText: [
            `Date à laquelle vous avez extrait ces données de votre système.`
          ]
        },
      ]
    },
  },
  mutations: {
    updateField,
    resetSingleSearch(state) {
      var keys = Object.keys(state.singleSearch)
      keys.forEach((key) => {
        state.singleSearch[key] = Array.isArray(state.singleSearch[key]) ? [] : "";
      })
    },
    resetMessageAndSripe(state) {
      // state.stripe = null
      state.message = false
      state.textMessage = ''
      state.messageType = 'error'
    },
    setSingleSearchValue(state, [key, value]) {
      // NOT USED
      state.singleSearch[key] = value
    },
    setFileSearchValue(state, [key, value]) {
      state.fileSearch[key] = value
    },
    setValueDataColMapping(state, [key, value]) {
      state.fileSearch.dataColMapping[key] = value
    },
    setGenderValue(state, value) {
      state.singleSearch.gender = value
    },
    increaseStep(state, value) {
      state.fileSearch.step = state.fileSearch.step < 5 ? value : state.fileSearch.step
    },
    decreaseStep(state, value) {
      state.fileSearch.step = state.fileSearch.step > 1 ? value : state.fileSearch.step
    },
    resetFileVariables(state) {
      state.fileSearch.columns = JSON.parse(JSON.stringify(state.fileSearch.defaultColumns))
      state.fileSearch.data = ''
      state.fileSearch.dataArray = []
      state.fileSearch.dataColMapping = {}
      state.fileSearch.separator = null
      state.fileSearch.loadingValue = 0
      state.fileSearch.needLoading = true
      state.fileSearch.jobId = ''
      state.fileSearch.storedValues = false
      state.fileSearch.threshold = 0.7
      state.fileSearch.prevalence = "-"
      state.fileSearch.results = {}
      state.fileSearch.deadPeopleExtract = []
      state.fileSearch.fileSent = null
      state.fileSearch.fileName = ''
      state.fileSearch.step = 1
    },
    processLogin(state) {
      state.userStatus = 'loading'
    },
    loginSuccess(state) {
      state.userStatus = 'success'
    },
    loginError(state) {
      state.userStatus = 'error'
    },
    logoutSuccess(state) {
      state.userStatus = ''
    },
    setMessageValue(state, value) {
      state.message = value
    },
    setTextMessage(state, value) {
      state.textMessage = value
    },
    setMessageType(state, value) {
      state.messageType = value
    },
    removeMessage(state) {
      state.message = false
      state.textMessage = ''
    },
  },
  getters: {
    getField,
  },
  actions: {
    displayMessage(context, data) {
      context.commit('setMessageValue', true)
      context.commit('setTextMessage', data.text)
      context.commit('setMessageType', data.type)
      // setTimeout(function() {
      //   context.commit('removeMessage')
      // }, 8000)
    },
    updatetDataFileSearch({ state }) {
      return new Promise((resolve, reject) => {
        var jobId = state.fileSearch.jobId
        console.log("JOOOOO", jobId)
        API.get(`bulk/search/?job_id=${jobId}`)
          .then(response => {
            console.log("RESPONSE RESULTS DATA", response)
            var data = typeof response.data.meta.data == "string" ? JSON.parse(response.data.meta.data) : response.data.meta.data
            var filteredData = data.filter((personData) => {
              return personData.dcd_patient_decede
            })
            filteredData.sort(function (a, b) {
              // return 1 if b before a
              // return -1 if a before b
              if (a["dcd_patient_matchid_nom_famille"] == null) {
                return 1  // b before
              }
              if (b["dcd_patient_matchid_nom_famille"] == null) {
                return -1
              }
              // Now sort on the score
              return -(a["dcd_patient_score"] - b["dcd_patient_score"])
            })
            state.fileSearch.deadPeopleExtract = filteredData.length < 11 ? filteredData : filteredData.slice(0, 10)
            console.log("DEAD DATA", data, filteredData)
            state.fileSearch.step = state.fileSearch.step < 5 ? 5 : state.fileSearch.step
            resolve()
          })
          .catch(e => {
            console.log("erreur display results", e)
            reject()
          })
      })
    },
    // NOT USED
    getStripeKey(context) {
      // Get the stripe key and store the Stipe object created with the key
      console.log("ENTER GET STRIPE")
      API.get('payment/key')
        .then(response => {
          console.log("RESPONSE KEY PAYEMENT", response)
          if (response.status == 200) {
            // Initialize Stripe.js
            context.state.stripe = Stripe(response.data.public_key) // eslint-disable-line no-undef
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => context.dispatch('getStripeKey'))
              .catch(() => {
                context.state.stripe = null
                var messageData = { "text": "Une erreur est survenue lors de la connexion au serveur", 'type': 'error' }
                context.dispatch('displayMessage', messageData)
              })
          } else {
            context.state.stripe = null
            var messageData = { "text": "Une erreur est survenue, veuillez réessayer.", 'type': 'error' }
            context.dispatch('displayMessage', messageData)
          }
        })
        .catch(() => {
          context.state.stripe = null
          var messageData = { "text": "Une erreur est survenue, veuillez réessayer.", 'type': 'error' }
          context.dispatch('displayMessage', messageData)
        })
    },
    login: (context, userData) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        context.commit('processLogin')
        FileStorage.removeFileData()
        API.post('user/login', userData)
          .then(response => {
            console.log("RESPONSE LOGIN ACTION", response)
            if (response.status == 200) {
              // Connection to the server
              if (response.data.status == 401) {
                console.log("ERROR 401")
                TokenService.removeToken()
                reject(response)
              } else if (response.data.status == 200 || response.data.status == 201) {
                const responseData = response.data.data
                const token = responseData.client_token
                const client_refresh_token = responseData.client_refresh_token
                console.log("TOKEN", token, "REFRESH TOKEN", client_refresh_token)
                TokenService.saveToken(token)
                TokenService.saveClientEmail(userData.client_email)
                TokenService.saveClientId(responseData.client_id)
                TokenService.saveClientAdmin(responseData.client_role === "admin")  // TODO REMOVE
                TokenService.saveCookie(responseData.client_cookie)
                SessionStorage.saveClientRefreshToken(client_refresh_token)
                context.commit('loginSuccess')
                API.setHeader()
                resolve(response)
              } else {
                TokenService.removeToken()
                reject(response)
              }
            } else {
              // No connection with the server
              TokenService.removeToken()
              reject(response)
            }
          })
          .catch(err => {
            context.commit('loginError', err)
            TokenService.removeToken() // if the request fails, remove any possible user token if possible
            reject(err)
          })
      })
    },
    register: async (context, userData) => {
      return new Promise((resolve, reject) => {
        API.post('/user/', userData)
          .then((response) => {
            console.log("RESPONSE", response)
            if (response.data.status == 400) {
              reject(["Cet utilisateur existe déjà."])
            } else if (response.data.status == 200 || response.data.status == 201) {
              const clientId = response.data.data.client_id
              console.log("CLIENT ID", clientId)
              TokenService.saveClientEmail(userData.client_email)
              TokenService.saveClientId(clientId)
              // TODO save cookie
              context.dispatch('login', userData) // then login
                .then(() => resolve())
                .catch(() => {
                  console.log("INSCRIPTION OK MAIS PAS LE LOGIN")
                  reject()
                })
            }
          })
          .catch(e => {
            console.log("ERROR REGISTER", e)
            reject()
          })
      })
    },
    logout: async ({ commit }) => {
      FileStorage.removeFileData();
      SessionStorage.removeClientRefreshToken();
      commit('resetFileVariables')
      commit('setFileSearchValue', ['file', null])
      commit('setFileSearchValue', ['acceptCGU', false])
      commit('resetSingleSearch')
      commit('resetMessageAndSripe')
      console.log("LOGOUT", localStorage, { client_email: TokenService.getClientEmail()})
      try {
        await API.post("/user/logout", {client_email: TokenService.getClientEmail()})
      } catch(e) {
        console.log("ERROR", e)
      }
      API.removeHeader()
      TokenService.removeAllKeys()
      commit('logoutSuccess')
    },
    processFileLoading: ({ commit }, data) => {
      commit('setFileSearchValue', ['loadingValue', 0])
      commit('setFileSearchValue', ['jobId', data.jobId])
      // No need to reload if the columns or file do not change
      commit('setFileSearchValue', ['needLoading', false])
      commit('increaseStep', 3)
    },
  }
})


export default store
