<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2" style="color: #2b3e4a">
      Vérification du fichier
    </v-card-title>

    <v-card-text class="pt-5">
      Nous vérifions votre fichier, cela ne devrait pas durer longtemps...
      <br /><br />Vous pouvez néamoins en profiter pour prendre une pause !
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "WaitingModal",
  data: () => ({}),
};
</script>

<style scoped>
</style>