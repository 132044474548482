const TOKEN_KEY = 'access_token'
const CLIENT_ID = 'client_id'
const CLIENT_EMAIL = 'client_email'
const CLIENT_ADMIN = 'client_admin'
const COOKIE = 'cookie'
const CLIENT_REFRESH_TOKEN = "client_refresh_token"

// const REFRESH_TOKEN_KEY = 'refresh_token'


const TokenService = {
  removeAllKeys() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(CLIENT_ID)
    localStorage.removeItem(CLIENT_EMAIL)
    localStorage.removeItem(CLIENT_ADMIN)
    localStorage.removeItem(COOKIE)
  },
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  },

  getClientId() {
    return localStorage.getItem(CLIENT_ID)
  },

  saveClientId(clientId) {
    localStorage.setItem(CLIENT_ID, clientId)
  },

  removeClientId() {
    localStorage.removeItem(CLIENT_ID)
  },

  getClientEmail() {
    return localStorage.getItem(CLIENT_EMAIL)
  },

  saveClientEmail(clientEmail) {
    localStorage.setItem(CLIENT_EMAIL, clientEmail)
  },

  removeClientEmail() {
    localStorage.removeItem(CLIENT_EMAIL)
  },

  getClientAdmin() {
    return localStorage.getItem(CLIENT_ADMIN)
  },

  saveClientAdmin(clientAdmin) {
    localStorage.setItem(CLIENT_ADMIN, clientAdmin)
  },

  removeClientAdmin() {
    localStorage.removeItem(CLIENT_ADMIN)
  },

  getCookie() {
    return localStorage.getItem(COOKIE)
  },
  saveCookie(cookie) {
    localStorage.setItem(COOKIE, cookie)
  },
  removeCookie() {
    localStorage.removeItem(COOKIE)
  },
}

const SessionStorage = {
  getClientRefreshToken() {
      return sessionStorage.getItem(CLIENT_REFRESH_TOKEN)
  },

  saveClientRefreshToken(refreshToken) {
    sessionStorage.setItem(CLIENT_REFRESH_TOKEN, refreshToken)
  },

  removeClientRefreshToken() {
    sessionStorage.removeItem(CLIENT_REFRESH_TOKEN)
  }
}

export { TokenService, SessionStorage }