<template>
  <div class="static-page">
    <Navbar />
    <div class="page-content">
      <div class="page-title">CONDITIONS GÉNÉRALES D’UTILISATION</div>
      <p>Mise à jour le 19 novembre 2021</p>
      <div class="page-subtitle">Préambule</div>
      <div class="page-paragraphe">
        La plateforme "DCD", accessible à l’adresse https://risoft.fr met à
        disposition de ses utilisateurs des services de consultation de données
        permettant de déterminer si une personne est décédée ou non (ci-après la
        " <strong>Plateforme</strong> ").
        <br />
        Celle-ci est opérée par la société RISOFT, SAS immatriculée au Registre
        du Commerce et des Sociétés de Paris sous le numéro B 890 290 877, au
        capital social de 1.000,00 €, et dont le siège social est situé 208, rue
        de Vaugirard 75015 Paris (France) (ci-après "
        <strong>RISOFT</strong> ").
      </div>
      <div class="page-subtitle">1. Définitions</div>
      <div class="page-paragraphe">
        Lorsqu’ils sont utilisés dans les présentes conditions générales
        d’utilisation (y compris le préambule, les éventuelles annexes et
        intitulés des articles), les termes suivants dont l’initiale est en
        majuscule, ont le sens qui leur est donné dans le présent article, peu
        importe qu’ils soient utilisés au singulier ou au pluriel :
        <ul>
          <li>
            " <strong>Conditions Générales d'Utilisation </strong>" ou " CGU " :
            désigne le présent contrat, qui définit et encadre les modalités
            d’accès et d’utilisation de la Plateforme, et détermine les droits
            et obligations respectifs des Parties.
          </li>
          <li>
            "<strong>Données</strong>" : désignent ensemble les Données
            collaboratives et les Données INSEE recoupées dans la Plateforme.
          </li>

          <li>
            " <strong>Données personnelles</strong> " : désignent les
            informations au sens de la loi n°78-17 du 6 janvier 1978 modifiée
            par le règlement européen n° 2016/679 relatif à la protection des
            données à caractère personnel, susceptibles d’être traitées et
            analysées par les Parties dans le cadre des CGU.
          </li>
          <li>
            " <strong>Données collaboratives</strong> " : désignent les
            informations renseignées par les Utilisateurs sur la Plateforme, en
            particulier les noms, prénoms, sexe, date de naissance, commune de
            naissance, commune de décès et date de décès de la personne
            recherchée.
          </li>
          <li>
            "<strong> Données INSEE</strong> " : désignent les informations
            publiques issues du fichier des personnes décédées depuis 1970 en
            France, établi par l’Institut National de la Statistique et des
            Études Économiques (INSEE).
          </li>
          <li>
            " <strong>Droit d’Accès</strong> " : a la signification qui lui est
            attribuée à l’Article 7 – Droits de propriété intellectuelle et
            accès à la Plateforme.
          </li>
          <li>
            " <strong>Partie</strong> " : désigne individuellement l’Utilisateur
            ou RISOFT et ensemble l’Utilisateur et RISOFT.
          </li>
          <li>
            " <strong>Plateforme</strong> " : a la signification qui lui est
            attribuée dans le Préambule.
          </li>
          <li>
            " <strong>Recherche unitaire</strong> " : désigne la recherche d’une
            seule personne dans la base de données de la Plateforme.
          </li>
          <li>
            " <strong>Recherche groupée</strong> " : désigne la recherche d’une
            liste d’individus dans la base de données de la Plateforme.
          </li>
          <li>
            " <strong>Réglementation Données personnelles</strong> " : désigne
            la loi n°78-17 du 6 janvier 1978 modifiée par le règlement européen
            n° 2016/679 relatif à la protection des données à caractère
            personnel.
          </li>
          <li>
            " <strong>Services</strong> " : désignent les services proposés par
            RISOFT aux Utilisateurs, sur la Plateforme.
          </li>
          <li>
            " <strong>Utilisateur</strong> " : désigne tout visiteur,
            professionnel ou particulier, qui visite et/ou utilise la
            Plateforme.
          </li>
        </ul>
      </div>
      <div class="page-subtitle">2. Objet</div>
      <div class="page-paragraphe">
        Les présentes Conditions Générales d’Utilisation encadrent juridiquement
        l’utilisation des Services par les Utilisateurs. Ainsi, le simple accès
        ou l’utilisation de la Plateforme implique l’adhésion pleine et entière
        de l’Utilisateur aux présentes Conditions Générales d’Utilisation et
        l’oblige à s’y conformer strictement.
        <br />
        L’Utilisateur déclare avoir pris connaissance des caractéristiques et
        des fonctionnalités de de la Plateforme et des Services associés, qu’il
        a reçu de la part de RISOFT tous conseils, instructions et précisions
        nécessaires pour souscrire aux présentes CGU en toute connaissance de
        cause.
        <br />
        Ces Conditions Générales d’Utilisation sont accessibles à partir du
        formulaire d’inscription de la Plateforme ou à la rubrique “Conditions
        Générales d’Utilisation” de la Plateforme. Sauf disposition expresse
        contraire, tout nouveau Service ou modification de Service est soumis
        aux présentes Conditions Générales d’Utilisation.
        <br />
        Il est conseillé aux Utilisateurs de consulter régulièrement les
        Conditions Générales d’Utilisation, afin de se référer à la dernière
        version en vigueur. Dans l’hypothèse où les Utilisateurs ne souhaitent
        pas accepter tout ou partie des présentes Conditions Générales
        d’Utilisation, il leur est demandé de renoncer à tout usage de la
        Plateforme.
      </div>
      <div class="page-subtitle">
        3. Présentation de la Plateforme et des Services
      </div>
      <div class="page-paragraphe">
        <ul>
          <li>
            <strong>3.1. Fonctionnement de la Plateforme</strong>
            <br />
            La Plateforme intègre un algorithme de rapprochement entre les
            Données de l’INSEE et les Données collaboratives renseignées par les
            Utilisateurs. La concordance des Données opérée par l’algorithme
            permet d’obtenir un résultat, présenté sous forme de score de
            fiabilité, indiquant le degré de concordance entre une personne
            recherché et une personne présente dans la liste de l’INSEE.
          </li>
          <br />
          <li>
            <strong>3.2. Service gratuit de consultation de Données </strong>
            <br />
            RISOFT met à disposition des Utilisateurs, quel que soit leur
            profil, un Service de consultation des Données disponibles sur la
            Plateforme. Pour accéder à l’information relative au décès d’une ou
            plusieurs personne(s) physique(s) et au score de fiabilité qui y est
            associé, l’Utilisateur doit renseigner des Données collaboratives
            relatives à ces personnes physiques. À ce titre l’Utilisateur se
            conforme aux prescriptions de l’Article 6.2.3. – Données
            personnelles relatives à une personne vivante.
            <br />
            Ce Service est gratuit dans la limite d’un seuil de consultations de
            Données indiqué sur la Plateforme. Au-delà de ce seuil, le Service
            de consultation de Données devient payant selon les modalités
            indiquées à l’Article 3.3.1. – Service " Premium " de consultation
            de Données.
          </li>
          <br />
          <li>
            <strong>3.3. Services payants </strong>
            <br />
            L’Utilisateur peut souscrire à des Services payants directement sur
            la Plateforme dans les conditions ci-après définies. Le prix et la
            durée d’accès à un Service payant est indiqué à l’Utilisateur avant
            qu’il n’y souscrive.
            <ul>
              <li>
                <i>3.3.1. Service " Premium " de consultation de Données</i>
                <br />Le Service de consultation de Données décrit ci-avant
                devient payant au-delà d’un certain nombre de résultats
                communiqués à l’Utilisateur, dont le seuil est indiqué sur la
                Plateforme.
              </li>
              <li>
                <i>3.3.2. Service d’alertes</i> <br />Un Service d’alertes basé
                sur une Recherche Unitaire ou une Recherche Groupée d’individus
                enregistrée, permet à l’Utilisateur d’être notifié par courriel,
                en cas de décès de(s) l’individu(s) renseigné(s) dans ladite
                recherche. La conservation des données renseignées par
                l’Utilisateur dans le cadre de ce Service est d’une durée d’un
                an. Ce Service est payant dans les conditions indiquées sur la
                Plateforme. L’accès aux résultats est conditionné au règlement
                du prix indiqué.
              </li>

              <li>
                <i>3.3.3. Prestations sur devis</i>
                <br />
                Un Service permet à l’Utilisateur professionnel (i) d’extraire
                des informations de son système d’information, et (ii) de
                connecter son système d’information directement à la Plateforme
                via une API, ou (iii) de déployer la solution de la Plateforme
                en local.
                <br />
                La réalisation de ce Service est conditionnée à l’acceptation
                par l’Utilisateur d’un devis correspondant communiqué par
                RISOFT.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="page-subtitle">
        4. Conditions d’utilisation de la Plateforme
      </div>
      <div class="page-paragraphe">
        <ul>
          <li>
            <strong>4.1. Création de compte Utilisateur </strong>
            <br />
            L’accès à la Plateforme requiert la création préalable d’un compte
            Utilisateur, ouverte aux personnes physiques ou morales ayant la
            capacité de contracter pour ses besoins propres ou professionnels.
            <br />
            Chaque compte Utilisateur correspond à une seule source de données.
            <br />
            Pour ce faire, l’Utilisateur complète un formulaire d’inscription
            dans lequel il renseigne les informations requises permettant à
            RISOFT de l’identifier de manière certaine. Tout changement de
            coordonnées devra faire l’objet d’une modification de compte par
            l’Utilisateur pour renseigner ses coordonnées actualisées. RISOFT
            recommande de ne pas utiliser d’adresse électronique temporaire pour
            la création de compte Utilisateur.
            <br />
            L’Utilisateur peut créer plusieurs comptes Utilisateur sur la
            Plateforme dès lors (i) qu’il respecte les CGU et (ii) que
            l’utilisation cumulée des différents comptes ne lui permet pas
            d’accéder gratuitement à des Services payants.
            <br />
            L’Utilisateur crée un identifiant unique et un mot de passe
            personnel et confidentiel pour valider la création de son compte et
            accéder aux Services. L’Utilisateur est seul responsable de
            l’utilisation de son identifiant et de son mot de passe. Il s’engage
            à conserver celui-ci secret, et à ne le divulguer sous aucun
            prétexte, et de quelque manière que ce soit.
            <br />
            L’Utilisateur veille à pouvoir consulter régulièrement les messages
            qui lui seront adressés par RISOFT aux coordonnées indiquées lors de
            la création de son compte.
          </li>
          <br />
          <li>
            <strong>4.2. Accès et utilisation de la Plateforme</strong>
            <ul>
              <li>
                <i>4.2.1. Durée d’accès </i> <br />L’accès aux Services gratuits
                de la Plateforme est disponible pendant toute la durée du
                maintien en vigueur du compte Utilisateur. Les Services payants
                sont quant à eux disponibles pendant la durée précisée au moment
                de leur souscription.
              </li>
              <li>
                <i>4.2.2. Usage raisonnable</i>
                <br />
                L’Utilisateur est responsable de la pertinence des Données
                consultées pour l’objectif qu’il s’est défini. RISOFT ne connaît
                pas les motivations de l’Utilisateur lorsqu’il utilise la
                Plateforme et ne peut à ce titre intervenir sur la pertinence
                des données renseignées ou recherchées par l’Utilisateur.
                <br />
                L’Utilisateur s’engage à (i) accéder à la Plateforme au moyen de
                navigateurs internet ordinaires uniquement (ii) utiliser la
                Plateforme et/ou les Données à des fins personnelles ou
                professionnelles à l’exclusion de tout usage commercial, (iii)
                ne pas contourner ou tenter de contourner les fonctionnalités
                liées aux Services, à la sécurité de la Plateforme ou des autres
                Utilisateurs, ainsi qu’à celles liées à la restriction de leur
                utilisation ou à la copie de leur contenu ou Services.
              </li>
              <li>
                <i>4.2.3. Suspension et suppression de compte</i>
                <br />
                Lorsqu’un Utilisateur ne respecte pas les termes des CGU ou
                lorsqu’il fait un usage anormal de la Plateforme, des Services
                ou des Données, RISOFT se réserve le droit de bloquer l’accès ou
                de supprimer un compte Utilisateur, selon la gravité du
                manquement reproché.
                <br />
                Dans ce cas, RISOFT informe l’Utilisateur par écrit des raisons
                objectives motivant sa décision. L’Utilisateur dispose d’un
                délai de quinze (15) jours à compter de l’envoi de la
                notification pour contester la décision de suspension ou de
                suppression en clarifiant les faits auprès de RISOFT, par écrit.
                À défaut d’explications satisfaisantes, la décision de
                suspension ou de suppression deviendra effective dans un délai
                de quinze (15) jours supplémentaires à compter de la réception
                des explications de l’Utilisateur.
                <br />
                Cette décision est sans préjudice du droit de RISOFT d'engager
                les poursuites judiciaires qu'elle jugerait appropriées en
                raison d'un comportement déloyal et/ou contraire aux présentes
                Conditions Générales d'Utilisation. L’Utilisateur est informé
                qu’aucun remboursement d’un Service payant ne pourra avoir lieu.
                <br />
                Dans l’hypothèse où des informations obligatoires pour la
                création d’un compte Utilisateur seraient manquantes ou
                apparaitraient comme manifestement erronées (fausse identité,
                adresse fantaisiste, etc.), RISOFT se réserve le droit à sa
                discrétion, d’en alerter l’Utilisateur afin qu’il mette à jour
                ses informations ou bien de supprimer sans préavis le compte
                Utilisateur correspondant.
                <br />
                L’Utilisateur peut cependant s’inscrire en créant un pseudonyme,
                pour autant que l’adresse mail renseignée soit valide.
                <br />
                L’Utilisateur peut supprimer son compte à tout moment sur simple
                demande adressée à RISOFT.
                <br />
                L’Utilisateur est informé que la suppression de son compte met
                fin à son accès aux Services qu’ils soient payants ou non, sans
                remboursement possible.
                <br />
              </li>
              <li>
                <i>4.2.4. Niveaux de service</i>
                <br />
                RISOFT s’efforce dans la mesure du possible de maintenir
                accessible la Plateforme 7 jours sur 7 et 24 heures sur 24, mais
                n’est tenue à aucune obligation d’y parvenir. Toute évolution ou
                changement de la Plateforme peut entraîner une mise à jour et/ou
                une indisponibilité temporaire de la Plateforme, laquelle ne
                saurait en aucune manière engager la responsabilité de RISOFT.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="page-subtitle">
        5. Conditions financières et renonciation au droit de rétractation
      </div>
      <div class="page-paragraphe">
        Les différents types et les caractéristiques essentielles des Services
        payants sont décrits sur la Plateforme. Le prix indiqué pour chaque
        Service correspond au prix en euros, toutes taxes comprises (TTC), frais
        de port inclus. Les Services payants sont facturés à l’Utilisateur sur
        la base des tarifs en vigueur sur la Plateforme au moment de la
        validation de la commande. Le paiement de la totalité du prix s’effectue
        au moment de la validation de la commande et sans frais supplémentaires
        : par carte bancaire, via une connexion sécurisée. Une facture est
        établie par RISOFT et disponible dans le compte Utilisateur.
        <br />
        RISOFT ne pourra être tenue responsable de tout usage frauduleux du
        moyen de paiement utilisé. L’Utilisateur garantit à RISOFT qu’il est
        pleinement autorisé à utiliser le moyen de paiement choisi pour le
        paiement de sa commande et que ce moyen de paiement donne légalement
        accès à des fonds suffisants pour couvrir tous les coûts résultants de
        sa commande.
        <br />
        Les paiements effectués par l’Utilisateur ne seront considérés comme
        définitifs et, le cas échéant, l’accès aux Services ne sera effectif
        qu’après encaissement des sommes dues à RISOFT.
        <br />
        L’Utilisateur est informé que s’agissant de Services en ligne, le droit
        de rétractation ne s’applique pas et est expressément exclu conformément
        à l’article L221-28 du Code de la consommation.
      </div>
      <div class="page-subtitle">6. Données de la Plateforme</div>
      <div class="page-paragraphe">
        RISOFT garantit que les Données accessibles depuis la Plateforme sont
        obtenues légalement et en conformité avec la réglementation applicable,
        grâce notamment au respect par les Utilisateurs des dispositions
        relatives aux Données collaboratives.
        <ul>
          <br />
          <li>
            <strong>6.1. Données INSEE</strong> <br />RISOFT s’engage à faire
            usage des Données INSEE dans des conditions conformes au Code des
            relations entre le public et l’administration, et dans le respect de
            la Réglementation Données personnelles.
            <br />
            L’Utilisateur est informé que les fichiers des personnes décédées
            sont établis par l’INSEE à partir des informations reçues des
            communes dans le cadre de leur mission de service public ; ils
            constituent, à ce titre, un document administratif. Ils sont publiés
            en application des dispositions des articles L311-9 et L312-1-1 du
            code des relations entre le public et l’administration, et
            réutilisables dans les conditions prévues par le titre II de ce
            livre. Les mairies ont un délai légal de transmission des bulletins
            de décès à l’INSEE, d’une semaine. Lorsque la transmission se fait
            sous forme papier, il faut ajouter un délai supplémentaire d’envoi
            postal et de saisie par les services de l’INSEE. En pratique, ces
            délais légaux peuvent être allongés pour une partie des communes,
            ainsi qu’en situation particulière (jour férié ou pont, et en ce
            moment avec les circonstances exceptionnelles liées à la situation
            sanitaire). Il en résulte que les Données mises à disposition un
            mois donné, ne comprennent pas tous les décès survenus durant le
            mois, les informations pouvant parvenir à l’INSEE dans le courant du
            ou des mois suivant(s). À l’inverse, le fichier du mois peut
            comprendre des décès survenus antérieurement. La construction du
            fichier pour un mois donné est basée sur la date d’enregistrement du
            décès par l’INSEE et non sur la date du décès elle-même.
            <br />
            <strong
              >L’INSEE et par conséquent, RISOFT, ne garantit pas que les
              fichiers des personnes décédées sont exempts d’omissions ou
              d’erreurs. Les informations contenues dans ces fichiers ne peuvent
              en aucun cas être utilisées dans un but de certification du statut
              vital des personnes.
            </strong>
            <br />
            Lorsque l’Utilisateur entend réutiliser des Données et/ou des
            Données INSEE disponibles sur la Plateforme, il s’engage à (i) ne
            pas les gérer directement ni à en dénaturer le sens, (ii) à
            mentionner la source et la date de leur dernière mise à jour, (iii)
            à respecter tous droits de propriété intellectuelle éventuellement
            détenus par des tiers à l’administration ainsi que des
            administrations elles-mêmes sur les bases de données qu’elles ont
            produites ou reçues dans l’exercice d’une mission de service public.
          </li>
          <br />
          <li>
            <strong>6.2. Données collaboratives</strong>
            <br />
            <ul>
              <li>
                <i>6.2.1. Données collaboratives </i>
                <br />
                Les Données collaboratives sont utiles à l’algorithme pour
                définir un rapprochement entre un individu recherché par
                l’Utilisateur et un individu dont les données figurent parmi les
                Données INSEE.
                <br />
                Les Données collaboratives renseignées par un Utilisateur dans
                la Plateforme peuvent, sans que cette liste ne soit limitative,
                correspondre aux : nom(s), prénom(s), sexe, date de naissance,
                commune de naissance, dix premiers chiffres du NIR, date de
                décès, commune de décès, dernier code postal d’habitation connu
                etc. Pour les Utilisateurs professionnels, il peut également
                s’agir du numéro d’identification du patient et/ou date des
                dernières nouvelles reçues et de l’identification du médecin à
                prévenir en cas de décès.
                <br />
                Cependant, dans un souci de minimisation de la collecte de
                Données personnelles, les Données collaboratives susceptibles
                d’être enregistrées dans la Plateforme sont exclusivement
                limitées aux suivantes : nom(s), prénom(s), sexe, date & commune
                de naissance, date & commune de décès.
                <br />
                Les Données relatives aux personnes vivantes ne sont pas
                enregistrées par la Plateforme.
                <br />
                Cependant, les Données renseignées lors d’une Recherche groupée
                sont conservées jusqu’à une heure après la fin du traitement, à
                l’exception de certaines données concernant les individus
                décédés.
              </li>
              <li>
                <i>6.2.2. Données relatives à une personne décédée</i>
                <br />
                En principe, seules les Données et informations concernant des
                personnes décédées sont susceptibles d’être enregistrées par la
                Plateforme.
                <br />
                Ces données sont publiques et ne peuvent être qualifiées de
                Données personnelles.
                <br />
                Le renseignement de Données relatives à une personne décédée par
                l’Utilisateur est en principe libre. Cependant, lorsque
                l’Utilisateur renseigne des données relatives à une personne
                décédée, il lui incombe de vérifier l’existence ou non de
                directives relatives au sort desdites données après la mort de
                l’individu et le cas échéant de les respecter. RISOFT ne saurait
                être responsable de la violation par l’Utilisateur des
                directives données par une personne sur le sort de ses données
                après sa mort.
              </li>
              <li>
                <i
                  >6.2.3. Données personnelles relatives à une personne
                  vivante</i
                >
                <br />
                L’Utilisateur peut renseigner des Données personnelles
                concernant une personne vivante, si elles sont exclusivement
                nécessaires aux Services et dans le respect de la réglementation
                relative à la protection des Données personnelles.
                <br />
                Ainsi l’Utilisateur s’engage à (i) ce que les Données
                personnelles ainsi renseignées aient fait l’objet d’une collecte
                loyale sur une base légale pertinente, (ii) ce que les personnes
                dont les données sont saisies et traitées sur la Plateforme
                aient été informées au préalable par ses soins, (iii)
                communiquer aux personnes concernées les informations relatives
                à leurs droits concernant la rectification, l’effacement, la
                portabilité, la limitation ou l’opposition au traitement de ces
                Données personnelle, (iv) ne renseigner sur la Plateforme aucune
                données de santé, celles-ci s’entendant des données provenant
                d’un établissement de santé, notamment l’information relative à
                la prise en charge d’un patient dans une structure de soin.
                <br />
                RISOFT apportera sa collaboration à tout Utilisateur qui en
                ferait la demande pour répondre aux demandes d’exercice de
                droits des individus dont les Données personnelles seraient
                traitées par la Plateforme et transmises par l’Utilisateur.
                RISOFT s’engage à mettre en œuvre tous les moyens techniques et
                organisationnels proportionnés au regard du niveau de sécurité
                attendu pour le traitement des Données personnelles qui
                transiteraient via la Plateforme. RISOFT est en charge du
                cryptage et de la sécurisation des Données. À ce titre, lorsque
                l’Utilisateur renseigne des Données personnelles sur une
                personne vivante, et choisit de les enregistrer dans un fichier
                sur la Plateforme pour bénéficier du Service d’alerte décrit
                ci-avant, ce fichier est chiffré, sécurisé et conservé par
                RISOFT exclusivement sur des serveurs français. La durée de
                conservation des Données collaboratives ayant la qualité de
                Données personnelles n’excède pas la durée d’un an à compter du
                paiement de ce Service. Cette durée est automatiquement
                renouvelée à l’expiration de ce délai d’un an, à moins que
                l’Utilisateur n’ait résilié son compte.
              </li>
              <li>
                <i
                  >6.2.4. Droit d’usage des Données collaboratives par RISOFT</i
                >
                <br />
                L’Utilisateur reconnaît et accepte expressément que RISOFT
                puisse enregistrer certaines Données collaboratives dans ses
                bases de données pour le bon fonctionnement de l’algorithme de
                la Plateforme et des Services. Seules certaines Données
                collaboratives mentionnées à l’Article 6.2.1. Données
                collaboratives sont enregistrées.
                <br />
                RISOFT s’engage à ne pas vendre tout ou partie des Données
                collaboratives renseignées par les Utilisateurs, à des sociétés
                tierces.
              </li>
            </ul>
            <br />
          </li>

          <li>
            <strong>6.3. Données personnelles des Utilisateurs</strong>
            <br />
            RISOFT s’engage à ne collecter que les données personnelles des
            Utilisateurs suivantes : (i) leur mail ; (ii) leur nom (ii) ; (iii)
            leur prénom ; (iv) code postal ; (v) domaine d’activité.
            <br />
            RISOFT s’engage à respecter toutes les obligations résultant de
            l’application de la Réglementation Données personnelles. Les Données
            personnelles traitées par RISOFT sont limitées aux informations du
            compte Utilisateur renseignées par l’Utilisateur et à toutes les
            informations relatives à son utilisation de la Plateforme et des
            Services pouvant être qualifiées de Données personnelles, aux fins
            de (i) gestion de la relation contractuelle, (ii) personnalisation
            de l’expérience Utilisateur et répondre aux besoins individuels
            (iii) contact de l’Utilisateur par courrier électronique. RISOFT
            conserve les Données personnelles des Utilisateurs jusqu’à la
            clôture de leur compte Utilisateur. RISOFT met en œuvre des mesures
            adaptées pour préserver la sécurité des Données personnelles des
            Utilisateurs. RISOFT défini des mesures techniques et
            organisationnelles permettant de protéger vos données de façon
            appropriée selon leur nature, l’étendue du traitement et leur
            accessibilité. Il peut s’agir par exemple de chiffrement des
            données, de gestion de droits d’accès, de flux sécurisés. Seuls les
            employés qui ont besoin d’effectuer un travail spécifique (par
            exemple, la facturation ou le service à la clientèle) ont accès aux
            Données personnelles identifiables. Les ordinateurs et serveurs
            utilisés pour stocker des Données personnelles identifiables sont
            conservés dans un environnement sécurisé.
            <br />
            L’Utilisateur peut demander à tout moment l’accès aux Données
            personnelles le concernant, leur rectification, leur effacement
            (dans la mesure où cela n’empêche pas la bonne exécution du contrat
            ou le respect des obligations légales de RISOFT) et la limitation
            d’un ou plusieurs traitements particuliers de données vous
            concernant, dans les conditions prévues par la Réglementation
            Données personnelles.
            <br />
            L’Utilisateur dispose également du droit de modifier ou de retirer,
            à tout moment, les consentements accordés pour le traitement de ses
            Données personnelles. L’Utilisateur dispose par ailleurs du droit de
            s’opposer à un traitement de ses Données personnelles et du droit à
            leur portabilité, dans les conditions fixées par la Réglementation
            Données personnelles. Les Données personnelles de l’Utilisateur
            peuvent être conservées ou supprimées après son décès conformément à
            la Réglementation Données personnelles. L’Utilisateur dispose du
            droit de donner instruction à RISOFT de communiquer ces données à un
            tiers préalablement désigné.
            <br />
            Les droits de l’Utilisateur sur ses Données personnelles prévus par
            la Règlementation Données personnelles peuvent être exercés en
            contactant RISOFT à info@risoft.fr par courrier postal à son siège
            social tel que spécifié dans les présentes Conditions Générales
            d’Utilisation.
            <br />
            L’Utilisateur dispose également du droit d'introduire une
            réclamation ou une plainte auprès de la CNIL s’il estime, après
            avoir contacté RISOFT, que ses droits ne sont pas respectés :
            <br />
            Soit en écrivant à l’adresse postale ci-dessous : Commission
            Nationale de l'Informatique et des Libertés 3 Place de Fontenoy -
            TSA 80715 75334 PARIS CEDEX 07 Tél : 01 53 73 22 22 Fax : 01 53 73
            22 00
            <br />
            Soit en cliquant sur le lien suivant :
            https://www.cnil.fr/fr/plaintes Les Parties s’engagent à collaborer
            activement afin de permettre l’exécution de toute formalité requise
            et, le cas échéant, d’obtenir les autorisations des autorités
            compétentes en matière de protection des Données personnelles.
          </li>
        </ul>
      </div>
      <div class="page-subtitle">
        7. Droits de propriété intellectuelle et accès à la Plateforme
      </div>
      <div class="page-paragraphe">
        La Plateforme, son contenu et ses Services, les marques, ainsi que ses
        logiciels, dessins, modèles, bases de données marques et logos ou tout
        autre signe distinctifs reproduits sur la Plateforme sont protégés par
        des droits de propriété intellectuelle.
        <br />
        RISOFT accorde à l’Utilisateur un droit d’accès à la Plateforme, non
        exclusif, non transférable, non cessible et non sous-licenciable (sauf à
        ses Utilisateurs internes autorisés dans le cas d’un client
        professionnel), pour toute la durée d’accès aux Services (ci-après le "
        Droit d’Accès "). Le Droit d’Accès est expressément limité au territoire
        de la France métropolitaine et à la Plateforme pour les Services
        souscrits par l’Utilisateur, par accès distant à partir de la connexion
        Internet de l’Utilisateur, en contrepartie du paiement du prix
        correspondant aux Services payants.
        <br />
        Tout accès non expressément autorisé par RISOFT au titre des présentes
        CGU est illicite. Ainsi, il est notamment interdit à l’Utilisateur de
        procéder de manière directe ou indirecte à : (i) toute représentation,
        diffusion ou distribution de la Plateforme et/ou des Services, que ce
        soit à titre onéreux ou gracieux et notamment toute mise en réseau, (ii)
        toute forme d’utilisation de la Plateforme et/ou des Service de quelque
        façon que ce soit, aux fins de conception, réalisation, diffusion ou
        commercialisation de service(s) similaire(s), (iii) toute utilisation
        pour un traitement non autorisé par RISOFT.
      </div>
      <div class="page-subtitle">8. Garanties</div>
      <div class="page-paragraphe">
        <ul>
          <br />
          <li>
            <strong>8.1. Garanties de RISOFT</strong>
            <br />
            RISOFT déclare et garantit disposer (i) du pouvoir et de la capacité
            de conclure les présentes CGU et (ii) des droits nécessaires pour
            concéder le Droit d’Accès prévu à l’Article 7 – Droits de propriété
            intellectuelle et accès à la Plateforme. RISOFT prendra à sa charge
            tous les dommages-intérêts auxquels pourrait être condamné
            l’Utilisateur par une décision de justice devenue définitive et
            ayant pour base exclusive la démonstration d'une contrefaçon du fait
            de l'utilisation de la Plateforme à condition que (i) l’Utilisateur
            ait notifié à bref délai, par écrit, l'action en contrefaçon ou la
            déclaration ayant précédé celle-ci, que (ii) RISOFT ait été en
            mesure d'assurer la défense de ses propres intérêts et de ceux de
            l’Utilisateur et, pour ce faire, que (iii) l’Utilisateur ait
            collaboré loyalement à ladite défense en fournissant tous les
            éléments, informations et assistance nécessaires pour mener à bien
            une telle défense. La garantie visée au présent article est
            expressément exclue lorsque la violation des droits de propriété
            intellectuelle de tiers et/ou la menace d’interdiction d’utilisation
            de tout ou partie de la Plateforme est due, en tout ou partie, à une
            faute ou négligence imputable à l’Utilisateur et/ou tout élément
            fourni par l’Utilisateur.
            <br />
            RISOFT ne garantit pas la continuité, l’accessibilité et la sécurité
            absolue de la Plateforme, compte tenu notamment des risques liés à
            Internet.
          </li>
          <br />
          <li>
            <strong>8.2. Garanties de l’Utilisateur </strong>
            <br />
            L’Utilisateur déclare et garantit à RISOFT (i) disposer du pouvoir
            et de la capacité pour conclure les présentes CGU, (ii) disposer de
            l’ensemble des droits nécessaires pour l’utilisation des Données,
            (iii) qu’il respecte la réglementation en matière de protection des
            données personnelles vis-à-vis des Utilisateurs et des personnes
            vivantes dont il saisit les Données personnelles, (iv) utiliser la
            Plateforme à ses seuls et entiers risques et périls. En cas de
            manquement aux garanties de l’Utilisateur, les indemnisations et
            frais de toute nature, dépensés par RISOFT pour assurer sa défense,
            y compris les frais de conseil, ainsi que tous les dommages et
            intérêts éventuellement prononcés contre elle, seront pris en charge
            par l’Utilisateur.
            <br />
            L’Utilisateur est en outre seul responsable des moyens informatiques
            utilisés pour accéder au site et de leur protection, notamment
            contre toute attaque informatique ou virus.
          </li>
        </ul>
      </div>
      <div class="page-subtitle">9. Responsabilité</div>
      <div class="page-paragraphe">
        Dans la mesure où les Services donnent accès à des informations
        publiques ou à des informations fournies par les Utilisateurs eux-mêmes,
        RISOFT ne peut garantir l’authenticité et/ou la fiabilité des Données.
        L’Utilisateur doit prendre en compte le score de fiabilité associé au
        résultat de sa requête. RISOFT ne saurait davantage être tenue
        responsable d’une utilisation de la Plateforme et/ou des Services non
        conforme aux conditions prévues dans les CGU. RISOFT ne saurait être
        tenue responsable d’une défaillance, indisponibilité, modification ou
        erreur survenue lors de l’utilisation de la Plateforme. RISOFT ne pourra
        être tenue responsable des dommages, directs ou indirects, survenus lors
        de l’utilisation de la Plateforme, à l’exception de ceux qui auraient
        pour cause un manquement à ses obligations.
        <br />
        En tout état de cause, RISOFT n’est responsable que des seuls dommages
        prévisibles et directs qui lui sont exclusivement imputables, à
        l'exclusion des dommages indirects (sont considérés comme des dommages
        indirects tout dommage indirect, accidentel ou collatéral éventuel que
        le Client subirait le cas échéant). En toute hypothèse, la
        responsabilité de RISOFT vis-à-vis de l’Utilisateur, au titre des CGU ne
        saurait excéder le montant des sommes versées par l’Utilisateur au cours
        des douze (12) mois précédant la survenance du fait générateur du
        dommage. De manière générale, à défaut pour l’Utilisateur d’avoir mis en
        jeu la responsabilité de RISOFT au titre des CGU dans un délai de deux
        (2) ans à compter de la manifestation du dommage, l’Utilisateur sera
        réputé avoir renoncé à se prévaloir de l’éventuel manquement au regard
        des CGU. Cette limitation de responsabilité ne s’applique pas en cas de
        survenance d’un dommage pour lequel la loi interdit toute limitation de
        la responsabilité conformément aux lois applicables au moment de la
        survenance dudit dommage. Les Parties entendent que les limitations de
        responsabilité ci-dessus ne neutralisent pas l’obligation essentielle de
        chaque Partie
      </div>
      <div class="page-subtitle">10. Dispositions diverses</div>
      <div class="page-paragraphe">
        <ul>
          <br />
          <li>
            <strong>10.1. Droit applicable – Langue - Litiges</strong>
            <br />
            LES PRESENTES CGU ET LES OPERATIONS QUI EN DECOULENT SONT REGIES PAR
            LE DROIT FRANÇAIS, sauf lois de police plus favorable applicable à
            l’Utilisateur dans son pays de résidence.
            <br />
            Les présentes CGU sont rédigées en langue française. Dans le cas où
            elles seraient traduites en une ou plusieurs langues étrangères,
            seul le texte français ferait foi en cas de litige.
            <br />
            Tout litige lié aux opérations d’achat et de vente conclues en
            application des présentes Conditions Générales d’Utilisation,
            concernant tant leur validité que leur interprétation, leur
            exécution, leur résiliation, leurs conséquences ou leurs suites et
            qui n’aurait pu être résolu entre RISOFT et l’Utilisateur sera
            soumis aux tribunaux compétents dans les conditions de droit commun.
            <br />
            L’Utilisateur peut recourir à tout mode alternatif de règlement des
            différends en cas de contestation. En particulier, l’Utilisateur
            ayant la qualité de consommateur, peut recourir à une médiation
            conventionnelle, notamment auprès de la Commission de la médiation
            de la consommation ou auprès des instances de médiation sectorielles
            existantes, et dont les références figurent sur son site Internet.
            Conformément à l’article L. 612-1 du code de la consommation, le
            Consommateur peut recourir gratuitement à un service de médiation.
          </li>
          <br />
          <li>
            <strong>10.2. Force majeure </strong>
            <br />
            Aucune des Parties ne pourra être tenue pour responsable de la
            non-exécution ou des retards dans l’exécution d’une quelconque de
            leurs obligations, qui découlerait d’un cas de force majeure au sens
            de l’article 1218 du code civil. La Partie constatant l’évènement
            doit informer l’autre Partie de son impossibilité d’exécuter ses
            obligations, et s’en justifier. Le cas de force majeure suspend les
            obligations nées des présentes CGU pendant toute la durée de son
            existence si elle est temporaire. Au-delà d’une durée de trente
            jours, le cas de force majeure ouvrirait droit à la résiliation de
            plein droit par l’une ou l’autre des parties quinze jours après
            l’envoi d’une Lettre recommandée avec accusé de réception notifiant
            cette résiliation. A la disparition de la cause de la suspension de
            leurs obligations, les Parties reprennent le plus rapidement
            possible l’exécution de leurs obligations contractuelles.
          </li>
          <br />
          <li>
            <strong
              >10.3. Modification de la Plateforme et des présentes CGU</strong
            >
            <br />
            RISOFT se réserve le droit d’apporter aux présentes Conditions
            Générales d’Utilisation toutes les modifications qu’elle jugera
            utiles. A chaque modification, les nouvelles Conditions Générales
            d’Utilisation sont portées à la connaissance des Utilisateurs de la
            Plateforme avant leur entrée en vigueur. En cas de modification
            substantielle des Conditions Générales d’Utilisation, RISOFT en
            informera préalablement et dans un délai raisonnable les
            Utilisateurs pour leur permettre d’examiner lesdites modifications.
            Les Utilisateurs qui n’acceptent pas les nouvelles conditions
            devront cesser d’utiliser la Plateforme. L'utilisation renouvelée de
            la Plateforme au fur et à mesure de la modification de ces
            Conditions Générales d’Utilisation constitue l’acceptation, par
            chaque Utilisateur, des Conditions Générales d’Utilisation en
            vigueur.
          </li>
          <br />
          <li>
            <strong>10.4. Non-renonciation et invalidité</strong>
            <br />
            Le fait pour l’une des Parties de ne pas se prévaloir du manquement
            de l’autre partie, à l’une quelconque des obligations visées au sein
            des présentes Conditions Générales d’Utilisation, ne saurait être
            interprété pour l’avenir comme une renonciation à l’obligation en
            cause.
            <br />
            L’annulation de l’une des stipulations des présentes Conditions
            Générales d’Utilisation n’est susceptible d’entraîner l’annulation
            de celles-ci dans leur ensemble, que dans la mesure où la
            stipulation litigieuse est considérée, dans l’esprit des Parties,
            comme substantielle et déterminante, et que son annulation remet en
            cause l'équilibre général des présentes. En cas d'annulation de
            l’une des stipulations des Conditions Générales d’Utilisation,
            considérée comme non substantielle, les parties s’efforceront de
            négocier une clause économiquement équivalente.
          </li>
          <br />
          <li>
            <strong>10.5. Validité des preuves électroniques</strong>
            <br />
            L’Utilisateur reconnaît la validité et la force probante des
            échanges et enregistrements électroniques conservés par RISOFT et
            admet que ces éléments reçoivent la même force probante qu'un écrit
            signé de manière manuscrite en vertu des articles 1174, 1176 et 1366
            du Code civil.
          </li>
          <br />
          <li>
            <strong>10.6. Liens hypertextes</strong>
            <br />
            La Plateforme propose des liens hypertextes donnant accès à d’autres
            sites web édités et gérés par des tiers, notamment à partir des
            résultats des recherches dans la base de données RISOFT. RISOFT ne
            peut être tenue responsable directement ou indirectement dans le cas
            où lesdits sites tiers ne respecteraient pas les dispositions
            légales et réglementaires en vigueur ou à venir, de même que RISOFT
            ne saurait être responsable des conséquences de ce non-respect.
            D’une manière générale, RISOFT ne saurait assumer la responsabilité
            des activités de ces tiers et engage les Utilisateurs à prendre
            connaissance des conditions d’utilisation des sites concernés.
          </li>
        </ul>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

export default {
  name: "Terms",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
</style>