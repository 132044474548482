<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2" style="color: #2b3e4a">
      {{ title }}
    </v-card-title>

    <v-card-text class="pt-5">
      <p v-for="text in textArray" :key="text">{{ text }}</p>
    </v-card-text>

    <v-img
      v-if="image"
      class="homepage-image margin-x-auto"
      width="85%"
      height="100%"
      :src="image"
    ></v-img>

    <v-divider></v-divider>

    <div class="actions-wrapper">
      <div class="cancel-btn border-btn" @click="$emit('close-dialog')">
        Annuler
      </div>
      <div class="accent-btn colored-btn" @click="$emit('start-notification')">
        {{ validationText }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "NotificationModal",
  props: ["textArray", "title", "image", "validationText"],
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
</style>