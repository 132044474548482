<template>
  <div class="static-page mt-1">
    <Navbar />
    <div class="page-content">
      <div class="page-title">Mot de passe oublié</div>
      <form class="center" @submit="submitPasswordReset">
        <p>
          Veuillez saisir votre adresse mail. Nous vous enverrons les
          instructions pour réinitialiser votre mot de passe.
        </p>
        <input
          v-model="email"
          id="reset-email"
          placeholder="email"
          required
          type="email"
          name="email"
          class="input-email mx-auto"
        />
                  

        <VRow class="mt-8 mx-auto full-width center">
          <v-btn
              dense
              text
              color="white"
              class="blue-background mr-5"
              width="150"
              type="submit"
            >
            <span class="basic-button-text">Réinitialiser</span>
          </v-btn>
          <v-btn
              outlined
              color="rgba(0, 0, 0, 0.6)"
              width="150"
              @click="backHomepage"
            >
            <span class="basic-button-text">Retour</span>
          </v-btn>
        </VRow>
      </form>
      <v-alert 
        v-if="message"
        :type="messageType"
        class="mt-8 alert-div center ma-auto"
      >
      {{ message }}
      </v-alert>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
import { API } from "@/services/Api";

export default {
  name: "PasswordForgotten",
  components: { Navbar, Footer },
  data() {
    return {
      email: "",
      message: "",
      messageType: ""
    };
  },
  methods: {
    backHomepage() {
      this.$router.push({
        name: "Homepage"
      })
    },
    async submitPasswordReset(e) {
      e.preventDefault();
      this.message = ""
      try {
        await API.post("user/forgot_password", {client_email: this.email});
        this.message = "Un email vous a été envoyé à l'adresse renseignée. Pensez à regarder dans le dossier 'spam' si vous ne le trouvez pas."
        this.messageType = "success"
        this.email = "";
      } catch(error) {
        console.log("error !!!!!!!!!!", error, error.status, error.status_code)
        if (error.status == 404) {
          console.log("HHHHH")
          this.message = "Nous n'avons pas trouvé de correspondance avec l'adresse email."
        } else {
          this.message = "Une erreur est survenue, veuillez réessayer."
        }
        this.email = "";
        this.messageType = "error"
      }
    },
  },
};
</script>

<style scoped>
.input-email {
  width: 350px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 5px;
}

.alert-div {
  width: 500px;
}
</style>