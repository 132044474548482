<template>
  <div>
    <Sidebar />
    <div class="app-container">
      <div class="fileSearch-headers">
        <div class="heading pt-7">Admin</div>
        <div class="header-description-text">
          Vous pouvez modifier ici les utilisateurs et les paramètres de
          l'application.
        </div>
      </div>
      <div class="pa-5">
        <div class="filesearch-subtitle">Gestion des utilisateurs</div>
        <div
          v-for="message in messages"
          :key="message"
          class="success-color message"
        >
          {{ message }}
        </div>
        <div v-for="error in errors" :key="error" class="error-color message">
          {{ error }}
        </div>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="allUsers" :search="search">
            <template v-slot:[`item.client_last_request_date`]="{ item }">
              <span>{{ formatDate(item.client_last_request_date) }}</span>
            </template>
            <template v-slot:top>
              <v-dialog v-model="dialogEdit" max-width="800px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Editer l'utilisateur</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.client_lastname"
                            label="Nom"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.client_firstname"
                            label="Prénom"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.client_email"
                            label="Email"
                            type="email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.client_bulk_price"
                            label="Prix unitaire bulk search"
                            type="number"
                            step="0.01"
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="otherInput ? 4 : 6" class="center-select">
                          <select
                            name="activity-inscription"
                            @change="changeSelect"
                            class="input-full-width"
                          >
                            <option
                              v-for="activity in activityOptions"
                              :selected="
                                editedItem.client_activity_domain == activity
                              "
                              :key="activity"
                              :value="activity"
                            >
                              {{ activity }}
                            </option>
                          </select>
                        </v-col>
                        <v-col v-if="otherInput" cols="4" class="center-select">
                          <input
                            type="text"
                            placeholder="PRÉCISEZ LE NOM DU DOMAINE"
                            class="input-full-width"
                            @change="setOtherActivity"
                            v-model="otherInputValue"
                          />
                        </v-col>
                        <v-col :cols="otherInput ? 4 : 6">
                          <v-text-field
                            v-model="editedItem.client_postal_code"
                            label="Code Postal"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <div class="actions-wrapper">
                    <v-btn color="primary" text @click="close('user')">
                      Annuler
                    </v-btn>
                    <v-btn color="accent" text @click="save">
                      Sauvegarder
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Êtes-vous sûr de vouloir supprimer cet utilisateur
                    ?</v-card-title
                  >
                  <div class="actions-wrapper">
                    <v-btn color="primary" text @click="closeDelete('user')"
                      >Annuler</v-btn
                    >
                    <v-btn color="accent" text @click="deleteItemConfirm"
                      >Oui, supprimer</v-btn
                    >
                  </div>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item, 'user')">
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="!item.client_is_admin"
                small
                @click="deleteItem(item, 'user')"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <div class="filesearch-subtitle">Paramètres de l'application</div>
        <div class="admin-parameters-grid">
          <div
            v-for="param in adminParameters"
            :key="param.text"
            class="grid-div"
          >
            <div>{{ param.text }}</div>
            <input
              required
              v-model="param.value"
              type="number"
              step="0.01"
              min="0"
              class="input-admin"
            />
          </div>
        </div>
        <div class="param-subtitle">Pricing</div>
        <div class="admin-parameters-grid">
          <div
            v-for="pricing in adminPricing"
            :key="pricing.text"
            class="grid-div"
          >
            <div>{{ pricing.text }}</div>
            <input
              :disabled="pricing.disabled"
              v-model="pricing.value"
              type="number"
              step="0.01"
              min="0"
              class="input-admin"
            />
          </div>
        </div>
        <div class="width-700 margin-auto">
          <div
            v-for="messageP in messagesParam"
            :key="messageP"
            class="success-color message"
          >
            {{ messageP }}
          </div>
          <div
            v-for="errorP in errorsParam"
            :key="errorP"
            class="error-color message"
          >
            {{ errorP }}
          </div>
        </div>
        <div
          @click="passwordModal = true"
          class="blue-btn colored-btn width-200 margin-auto mb-13"
        >
          Valider ces paramètres
        </div>
        <!-- Modal pour le password -->
        <v-dialog v-model="passwordModal" max-width="550px">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2" style="color: #2b3e4a">
              Mot de passe de l'utilisateur admin
            </v-card-title>
            <v-row class="ma-3">
              <v-col cols="12">
                <input
                  id="admin-password-confirmation"
                  v-model="passwordConfirmation"
                  placeholder="MOT DE PASSE"
                  required
                  type="password"
                  class="input-full-width"
                />
              </v-col>
            </v-row>
            <div class="actions-wrapper">
              <div class="cancel-btn border-btn" @click="passwordModal = false">
                Annuler
              </div>
              <div class="accent-btn colored-btn" @click="validateParameters">
                Confirmer
              </div>
            </div>
          </v-card>
        </v-dialog>

        <div class="filesearch-subtitle">
          Gestion des recherches automatisées
        </div>
        <div
          v-for="messageT in messagesTasks"
          :key="messageT"
          class="success-color message"
        >
          {{ messageT }}
        </div>
        <div
          v-for="errorT in errorsTasks"
          :key="errorT"
          class="error-color message"
        >
          {{ errorT }}
        </div>
        <v-data-table
          :headers="headersTasks"
          :items="allTasks"
          :customSort="customSort"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteTasks" max-width="550px">
              <v-card>
                <div class="pa-5 center semi-bold text-color">
                  Êtes-vous sûr de vouloir supprimer cette tâche ?
                </div>
                <div class="actions-wrapper">
                  <v-btn color="primary" text @click="closeDelete('task')"
                    >Annuler</v-btn
                  >
                  <v-btn color="accent" text @click="deleteTasksConfirm"
                    >Oui, supprimer</v-btn
                  >
                </div>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditTask" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Editer la tâche</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-switch
                      v-model="editedItem.enable"
                      :label="`Tâche active`"
                    ></v-switch>
                  </v-container>
                </v-card-text>
                <div class="actions-wrapper">
                  <v-btn color="primary" text @click="close('task')">
                    Annuler
                  </v-btn>
                  <v-btn color="accent" text @click="saveTask">
                    Sauvegarder
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <span v-if="item.type == 'single'">Recherche unitaire</span>
            <span v-else>Recherche groupée</span>
          </template>
          <template v-slot:[`item.previous_run_date`]="{ item }">
            <span>{{ formatDate(item.previous_run_date) }}</span>
          </template>
          <template v-slot:[`item.next_run_date`]="{ item }">
            <span>{{ formatDate(item.next_run_date) }}</span>
          </template>
          <template v-slot:[`item.enable`]="{ item }">
            <v-simple-checkbox
              v-model="item.enable"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="center">
              <v-icon small class="mr-2" @click="editItem(item, 'task')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item, 'task')">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../../components/layout/Sidebar";
import { API } from "../../services/Api";
import { TokenService } from "../../services/Storage";
import { utils } from "../../services/Utils";

export default {
  name: "Admin",
  components: {
    Sidebar,
  },
  data: () => ({
    errors: [],
    messages: [],
    errorsParam: [],
    messagesParam: [],
    errorsTasks: [],
    messagesTasks: [],
    allUsers: [],
    allTasks: [],
    search: "",
    dialogDelete: false,
    dialogDeleteTasks: false,
    dialogEdit: false,
    dialogEditTask: false,
    otherInput: false,
    otherInputValue: "",
    passwordModal: false,
    passwordConfirmation: "",
    activityOptions: ["Domaine médical", "Autre"],
    headers: [
      { text: "Email", value: "client_email" },
      { text: "Nom", value: "client_lastname" },
      { text: "Prénom", value: "client_firstname" },
      { text: "Admin", value: "client_is_admin" },
      { text: "Code postal", value: "client_postal_code" },
      { text: `Domaine d'activité`, value: "client_activity_domain" },
      { text: `Logiciel médical`, value: "client_medical_software" },
      { text: "Date de dernière requête", value: "client_last_request_date" },
      { text: "Prix unitaire bulk search", value: "client_bulk_price" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersTasks: [
      { text: "Id", value: "id" },
      { text: "Client ID", value: "client_id" },
      { text: "Type", value: "task_type" },
      { text: "Date dernière recherche", value: "previous_run_date" },
      { text: "Date prochaine recherche", value: "next_run_date" },
      // { text: "Payée", value: "toPay" },
      { text: "Actif", value: "enable", sortable: false },
      { text: "Modifier", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    adminParameters: [
      { text: "Sensitivité", key: "admin_sensitivity_threshold", value: null },
      { text: "Spécificité", key: "admin_specificity_threshold", value: null },
      // { text: "Prévalence", key: "admin_prevalence", value: null },
      {
        text: "Poids du score MatchID",
        key: "admin_weight_matchid_score",
        value: null,
      },
      {
        text: "Poids distance au décès",
        key: "admin_weight_distance_from_death",
        value: null,
      },
      {
        text: "Poids probabilité de décès",
        key: "admin_weight_proba_deces",
        value: null,
      },
      {
        text: "Distance habitation - décès",
        key: "admin_distance_threshold",
        value: null,
      },
      { text: "Valeur du seuil", key: "admin_load_threshold", value: null },
    ],
    adminPricing: [
      {
        text: "Prix unitaire bulk search",
        key: "admin_unit_pricing",
        value: null,
        disabled: false,
      },
      {
        text: "Prix alerte single search",
        key: "client_automated_single_price",
        value: null,
        disabled: true,
      }, // TODO load data
      {
        text: "Prix alerte bulk search",
        key: "client_automated_bulk_price",
        value: null,
        disabled: true,
      },
    ],
    user_email: null,
  }),
  methods: {
    async getAllUsers() {
      try {
        const response = await API.get("user/")
        if (response.status == 200) {
          this.allUsers = response.data.data.users;
          this.allUsers.forEach((user) => {
            user.client_bulk_price = user.client_bulk_price / 100;
          });
        } else if (response.status == 499) {
          try {
            await API.refreshToken()
            this.getAllUsers()
          } catch(e) {
            var messageData = {
                text:
                  "Une erreur est survenue lors de la connexion au serveur, erreur " +
                  e,
                type: "error",
              };
            this.$store.dispatch("displayMessage", messageData);
          }
        } else {
          this.errors
            .push(`Une erreur est survenue lors du chargement des utilisateurs... 
                      Réessayer de charger la page.`);
        }
      } catch(e) {
          this.errors
            .push(`Une erreur est survenue lors du chargement des utilisateurs... 
                    Réessayer de charger la page`);
          console.error(e)
      }
    },
    async getAllAutomatedTasks() {
      try {
        const response = await API.get("cron/")
        if (response.status == 200) {
          this.allTasks = Array.isArray(response.data.tasks)
            ? response.data.tasks
            : [response.data.tasks];
        } else if (response.status == 499) {
          try {
            await API.refreshToken()
            this.getAllAutomatedTasks()
          } catch(e) {
            var messageData = {
              text:
                "Une erreur est survenue lors de la connexion au serveur, erreur " +
                e,
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
            this.allTasks = [];
          }
        } else {
            this.allTasks = [];
            this.errorsTasks.push(
              `Une erreur est survenue lors du chargement des données.`
            );
        }
      } catch(e) {
          this.allTasks = [];
          this.errorsTasks.push(
            `Une erreur est survenue lors du chargement des données.`
          );
          console.error(e)
      }
    },
    changeSelect(e) {
      this.otherInput = e.target.value == "Autre";
      console.log("OHTERT", this.otherInput);
      if (!this.otherInput) {
        // If not other, set the value; if other, wait user write in input
        this.editedItem.client_activity_domain = e.target.value;
      }
    },
    setOtherActivity(e) {
      if (this.otherInput) {
        this.editedItem.client_activity_domain = "Autre : " + e.target.value;
        console.log("THIIIS", this.editedItem);
      }
    },
    formatDate(value) {
      return value ? new Date(value).toLocaleDateString() : "";
    },
    // TODO issue with date sorting when null values
    customSort: function (items, index, isDesc) {
      return utils.completeSorting(items, index, isDesc);
    },
    editItem(item, type) {
      this.editedIndex =
        type == "user"
          ? this.allUsers.indexOf(item)
          : this.allTasks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = type == "user";
      this.dialogEditTask = type == "task";
      type == "user" ? this.manageOtherInput() : "";
    },
    manageOtherInput() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.editedItem,
          "client_activity_domain"
        ) &&
        this.editedItem.client_activity_domain.includes("Autre")
      ) {
        this.otherInput = true;
        this.otherInputValue =
          this.editedItem.client_activity_domain.split(": ")[1];
      }
    },

    deleteItem(item, type) {
      this.editedIndex =
        type == "user"
          ? this.allUsers.indexOf(item)
          : this.allTasks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = type == "user";
      this.dialogDeleteTasks = type == "task";
    },

    deleteItemConfirm() {
      API.delete(
        `user/?email=${encodeURIComponent(this.editedItem.client_email)}`
      )
        .then((response) => {
          if (response.status == 200) {
            this.allUsers.splice(this.editedIndex, 1);
            this.messages.push(
              `L'utilisateur ${this.editedItem.client_email} a bien été supprimé.`
            );
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.deleteItemConfirm())
              .catch((e) => {
                var messageData = {
                  text:
                    "Une erreur est survenue lors de la connexion au serveur, erreur " +
                    e,
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            this.errors.push(
              `Une erreur est survenue lors de la suppression de l'utilisateur ${this.editedItem.client_email}`
            );
          }
        })
        .catch((e) => {
          this.errors.push(
            `Une erreur est survenue lors de la suppression de l'utilisateur ${this.editedItem.client_email}, erreur ${e}.`
          );
        });
      this.closeDelete("user");
    },
    deleteTasksConfirm() {
      var data = {
        task_id: this.editedItem.id,
      };
      API.delete(`cron/disable`, data)
        .then((response) => {
          if (response.status == 200) {
            this.allTasks.splice(this.editedIndex, 1);
            this.messagesTasks.push(
              `La tâche ${this.editedItem.id} a bien été supprimée.`
            );
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.deleteTasksConfirm())
              .catch((e) => {
                var messageData = {
                  text:
                    "Une erreur est survenue lors de la connexion au serveur, erreur " +
                    e,
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            this.errorsTasks.push(
              `Une erreur est survenue dans la suppression de la tâche.`
            );
          }
        })
        .catch((e) => {
          this.errorsTasks.push(
            `Une erreur est survenue dans la suppression de la tâche, erreur ${e}`
          );
        });
      this.closeDelete("task");
    },

    close(type) {
      this.dialogEdit = type == "user" ? false : this.dialogEdit;
      this.dialogEditTask = type == "task" ? false : this.dialogEditTask;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete(type) {
      this.dialogDelete = type == "user" ? false : this.dialogDelete;
      this.dialogDeleteTasks = type == "task" ? false : this.dialogDeleteTasks;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // When the user data is changed by admin user
      var editIndex = this.editedIndex;
      if (this.editedIndex > -1) {
        var data = {
          client_email: this.editedItem.client_email,
          client_firstname: this.editedItem.client_firstname,
          client_lastname: this.editedItem.client_lastname,
          client_postal_code: this.editedItem.client_postal_code,
          client_activity_domain: this.editedItem.client_activity_domain,
          client_bulk_price: this.editedItem.client_bulk_price * 100,
        };

        if (
          data["client_email"] !=
          this.allUsers[this.editedIndex]["client_email"]
        ) {
          // If the email is changed
          data["client_new_email"] = data["client_email"];
          data["client_email"] =
            this.allUsers[this.editedIndex]["client_email"];
        }
        data = Object.fromEntries(
          Object.entries(data).filter(([, v]) => v != null)
        );
        API.put("user/", data)
          .then((response) => {
            if (response.status == 200) {
              this.setUserNewValues(data, editIndex);
              this.messages.push(`L'utilisateur a bien été modifié.`);
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() => this.save())
                .catch((e) => {
                  var messageData = {
                    text:
                      "Une erreur est survenue lors de la connexion au serveur, erreur " +
                      e,
                    type: "error",
                  };
                  this.$store.dispatch("displayMessage", messageData);
                });
            } else {
              this.errors.push(
                `Une erreur est survenue lors de la modificiation de l'utilisateur ${data.client_new_email}.`
              );
            }
          })
          .catch((e) => {
            this.errors
              .push(`Une erreur est survenue avec le serveur lors de la modificiation 
                        de l'utilisateur ${data.client_new_email}. Erreur ${e}`);
          });
      } else {
        this.allUsers.push(this.editedItem);
      }
      this.close("user");
    },
    setUserNewValues(data, editIndex) {
      this.allUsers[editIndex].client_email =
        Object.prototype.hasOwnProperty.call(data, "client_new_email")
          ? data.client_new_email
          : data.client_email;
      this.allUsers[editIndex].client_firstname = data.client_firstname;
      this.allUsers[editIndex].client_lastname = data.client_lastname;
      this.allUsers[editIndex].client_postal_code = data.client_postal_code;
      this.allUsers[editIndex].client_bulk_price = data.client_bulk_price / 100;
      this.allUsers[editIndex].client_activity_domain =
        Object.prototype.hasOwnProperty.call(data, "client_activity_domain")
          ? data.client_activity_domain
          : "";
    },
    async setAdminParameters() {
      try {
        this.user_email = TokenService.getClientEmail();
        const response = await API.get(`user/search?email=${this.user_email}`)
        let responseData = response.data.data
        if (response.status == 200) {
          this.adminParameters.forEach((param) => {
            param.value = responseData[param.key];
          });
          this.adminPricing.forEach((param) => {
            param.value = responseData[param.key] / 100;
            if (
              param.key == "admin_unit_pricing" &&
              responseData[param.key] == null
            ) {
              param.value = responseData["client_bulk_price"] / 100;
            }
          });
        } else if (response.status == 499) {
            await API.refreshToken()
            this.setAdminParameters()
        } else {
          var messageData = {
            text: "Une erreur est survenue lors de la requête, veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        }
      } catch(e) {
        var message = {
          text: "Une erreur est survenue avec le serveur, veuillez réessayer.",
          type: "error",
        };
        this.$store.dispatch("displayMessage", message);
        console.error(e);
      }
    },
    saveTask() {
      API.put("cron/update")
        .then((response) => {
          if (response.status == 200) {
            this.messagesTasks.push(
              `La tâche a été mise à jour - aucune action en backend pour l'instant.`
            );
          } else if (response.staus == 499) {
            API.refreshToken()
              .then(() => this.saveTask())
              .catch((e) => {
                var messageData = {
                  text:
                    "Une erreur est survenue lors de la connexion au serveur, erreur " +
                    e,
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            this.errorsTasks.push(
              `Une erreur est survenue dans la mise à jour de la tâche.`
            );
          }
        })
        .catch((e) => {
          this.errorsTasks.push(
            `Une erreur est survenue dans la mise à jour de la tâche, erreur ${e}.`
          );
        });
      this.close("task");
    },
    validateParameters() {
      var data = {
        admin_email: this.user_email,
        admin_password: this.passwordConfirmation,
      };
      this.passwordConfirmation = "";
      this.passwordModal = false;
      this.adminParameters.forEach(
        (param) => (data[param.key] = parseFloat(param.value))
      );
      data["admin_unit_pricing"] =
        this.adminPricing.filter((val) => val.key == "admin_unit_pricing")[0]
          .value * 100;
      console.log("DATA param", data);
      API.post("user/admin", data)
        .then((response) => {
          console.log("RESPONSE POST PARAM", response);
          if (response.status == 499) {
            API.refreshToken()
              .then(() => this.validateParameters())
              .catch((e) => {
                var messageData = {
                  text:
                    "Une erreur est survenue lors de la connexion au serveur, erreur " +
                    e,
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else if (response.status == 200) {
            if (response.data == 404) {
              var messageData = {
                text: "Une erreur est survenue",
                type: "error",
              };
              this.$store.dispatch("displayMessage", messageData);
            } else {
              this.messagesParam.push("Vos paramètres ont bien été changés !");
            }
          } else {
            this.errorsParam.push(`Une erreur est survenue.`);
          }
        })
        .catch((e) => {
          this.errorsParam.push(`Une erreur est survenue, erreur ${e}.`);
          this.setAdminParameters();
        });
    },
  },
  async created() {
    await Promise.all([
      this.getAllUsers(),
      this.setAdminParameters(),
      this.getAllAutomatedTasks(),
    ]);
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete("user");
    },
    messages: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.messages = [];
          }.bind(this),
          7000
        );
      }
    },
    errors: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.errors = [];
          }.bind(this),
          7000
        );
      }
    },
    messagesParam: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.messagesParam = [];
          }.bind(this),
          7000
        );
      }
    },
    errorsParam: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.errorsParam = [];
          }.bind(this),
          7000
        );
      }
    },
    messagesTasks: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.messagesTasks = [];
          }.bind(this),
          7000
        );
      }
    },
    errorsTasks: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.errorsTasks = [];
          }.bind(this),
          7000
        );
      }
    },
  },
};
</script>

<style scoped>
.center-select {
  align-self: center;
}

.admin-parameters-grid {
  display: grid;
  grid-template-columns: 250px 250px 250px;
  margin: 30px auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
}

.grid-div {
  background-color: #eff3f4;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 50%);
}

.param-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  text-decoration: underline;
  font-size: 18px;
}
</style>