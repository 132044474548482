<template>
  <div class="file-search-wrapper-div">
    <div class="file-search-title">
      Étape 2. <span class="ml-3">Configuration</span>
    </div>
    <div class="description">
      Les informations minimum nécessaires sont marquées d’une astérix.
      <br /><br />
      Toutes les colonnes ci-dessous ne sont pas indispensables mais plus il y a
      de colonnes appareillées et donc d'informations renseignées, plus le
      système sera efficace et fournira des résultats pertinents. Veuillez-vous
      référer aux boutons "aide" de chaque colonne pour avoir des informations
      supplémentaires ou à la page FAQ.
      <br /><br />
      Attention: si un champ n'est pas de qualité, il est susceptible d'impacter
      la qualité du traitement. N'hésitez pas à tester plusieurs configurations.
    </div>
    <div
      v-if="(separator && dataArray && file) || (jobId && fileName)"
      class="success-color file-imported"
    >
      <v-icon class="icon-success">{{ iconChecked }}</v-icon>
      Nom du fichier : "<strong>{{
        file ? file.name : fileName ? fileName + ".csv" : ""
      }}</strong
      >" {{ size ? "- " + size : "" }}
    </div>
    <div v-if="storedValues" class="info-color file-imported">
      <v-icon color="button"> {{ infoIcon }} </v-icon>
      Votre fichier a déjà été importé et configuré, vous ne pouvez plus
      modifier l'appariement des colonnes.
    </div>
    <div class="card-column-mapping">
      <div class="title-card-mapping">
        <div class="mr-3">CHOISISSEZ LES CHAMPS À APPARIER</div>
        <Tooltip :icon="iconTooltip" color="button" :textArray="textArray" />
      </div>
      <div class="columns-card-grid">
        <div v-for="column in columns" :key="column.name" class="column-card">
          <div class="card-headers">
            <div class="card-title">
              {{ column.name }}
            </div>
            <div v-if="column.helpText[0].length > 0">
              <Tooltip
                :icon="iconTooltip"
                color="button"
                :textArray="column.helpText"
              />
            </div>
          </div>
          <div v-if="column.type === 'input'">
            <input
              id="last-news"
              name="last-news"
              v-model="column.matching"
              :disabled="storedValues ? true : false"
              type="date"
              class="select-column font-size-14"
            />
          </div>
          <div v-else class="wrapper-select-col">
            <select
              name="col"
              class="select-column"
              v-model="column.matching"
              :disabled="storedValues ? true : false"
              @click="updateDataColumns"
            >
              <!-- <option value="">Sélectionner une colonne</option> -->
              <option
                v-for="dataColumn in Object.keys(dataColMapping)"
                :key="`${column.name}-${dataColumn}`"
                :value="dataColArray.indexOf(dataColumn)"
                :disabled="dataColMapping[dataColumn] && dataColumn != ''"
              >
                {{ dataColumn }}
              </option>
            </select>
            <v-icon class="chevron-icon">
              {{ iconChevron }}
            </v-icon>
          </div>
        </div>
      </div>
    </div>

    <div v-if="error" class="mt-3 mb-3 div-announce error-color">
      <v-icon class="mx-2 error-loading-color-icon">{{ iconAlert }}</v-icon>
      Une erreur survenue. Veuillez réessayer dans quelques minutes. Si le
      problème persiste, veuillez nous contacter.
    </div>

    <div>
      <div class="previous-step pointer-on-hover" @click="goPreviousStep">
        Étape précédente
      </div>

      <div @click="validMatchingAndGoNext" class="next-step pointer-on-hover">
        Étape suivante
      </div>
    </div>
    <v-dialog v-model="waitingModal" persistent width="400px">
      <WaitingModal />
    </v-dialog>
  </div>
</template>

<script>
import Tooltip from "../../../components/layout/Tooltip";
import WaitingModal from "../../../components/layout/WaitingModal";
import { mapFields } from "vuex-map-fields";
import {
  mdiCheckCircle,
  mdiHelpCircle,
  mdiUnfoldMoreHorizontal,
  mdiAlertCircle,
  mdiInformation,
} from "@mdi/js";
import { API } from "../../../services/Api";
import { TokenService } from "../../../services/Storage";
import { utils } from "../../../services/Utils";

export default {
  name: "ConfigureFile",
  components: {
    Tooltip,
    WaitingModal,
  },
  data: () => ({
    iconChecked: mdiCheckCircle,
    iconTooltip: mdiHelpCircle,
    iconChevron: mdiUnfoldMoreHorizontal,
    iconAlert: mdiAlertCircle,
    infoIcon: mdiInformation,
    validationOngoing: false,
    textArray: [
      `Si une colonne de votre fichier correspond à l'une affichée des colonnes 
            affichées ci-dessous, associez-les en cliquant sur le menu déroulant de la 
            colonne et en sélectionnant le nom de la colonne de votre fichier.`,
      `Si une colonne de votre fichier ne correspond à aucune des colonnes proposées, 
            c'est que son contenu n'est pas déterminant pour la recherche.`,
      `Pour les colonnes proposées qui ne sont pas présentes dans votre fichier, 
            laissez les valeurs d'appariement vides.`,
    ],
    waitingModal: false,
    error: false,
  }),
  mounted() {
    this.manageDatePicker();
  },
  computed: {
    ...mapFields([
      "fileSearch.step",
      "fileSearch.file",
      "fileSearch.fileName",
      "fileSearch.data",
      "fileSearch.dataArray",
      "fileSearch.separator",
      "fileSearch.columns",
      "fileSearch.dataColMapping",
      "fileSearch.fileSent",
      "fileSearch.jobId",
      "fileSearch.storedValues",
      "fileSearch.needLoading",
    ]),
    size: function () {
      if (this.file) {
        var size = this.file.size;
        size = utils.calculateSize(size);
        return size;
      } else {
        return "";
      }
    },
    dataColArray: function () {
      return Object.keys(this.dataColMapping);
    },
  },
  methods: {
    manageDatePicker() {
      // Used for safari browser
      var dateInputs = Array.from(document.querySelectorAll('[type="date"]'));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        dateInputs.forEach((input) => {
          input.placeholder = "jj/mm/aaaa";
        });
      }
    },
    goPreviousStep() {
      this.$store.commit("decreaseStep", 1);
      window.scrollTo(0, 0);
    },
    validMatchingAndGoNext() {
      // Create new empty columns and then call go next
      if (!this.validationOngoing) {
        this.waitingModal = true;
        document.body.style.cursor = "wait";
        this.validationOngoing = true;
        if (!this.storedValues) {
          // Si pas de valeurs de jobId déjà stocké, on fait le process du mapping des cols
          if (!this.needLoading && this.jobId) {
            // If the file has already been loaded & has a jobId
            // So the user came back but didn t modifiy the columns mapping -> skip
            console.log("SKIP");
            this.waitingModal = false;
            document.body.style.cursor = "default";
            this.validationOngoing = false;
            this.$store.commit("increaseStep", 3);
          } else {
            try {
              this.manageColumns();
            } catch {
              this.waitingModal = false;
              this.error = true;
              document.body.style.cursor = "default";
              this.validationOngoing = false;
            }
          }
        } else {
          // Si le fichier était sur liste d'attente et déjà traité
          // On augmente juste le step
          this.waitingModal = false;
          document.body.style.cursor = "default";
          this.validationOngoing = false;
          this.$store.commit("increaseStep", 3);
        }
      }
    },
    updateDataColumns() {
      var selects = Array.from(
        document.getElementsByClassName("select-column")
      );
      var selectedCol = [];
      selects.forEach((selectDiv) => {
        selectDiv.value ? selectedCol.push(parseInt(selectDiv.value)) : "";
      });
      Object.entries(this.dataColMapping).forEach((item, i) => {
        selectedCol.includes(i)
          ? this.$store.commit("setValueDataColMapping", [item[0], true])
          : this.$store.commit("setValueDataColMapping", [item[0], false]);
      });
      // Columns mapping has change so need to load
      this.$store.commit("setFileSearchValue", ["needLoading", true]);
    },
    manageColumns() {
      // For all the defined columns of the app (in columns object)
      // The ones of the file we want are associated to the columns with their
      // order in the file headers (matching value)
      var copyDataArray = JSON.parse(JSON.stringify(this.dataArray));
      var headers = copyDataArray[0].split(this.separator.value);
      var noMatchingValue = headers.length; // The integer of the number of column is for blank matching
      copyDataArray = copyDataArray.slice(1);
      this.columns.forEach((column) => {
        if (column.type === "input") {
          // If col type is input ie 'expiration date' -> add the value to each row
          // because in every case, the column is created by the app
          headers.push(column.formalName);
          var dateNow = new Date();
          copyDataArray = column.matching
            ? this.addOneColumn(
                copyDataArray,
                this.separator.value,
                this.formatExpirationDate(column.matching)
              )
            : this.addOneColumn(
                copyDataArray,
                this.separator.value,
                `${("0" + dateNow.getDate()).slice(-2)}/${(
                  "0" + dateNow.getMonth()
                ).slice(-2)}/${dateNow.getFullYear()}` // tips to get 2 digits
              );
        } else {
          // Change the name of the header if mapped or add the name to headers array
          // the 21 is the blank field used to reset so all except this one
          if (
            typeof column.matching == "number" &&
            column.matching != noMatchingValue
          ) {
            // Replace headers original column name by formal name mapped column
            headers[column.matching] = column.formalName;
          } else {
            // Create new column for this formal name which hasn't been mapped
            copyDataArray = this.addOneColumn(
              copyDataArray,
              this.separator.value,
              ""
            );
            headers.push(column.formalName);
          }
        }
      });
      var [newheaders, dataArrayCleaned] = this.cleanData(
        copyDataArray,
        headers
      );
      newheaders = [newheaders.join(this.separator.value)];
      var allData = newheaders.concat(dataArrayCleaned);
      allData = this.replaceSeparator(allData, this.separator.value);
      var copyData = allData.join("\n"); // TESTED with \n only
      var fileTreated = new File([copyData], "fileTreated.csv", {
        type: "text/csv",
      });
      this.$store.commit("setFileSearchValue", ["fileSent", fileTreated]);
      this.sendFile(fileTreated);

      // const anchor = document.createElement('a');
      // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyData);
      // anchor.target = '_blank';
      // anchor.download = 'file_ready.csv';
      // anchor.click();
    },
    formatExpirationDate(date) {
      return typeof date == "string" && date.includes("-")
        ? date.split("-").reverse().join("/")
        : date;
    },
    addOneColumn(array, separator, value) {
      array.forEach((row, i) => {
        array[i] = row + separator + value;
      });
      return array;
    },
    replaceSeparator(allData, separator) {
      if (separator != ",") {
        var newData = [];
        allData.forEach((rowStr) => {
          var rowSplit = rowStr.split(separator);
          newData.push(rowSplit.join(","));
        });
        return newData;
      } else {
        return allData;
      }
    },
    cleanData(dataArray, headers) {
      // Escape all strings values by " " and delete useless columns
      var copy = [];
      var [columnIndexesToDelete, newHeaders] =
        this.getColumnsToDelete(headers);
      dataArray.forEach((row) => {
        // First split of the row with the minimal string between quotes
        // typically, strings between quotes may contain the separator (or anyway, it shouldn't be split!!)

        var regexText = `(${this.separator.value}?[“"].*?[”"]${this.separator.value}?)`;
        const regex = new RegExp(regexText);
        var rowFirstSplit = row.split(regex);

        // Remove the separator at the beginning or end if in a escaped text (with "")
        rowFirstSplit = this.cleanRowSplit(rowFirstSplit);
        var quotes = [`"`, `“`, `”`];
        var rowSplit = [];
        rowFirstSplit.map((x) => {
          if (x.length > 0 && !quotes.includes(x[0])) {
            // If the first element if not a quote, split with the separator
            rowSplit = rowSplit.concat(x.split(`${this.separator.value}`));
          } else {
            rowSplit = rowSplit.concat(x);
          }
        });
        // var rowSplit = row.split(`${this.separator.value}`)
        var newRow = "";
        rowSplit.forEach((val, i) => {
          if (
            !typeof val == "number" ||
            isNaN(parseFloat(val)) ||
            !quotes.includes(val[0])
          ) {
            // not a number
            val = val.replaceAll('"', ""); // Remove the "
            newRow = columnIndexesToDelete.includes(i)
              ? newRow
              : newRow.length == 0
              ? `"${val}"`
              : newRow + this.separator.value + `"${val}"`;
          } else {
            newRow = columnIndexesToDelete.includes(i)
              ? newRow
              : newRow.length == 0
              ? val
              : newRow + this.separator.value + String(val);
          }
        });
        copy.push(newRow);
      });
      return [newHeaders, copy];
    },
    cleanRowSplit(rowSplit) {
      // Remove the separator for each string if it is foolow/precede by ""
      rowSplit.forEach((val, i) => {
        if (val.length > 1) {
          if (
            val[0] == this.separator.value &&
            (val[1] == '"' || val[1] == "“")
          ) {
            val = val.slice(1);
          }
          if (
            val[val.length - 1] == this.separator.value &&
            (val[val.length - 2] == '"' || val[val.length - 2] == "”")
          ) {
            val = val.slice(0, val.length - 1);
          }
        }
        rowSplit[i] = val;
      });
      return rowSplit;
    },
    getColumnsToDelete(headers) {
      var columnIndexesToDelete = [];
      var requireColumns = this.columns.map((x) => x.formalName);
      var newHeaders = headers.filter((h) => requireColumns.includes(h));
      headers.forEach((h, i) => {
        if (!requireColumns.includes(h)) {
          columnIndexesToDelete.push(i);
        }
      });
      return [columnIndexesToDelete, newHeaders];
    },
    sendFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      var clientId = TokenService.getClientId();
      console.log("CLIENT IDD", clientId);
      const requestData = {
        method: "post",
        url: `https://api.risoft.fr/v1.0/bulk/search/client_id=${clientId}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      API.customRequest(requestData)
        .then((response) => {
          console.log("RESPONSE SEND FILE", response.data);
          if (response.status == 200) {
            document.body.style.cursor = "default";
            this.waitingModal = false;
            this.validationOngoing = false;
            this.$store.dispatch("processFileLoading", {
              jobId: response.data.job_id,
            });
            window.scrollTo(0, 0);
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.sendFile(file))
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
                document.body.style.cursor = "default";
                this.waitingModal = false;
              });
          } else {
            document.body.style.cursor = "default";
            this.waitingModal = false;
            this.error = true;
          }
        })
        .catch((e) => {
          console.log("EROORR", e);
          document.body.style.cursor = "default";
          this.waitingModal = false;
          this.error = true;
        });
    },
  },
  watch: {
    validationOngoing: function () {
      // Reset validation ongoing after 3 minutes
      setTimeout(
        function () {
          this.validationOngoing = false;
        }.bind(this),
        180000
      );
    },
  },
};
</script>

<style scoped>
.file-imported {
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.card-column-mapping {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.title-card-mapping {
  display: flex;
}

.columns-card-grid {
  margin-top: 20px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: calc(25% - 10px) calc(25% - 10px) calc(25% - 10px) calc(
      25% - 10px
    );
  grid-row-gap: 20px;
  grid-column-gap: 10px;
}

.column-card {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  height: 100px;
  text-align: center;
  padding-top: 5px;
  display: grid;
  grid-template-rows: 30px auto;
  grid-row-gap: 20px;
}

.card-headers {
  display: grid;
  grid-template-columns: 85% calc(10% - 5px);
  grid-column-gap: 5px;
  margin-left: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.card-title {
  font-size: 14px;
  font-weight: 600;
}

.select-column {
  background-color: #f9fafa;
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 20px;
}

.chevron-icon {
  position: absolute;
  margin-left: -22px;
  margin-top: 10px;
}

.div-announce {
  padding: 8px;
  border-radius: 5px;
}
</style>
