<template>
  <div>
    <Navbar />
    <div class="div-under-navbar"></div>
    <div class="homepage-content">
      <div class="title-homepage">Recherche de décès<br />(source INSEE)</div>

      <router-link
        :to="isAuthenticated ? '/application/singlesearch' : '/inscription'"
        class="commencer-button px-3 py-2"
      >
        Recherche unitaire<v-icon color="accent" class="ml-1 mb-1">{{
          singleSearchIcon
        }}</v-icon>
      </router-link>

      <v-img
        class="homepage-image margin-x-auto"
        lazy-src="../assets/img/schema_recherche_unitaire_lineaire_explication.jpg"
        width="95%"
        src="../assets/img/schema_recherche_unitaire_lineaire_explication.jpg"
        sync
      ></v-img>
      <div class="description-image margin-x-auto">
        <strong>Recherche unitaire</strong><br />
        <ol>
          <li>
            Vous pouvez rechercher si quelqu’un est décédé en renseignant ses
            données d’identité<br />
          </li>
          <li>
            DCD recherche si des individus pouvant correspondre à votre
            recherche existent dans la base des décès de l’INSEE<br />
          </li>
          <li>
            DCD vous renvoie la liste des correspondances potentielles<br />
          </li>
          <li>
            Vous pouvez demander à être notifié par mail le jour où cet individu
            apparaitra dans la base des décès
          </li>
        </ol>
      </div>

      <router-link
        :to="isAuthenticated ? '/application/bulksearch' : '/inscription'"
        class="commencer-button px-3 py-2"
      >
        Recherche groupée<v-icon color="accent" class="ml-1 mb-1">{{
          bulkSearchIcon
        }}</v-icon>
      </router-link>

      <v-img
        class="homepage-image margin-x-auto"
        lazy-src="../assets/img/schema_recherche_groupee_lineaire_explication.jpg"
        width="95%"
        src="../assets/img/schema_recherche_groupee_lineaire_explication.jpg"
        sync
      ></v-img>
      <div class="description-image margin-x-auto">
        <strong>Recherche groupée</strong><br />
        <ol>
          <li>
            Vous uploadez sur le site un fichier csv contenant une liste
            d’individu<br />
          </li>
          <li>
            DCD recherche chacun d’entre eux dans la base des décès de
            l’INSEE<br />
          </li>
          <li>
            DCD vous renvoie la liste des personnes décédées de votre liste avec
            leur date de décès<br />
          </li>
          <li>
            Vous pouvez demander à être notifié par mail le jour où un ou
            plusieurs de ces individus apparaitra dans la base des décès
          </li>
        </ol>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mdiAccountSearch, mdiFileSearchOutline } from "@mdi/js";
import Footer from "@/components/layout/Footer.vue";
import Navbar from "@/components/layout/Navbar.vue";
import { SessionStorage } from "@/services/Storage"

export default {
  name: "Homepage",
  components: {
    Footer,
    Navbar,
  },
  data: () => ({
    singleSearchIcon: mdiAccountSearch,
    bulkSearchIcon: mdiFileSearchOutline,
  }),
  computed: {
    isAuthenticated() {
      return SessionStorage.getClientRefreshToken();
    },
  },
};
</script>

<style>
.app-navbar > div {
  width: 95vw;
  margin: auto;
}

.basic-button-text {
  text-transform: none;
  letter-spacing: 0;
}

.commencer-button {
  text-decoration: none !important;
  background-color: #fff;
  border-radius: 5px;
  color: #258b9b !important;
  font-weight: 500;
  position: relative;
  border: 2px solid #258b9b;
  z-index: 5;
  top: 0px;
}

.connection-button > span {
  color: #fff;
}

.inscription-button > span {
  color: #258b9b;
}

.reset-color-focus:focus::before {
  opacity: 0 !important;
}

.app-bar-title {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.div-under-navbar {
  background-color: #258b9b;
  height: 250px;
  width: 100%;
  position: absolute;
}

.homepage-content {
  margin-top: 120px;
  position: relative;
  columns: 1;
  text-align: center;
  text-align: -webkit-center;
  margin-bottom: 100px;
}

.title-homepage {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 85px;
}

.move-bottom-18 {
  margin-bottom: -18px;
  z-index: 1;
}

.homepage-image {
  border-radius: 0%;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
}

.homepage-image + div {
  margin-bottom: 100px;
}

.description-image {
  text-align: justify;
  width: 55%;
  font-size: 15px;
  line-height: 1.3;
  margin-top: 10px;
}
</style>
