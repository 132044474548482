<template>
  <div class="file-search-wrapper-div">
    <div class="file-search-title">
      Étape 1. <span class="ml-3">Fichier : upload</span>
    </div>
    <div class="description">
      La limite du service est liée a la taille du fichier (10Mo, vous pouvez le
      découper en plusieurs sous-fichiers). Il doit permettre en quelques
      minutes le traitement de fichiers clients de quelques dizaines de milliers
      de lignes. Les données transmises sont effacées 1h après la fin du
      traitement, en dehors de certaines données concernant les individus
      décédés : cf
      <router-link to="/faq" target="_blank"><strong>FAQ</strong></router-link>
      "quelles sont les données que nous sommes susceptibles d’enregistrer et
      pourquoi".
      <br /><br />
      Le fichier doit être un tableur CSV et déposé ci-dessous. Vous pouvez
      tester avec notre fichier exemple :
      <a @click="downloadExampleCsv" style="text-decoration: underline"
        >exemple.csv</a
      >
    </div>
    <div class="div-import-form">
      <div class="">
        <p class="pl-4 mb-0">
          En utilisant le service, vous acceptez les conditions générales
          d'utilisation
        </p>
        <v-col cols="12" style="height: 30px">
          <v-checkbox v-model="acceptCGU" class="pa-0 ma-0" style="float: left">
          </v-checkbox>
          <div>
            J'accepte les
            <router-link to="/cgu" target="_blank"
              >conditions générales d'utilisation</router-link
            >.
          </div>
        </v-col>
      </div>
      <div v-if="storedValues" class="flex">
        <div class="confirmation-message-jobid success-color mb-3 mt-8">
          <v-icon class="icon-success">{{ iconChecked }}</v-icon>
          Le fichier "<strong>{{ fileName }}.csv</strong>" a déjà été importé.
          Vous pouvez passer à l'étape suivante.
        </div>
        <v-btn color="error" class="mb-3 mt-9" @click="removeFileLoaded">
          <span class="basic-button-text">Le supprimer</span>
        </v-btn>
      </div>
      <div
        v-else
        v-cloak
        @drop.prevent="addDropFile"
        @dragover.prevent
        class="drag-drop-file"
      >
        <v-file-input
          v-model="file"
          show-size
          truncate-length="15"
          dense
          outlined
          class="mt-5 input-file"
          placeholder="Sélectionnez ou glissez un fichier CSV ici"
          @change="loadFile"
          :rules="fileRules"
        ></v-file-input>
      </div>
      <div id="error-div" v-if="message" class="error-div">
        <div class="pa-1">{{ message }}</div>
      </div>
      <div class="separator-grid-wrapper">
        <div v-if="file" class="separator-grid">
          <span>Séparateur du fichier :</span>
          <v-select
            @change="setSeparator"
            v-model="separator"
            :items="separators"
            class="separator-input"
            dense
            solo
          ></v-select>
        </div>
      </div>
      <div
        v-if="file && separator && dataArray && !storedValues"
        class="confirmation-message success-color mb-3"
      >
        <v-icon class="icon-success">{{ iconChecked }}</v-icon
        >Le fichier a bien été importé !
      </div>
      <div
        v-if="file && separator && dataArray && !acceptCGU"
        class="confirmation-message error-color mb-3"
      >
        Vous devez accepter les conditions générales d'utilisation avant de
        passer à l'étape suivante.
      </div>
    </div>
    <div
      v-if="
        (file && separator && dataArray && acceptCGU) || (jobId && acceptCGU)
      "
      @click="goConfigureFile"
      class="next-step pointer-on-hover"
    >
      Étape suivante
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mdiCheckCircle } from "@mdi/js";
import { utils } from "../../../services/Utils";
import FileStorage from "../../../services/FileStorage";
import exampleData from "@/assets/data/exemple.json";

export default {
  name: "ImportFile",
  data: () => ({
    exampleData: exampleData,
    // file: null,
    message: "",
    iconChecked: mdiCheckCircle,
    separators: [
      { text: "Virgule", value: "," },
      { text: "Point-virgule", value: ";" },
      { text: "Espace", value: " " },
      { text: "Tabulation", value: "\t" },
      { text: "Pipe", value: "|" },
    ],
    fileRules: [
      (v) => {
        if (!v) return true;
        if (v.name.split(".")[v.name.split(".").length - 1] != "csv")
          return false;
        return true;
      },
    ],
  }),
  computed: {
    ...mapFields([
      "fileSearch.step",
      "fileSearch.file",
      "fileSearch.fileName",
      "fileSearch.data",
      "fileSearch.dataArray",
      "fileSearch.dataColMapping",
      "fileSearch.separator",
      "fileSearch.columns",
      "fileSearch.acceptCGU",
      "fileSearch.jobId",
      "fileSearch.storedValues",
    ]),
  },
  watch: {
    file() {
      // When file changes, reset all the variables depending on it
      this.$store.commit("resetFileVariables");
    },
    dataArray() {
      // Scroll to bottom after file loaded in order to see the "next" button
      if (this.file && this.acceptCGU) {
        setTimeout(function () {
          window.scrollTo(0, document.body.scrollHeight); // Scroll down to see the new button
        }, 1);
      }
    },
  },
  methods: {
    saveValueStore(name, value) {
      this.$store.commit("setFileSearchValue", [name, value]);
    },
    addDropFile(e) {
      var file = e.dataTransfer.files[0];
      if (!this.validFile(file.name)) {
        this.message = "Le fichier doit être au format csv";
        this.saveValueStore("file", null);
        setTimeout(function () {
          this.message = "";
        }, 5000);
      } else if (file && this.validFile(file.name)) {
        this.message = "";
        this.saveValueStore("file", file);
        this.checkSeparators();
      }
    },
    loadFile(file) {
      // Load the file from the input and
      // save it if ok or display error message
      if (file && !this.validFile(file.name)) {
        this.message = "Le fichier doit être au format csv";
        this.saveValueStore("file", null);
      } else if (file && file.size && file.size > 10 ** 7) {
        console.log("FILE TOO BIG", file.size);
        this.message = `La taille du fichier doit être inférieure à 10Mo. Elle est actuellement de ${utils.calculateSize(
          file.size
        )}.`;
        this.saveValueStore("file", null);
      } else if (file && this.validFile(file.name)) {
        this.message = "";
        this.saveValueStore("file", file);
        var name = file.name.split(".csv")[0];
        this.$store.commit("setFileSearchValue", ["fileName", name]);
        this.checkSeparators();
      } else if (!file) {
        this.message = "";
        this.saveValueStore("file", null);
      }
    },
    validFile(fileName) {
      return (
        fileName && fileName.split(".")[fileName.split(".").length - 1] == "csv"
      );
    },
    checkSeparators() {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.saveValueStore("data", e.target.result);
        var data = e.target.result;
        var dataArray = data.replaceAll("\r", "").split("\n");
        this.saveValueStore("dataArray", dataArray);
        var selectedSeparators = this.separators.filter((sep) => {
          return this.testSeparator(dataArray, sep.value);
        });
        var separator = this.$store.state.fileSearch.separator;
        separator =
          selectedSeparators.length > 0 ? selectedSeparators[0] : separator;
        this.saveValueStore("separator", separator);
      };
      reader.readAsText(this.$store.state.fileSearch.file);
    },
    testSeparator(data, sep) {
      var nbColumnsHeader = data.length > 1 ? data[0].split(sep).length : 0;
      return nbColumnsHeader > 1;
    },
    getValues(separators) {
      var list = [];
      separators.forEach((sep) => list.push(sep.name));
      return list;
    },
    setSeparator(e) {
      this.saveValueStore("separator", e);
    },
    goConfigureFile() {
      this.$store.commit("increaseStep", 2);
      this.jobId ? "" : this.setDataColMapping();
      window.scrollTo(0, 0);
    },
    setDataColMapping() {
      var colNames = this.dataArray[0].split(this.separator.value).concat([""]);
      var dataColMapping = {};
      colNames.forEach((col) => (dataColMapping[col] = false));
      this.saveValueStore("dataColMapping", dataColMapping);
    },
    removeFileLoaded() {
      // Remove file vuex variable if jobId removed
      this.$store.commit("setFileSearchValue", ["file", null]);
      this.$store.commit("resetFileVariables");
      FileStorage.removeFileData();
    },
    downloadExampleCsv() {
      let unparsedData = this.$papa.unparse(this.exampleData);
      console.log("FFFFF", unparsedData);
      this.$papa.download(unparsedData, "exemple");
    },
  },
};
</script>

<style scoped>
.drag-drop-file {
  width: 500px;
  height: 80px;
  background-color: #f4f4f4;
  border-width: 1px;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.input-file {
  width: 90%;
  margin-left: 10px;
}

.error-div {
  margin: auto;
  color: #e70a24;
  background-color: #f1bfbf;
  width: 500px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
}

.separator-input {
  width: 170px;
}

.separator-grid-wrapper {
  width: 500px;
  margin: auto;
}

.separator-grid {
  display: grid;
  grid-template-columns: 200px 180px;
  margin-top: 15px;
  margin-left: auto;
}

.confirmation-message {
  width: 500px;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
}

.confirmation-message-jobid {
  width: 700px;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
}

.div-import-form {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0;
  border-radius: 5px;
  padding: 12px 10px;
}
</style>