<template>
  <div>
    <Sidebar />
    <div class="app-container">
      <div class="fileSearch-headers">
        <div class="heading pt-3">Centre de notifications</div>
        <div class="header-description-text">
          Consultez ici l'ensemble des recherches pour lesquelles vous serez
          notifié des nouveaux résultats.
          <br /><br />
          Vous pouvez être notifié des résultats d'une recherche unitaire ou
          d'une recherche groupée dans un fichier. Ces recherches se
          déclencheront de manière automatique lorsque la base de l'INSEE sera
          mise à jour.
        </div>
      </div>

      <div class="pa-5">
        <div class="filesearch-subtitle">Recherches unitaires</div>
        <div v-if="tasksToPay" class="info-color message-div-payment">
          <v-icon color="button"> {{ infoIcon }} </v-icon>
          Vous avez {{ tasksToPay }} tâche(s) en attente pour activer
          l'envoie de notifications. Veuillez sélectionner celles que vous voulez
          activer dans la première colonne du tableau ci-dessous.
        </div>
        <div
          v-for="messageS in messagesSingle"
          :key="messageS"
          class="success-color message"
        >
          {{ messageS }}
        </div>
        <div
          v-for="errorS in errorsSingle"
          :key="errorS"
          class="error-color message"
        >
          {{ errorS }}
        </div>
        <div
          v-if="
            singleTasks && Array.isArray(singleTasks) && singleTasks.length == 0
          "
          class="message-div info-color message flex"
        >
          <v-icon color="button" class="mr-3" style="margin-bottom: auto">
            {{ infoIcon }}</v-icon
          >
          <div>
            Vous n'avez pour l'insant aucune recherche unitaire de planifiée.
            <br /><br />
            Vous pouvez en ajouter dans l'onglet "<strong
              >recherche unitaire</strong
            >".
          </div>
        </div>
        <v-data-table
          v-if="
            singleTasks && Array.isArray(singleTasks) && singleTasks.length > 0
          "
          item-key="id"
          :headers="headersSingle"
          :items="singleTasks"
          :customSort="customSort"
          show-select
          class="elevation-1 notification-table"
        >
          <template v-slot:[`header.data-table-select`]>
            <span>Tâches à activer</span>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteSingle" max-width="550px">
              <v-card>
                <div class="pa-5 center semi-bold text-color">
                  Êtes-vous sûr de vouloir supprimer cette tâche ?
                </div>
                <div class="actions-wrapper">
                  <v-btn color="primary" text @click="closeDelete"
                    >Annuler</v-btn
                  >
                  <v-btn
                    color="accent"
                    text
                    @click="deleteItemConfirm('single')"
                    >Oui, supprimer</v-btn
                  >
                </div>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPayTasks" max-width="550px">
              <v-card>
                <v-card-title
                  class="text-h6 grey lighten-2"
                  style="color: #2b3e4a"
                >
                  Activation des notifications
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                  <div class="payment-table">
                    <div class="border-bottom pa-3">
                      <span class="mb-5 semi-bold"
                        >RÉCAPITULATIF</span
                      >
                      <div
                        v-for="article in selectedSingleTasks"
                        :key="article.id"
                        class="flex semi-bold space-between pt-3"
                      >
                        <v-tooltip right max-width="400" nudge-top="50">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              @mouseenter="seeTheFilter(article)"
                              class="link-appearance"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ article.name }} {{ article.shownFilter }}
                            </div>
                          </template>
                          <p v-for="text in formattedFilter" :key="text">
                            {{ text }}
                          </p>
                        </v-tooltip>
                        <span style="float: left" class="regular mr-5"
                          >{{ (singleTaskPrice / 100).toFixed(2) }}€</span
                        >
                      </div>
                    </div>
                    <div class="border-bottom pa-3">
                      <span class="mb-5 semi-bold">TOTAL</span>
                      <span style="float: right" class="mr-5"
                        >{{ (price / 100).toFixed(2) }}€</span
                      >
                    </div>
                    <div class="center ma-5">
                      <div
                        class="colored-btn blue-btn"
                        @click="processPaymentNotification(selectedSingleTasks)"
                      >
                        Activer
                      </div>
                      <div
                        class="border-btn cancel-btn mt-5"
                        @click="dialogPayTasks = false"
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <!-- <v-card>
                <div class="pa-5 center semi-bold text-color">Paiement des tâches unitaires</div>
                <div class="actions-wrapper">
                  <v-btn color="primary" text @click="dialogPayTasks = false">Annuler</v-btn>
                  <v-btn color="accent" text @click="paySingleTasks">Procéder au paiement</v-btn>
                </div>
                </v-card> -->
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.data-table-select`]="{ item }">
            <v-simple-checkbox
              v-ripple
              v-if="!item.paid"
              @click="selectSingleSearch(item)"
              v-model="item.selected"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <span>{{ adaptTypeName(item.type) }}</span>
          </template>
          <template v-slot:[`item.shownFilters`]="{ item }">
            <v-tooltip right max-width="400" nudge-top="50">
              <template v-slot:activator="{ on, attrs }">
                <div
                  @mouseenter="seeTheFilter(item)"
                  class="link-appearance"
                  v-bind="attrs"
                  v-on="on"
                >
                  voir
                </div>
              </template>
              <p v-for="text in formattedFilter" :key="text">{{ text }}</p>
            </v-tooltip>
          </template>
          <template v-slot:[`item.start_date`]="{ item }">
            <span>{{ formatDate(item.start_date) }}</span>
          </template>
          <template v-slot:[`item.previous_run_date`]="{ item }">
            <span>{{ formatDate(item.previous_run_date) }}</span>
          </template>
          <template v-slot:[`item.next_run_date`]="{ item }">
            <span>{{ formatDate(item.next_run_date) }}</span>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-simple-checkbox v-model="item.paid" disabled></v-simple-checkbox>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-simple-checkbox
              v-model="item.enabled"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="center">
              <v-icon small @click="deleteItem(item, 'single')">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
        <div
          v-if="selectedSingleTasks.length > 0"
          class="colored-btn blue-btn payment-btn"
          @click="openPaymentModal"
        >
          Activer les notifications des tâches sélectionnées
        </div>
      </div>

      <div class="pa-5">
        <div class="filesearch-subtitle">Recherches groupées</div>
        <div
          v-for="messageF in messagesFile"
          :key="messageF"
          class="success-color message"
        >
          {{ messageF }}
        </div>
        <div
          v-for="errorF in errorsFile"
          :key="errorF"
          class="error-color message"
        >
          {{ errorF }}
        </div>
        <div
          v-if="tasksFile && Array.isArray(tasksFile) && tasksFile.length == 0"
          class="message-div info-color message flex"
        >
          <v-icon color="button" class="mr-3" style="margin-bottom: auto">
            {{ infoIcon }}</v-icon
          >
          <div>
            Vous n'avez pour l'insant aucune recherche groupée dans un fichier
            de planifiée.
            <br /><br />
            Vous pouvez en ajouter à la dernière étape de l'onglet "<strong
              >recherche groupée</strong
            >".
          </div>
        </div>
        <v-data-table
          v-if="tasksFile && Array.isArray(tasksFile) && tasksFile.length > 0"
          :headers="headersFile"
          :items="tasksFile"
          :customSort="customSort"
          class="elevation-1 notification-table"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteFile" max-width="550px">
              <v-card>
                <div class="pa-5 center semi-bold text-color">
                  Êtes-vous sûr de vouloir supprimer cette tâche ?
                </div>
                <div class="actions-wrapper">
                  <v-btn color="primary" text @click="closeDelete"
                    >Annuler</v-btn
                  >
                  <v-btn color="accent" text @click="deleteItemConfirm('file')"
                    >Oui, supprimer</v-btn
                  >
                </div>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <span>{{ adaptTypeName(item.type) }}</span>
          </template>
          <template v-slot:[`item.start_date`]="{ item }">
            <span>{{ formatDate(item.start_date) }}</span>
          </template>
          <template v-slot:[`item.previous_run_date`]="{ item }">
            <span>{{ formatDate(item.previous_run_date) }}</span>
          </template>
          <template v-slot:[`item.next_run_date`]="{ item }">
            <span>{{ formatDate(item.next_run_date) }}</span>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-simple-checkbox
              v-model="item.enabled"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="center">
              <v-icon small @click="deleteItem(item, 'file')">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../../components/layout/Sidebar";
import { API } from "../../services/Api";
import { TokenService } from "../../services/Storage";
import { utils } from "../../services/Utils";
import { mdiInformation } from "@mdi/js";
// import Tooltip from '../../components/layout/Tooltip'
// import PayementTable from '../../components/layout/PayementTable'

export default {
  name: "Notification",
  components: {
    Sidebar,
    // Tooltip,
    // PayementTable
  },
  data: () => ({
    dialogDeleteSingle: false,
    dialogDeleteFile: false,
    dialogPayTasks: false,
    messagesSingle: [],
    errorsSingle: [],
    messagesFile: [],
    errorsFile: [],
    infoIcon: mdiInformation,
    singleTasks: [],
    tasksFile: [],
    tasksToPay: 0,
    indexEditedTask: -1,
    filters: {},
    formattedFilter: [],
    show: false,
    singleTaskPrice: 0, // 100
    price: 0,
    selectedSingleTasks: [],
    headersSingle: [
      { text: "Prénom", value: "patient_prenom" },
      { text: "Nom", value: "patient_nom_famille" },
      { text: "Sexe", value: "patient_sexe" },
      { text: "Filtre complet", value: "shownFilters" },
      { text: "Date de création", value: "start_date" },
      { text: "Date dernière recherche", value: "previous_run_date" },
      { text: "Date prochaine recherche", value: "next_run_date" },
      { text: "Activée", value: "paid" },
      // { text: "Actif", value: "enabled", sortable: false },
      { text: "Modifier", value: "actions", sortable: false },
    ],
    headersFile: [
      { text: "Date de création", value: "start_date" },
      { text: "Nom du fichier", value: "file_name" }, // TODO verify ok
      { text: "Nombre de lignes", value: "line_number" }, // TODO verify ok
      { text: `Date d'extraction`, value: "extraction_date" }, // TODO verify ok
      { text: "Date dernière recherche", value: "previous_run_date" },
      { text: "Date prochaine recherche", value: "next_run_date" },
      { text: "Statut", value: "enabled", sortable: false },
      { text: "Modifier", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      client_id: "",
      type: "",
      schedule: "",
      previous_run_date: "",
      next_run_date: "",
      enabled: false,
    },
    defaultItem: {
      id: "",
      client_id: "",
      type: "",
      schedule: "",
      previous_run_date: "",
      next_run_date: "",
      enabled: false,
    },
  }),
  computed: {
    // To import mapFields if used
    // ...mapFields(["stripe"]),
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // TODO can be improved
    messagesSingle: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.messagesSingle = [];
          }.bind(this),
          7000
        );
      }
    },
    messagesFile: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.messagesFile = [];
          }.bind(this),
          7000
        );
      }
    },
    errorsSingle: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.errorsSingle = [];
          }.bind(this),
          7000
        );
      }
    },
    errorsFile: function (val) {
      if (val.length > 0) {
        setTimeout(
          function () {
            this.errorsFile = [];
          }.bind(this),
          7000
        );
      }
    },
    singleTasks: function (val) {
      if (val.length > 0) {
        this.tasksToPay = this.singleTasks.filter(
          (value) => !value["paid"]
        ).length;
      }
    },
  },
  async created() {
    await this.fetchUserSingleTasks();
    await this.fetchUserFileTasks();
    // await this.$store.dispatch("getStripeKey");
  },
  methods: {
    async fetchUserSingleTasks() {
      var clientId = TokenService.getClientId();
      try {
        const response = await API.get(
          `cron/?client_id=${clientId}&task_type=single`
        );
        this.singleTasks = response.data.tasks || [];
        this.singleTasks.forEach((val) => (val["selected"] = false));
        this.singleTasks.forEach((val) => (val["show"] = false));
        this.singleTasks.forEach((val) => (val["filters"] = []));
      } catch (e) {
        console.log("error", e)
        if (e.status == 499) {
          await API.refreshToken();
          await this.fetchUserSingleTasks();
        } 
        if (e.status != 400) {
          var messageData = {
            text: "Une erreur est survenue lors de la connexion au serveur",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
          this.singleTasks = [];
        }
      }
    },
    async fetchUserFileTasks() {
      var clientId = TokenService.getClientId();
      try {
        const response = await API.get(
          `cron/?client_id=${clientId}&task_type=bulk`
        );
        this.tasksFile = response.data.tasks != null ? response.data.tasks : [];
      } catch (e) {
        console.log("error", e)
        if (e.status == 499) {
          await API.refreshToken();
          await this.fetchUserFileTasks();
        } 
        if (e.status != 400) {
          var messageData = {
            text: "Une erreur est survenue lors de la connexion au serveur",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
          this.tasksFile = [];
        }
      }
    },
    async seeTheFilter(item) {
      try {
        this.indexEditedTask = this.singleTasks.indexOf(item);
        this.filters = {};
        this.formattedFilter = [];
        this.show = false;
        const response = await API.get(
          `cron/single/info?task_id=${item.task_id}`
        );
        console.log("RESPONSE FILTERS", response);
        this.filters = response.data.data;
        this.formatFilterTextArray();
        this.singleTasks[this.indexEditedTask]["show"] = true;
      } catch (e) {
        console.log("error", e)
        if (e.status == 499) {
          await API.refreshToken();
          await this.seeTheFilter(item);
        } else {
          this.errorsSingle.push(
            "Une erreur est survenue pour obtenir les informations du filtres de la tâche."
          );
          this.indexEditedTask = -1;
          this.filters = {};
          this.formattedFilter = [];
          this.show = false;
        }
      }
    },
    formatFilterTextArray() {
      var keys = Object.keys(this.filters);
      var array = [];
      keys.forEach((key) => {
        if (this.filters[key]) {
          switch (key) {
            case "patient_prenom":
              array.push(`Prénom : ${this.filters[key]}`);
              break;
            case "patient_nom_famille":
              array.push(`Nom : ${this.filters[key]}`);
              break;
            case "patient_sexe":
              array.push(`Sexe : ${this.filters[key]}`);
              break;
            case "patient_date_naissance":
              array.push(`Date de naissance : ${this.filters[key]}`);
              break;
            case "patient_dep_naissance":
              array.push(`Département de naissance : ${this.filters[key]}`);
              break;
            case "patient_pays_naissance":
              array.push(`Pays de naissance : ${this.filters[key]}`);
              break;
            case "patient_code_commune_naissance":
              array.push(`Code commune naissance : ${this.filters[key]}`);
              break;
            case "patient_commune_naissance":
              array.push(`Commune de naissance : ${this.filters[key]}`);
              break;
            case "patient_date_deces":
              array.push(`Date de décès : ${this.filters[key]}`);
              break;
            case "patient_commune_deces":
              array.push(`Commune de décès : ${this.filters[key]}`);
              break;
            case "patient_code_commune_deces":
              array.push(`Code commune de décès : ${this.filters[key]}`);
              break;
            case "patient_dep_deces":
              array.push(`Département de décès : ${this.filters[key]}`);
              break;
            case "patient_pays_deces":
              array.push(`Pays de décès : ${this.filters[key]}`);
              break;
            case "patient_age_deces":
              array.push(`Age de décès : ${this.filters[key]}`);
              break;
          }
        }
      });
      this.formattedFilter = array;
    },
    formatDate(value) {
      return value ? new Date(value).toLocaleDateString() : "";
    },
    adaptTypeName(type) {
      return type == "single" ? "Recherche unitaire" : "Recherche groupée";
    },
    customSort: function (items, index, isDesc) {
      return utils.completeSorting(items, index, isDesc);
    },
    deleteItem(item, type) {
      this.editedIndex =
        type == "single"
          ? this.singleTasks.indexOf(item)
          : this.tasksFile.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteSingle = type == "single";
      this.dialogDeleteFile = type == "file";
    },
    async deleteItemConfirm(type) {
      try {
        var data = {
          task_id: this.editedItem.task_id,
          client_id: parseInt(TokenService.getClientId()),
        };
        const response = await API.delete(`cron/disable`, data);
        console.log("RESPONSE DELETE TASK", response);
        if (response.status_code == 200) {
          type == "single"
          ? this.singleTasks.splice(this.editedIndex, 1)
          : this.tasksFile.splice(this.editedIndex, 1);
          type == "single"
            ? this.messagesSingle.push(
                `Votre tâche unitaire a bien été supprimée.`
              )
            : this.messagesFile.push(`Votre tâche a bien été supprimée.`);
        }
      } catch (e) {
        if (e.status == 499) {
          await API.refreshToken();
          await this.deleteItemConfirm(type);
        } else {
          type == "single"
            ? this.errorsSingle.push(
                `Une erreur est survenue lors de la suppression de votre tâche.`
              )
            : this.errorsFile.push(
                `Une erreur est survenue lors de la suppression de votre tâche.`
              );
        }
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    selectSingleSearch(item) {
      var index = this.selectedSingleTasks.indexOf(item);
      if (index >= 0) {
        this.selectedSingleTasks.splice(index, 1);
        item.selected = false;
        item.name = "";
      } else {
        this.selectedSingleTasks.push(item);
        item.selected = true;
        item.name = `Tâche unitaire ${
          this.selectedSingleTasks.indexOf(item) + 1
        }`;
        item.class = "bold";
        console.log("SELECTED ITEM", this.selectedSingleTasks);
      }
    },
    openPaymentModal() {
      // TODO See how the single search price is set
      this.price = this.selectedSingleTasks.length * this.singleTaskPrice;
      this.dialogPayTasks = true;
    },
    async processPaymentNotification(singleTasks) {
      // Payment unactivated for now -> just active the single tasks
      let taskId = singleTasks.map((task) => task.task_id);
      await this.activateSingleNotification({ task_id: taskId });

      // // Manage the payment process
      // this.paymentSingleNotification(singleTasks)
    },
    // NOT USED
    async paymentSingleNotification(singleTasks) {
      this.dataSingleTasksPayment(singleTasks)
        .then((data) => {
          console.log("DATA OF dataSingleTasksPayment", data);
          API.post("payment/checkout", data) // TODO w8 Jean 'checkout-stripe'
            .then((response) => {
              console.log("RESPONSE paymentSingleNotification", response);
              if (response.status == 200) {
                this.callStripeCheckout(response).then((resp) => {
                  console.log("AFTER PAYMENT", resp);
                  if (resp.error) {
                    var messageData = { text: resp.error, type: "error" };
                    this.$store.dispatch("displayMessage", messageData);
                  } else {
                    this.activateSingleNotification(data); // TODO PASS the selected single tasks
                  }
                });
              } else if (response.status == 499) {
                API.refreshToken()
                  .then(() => this.paymentSingleNotification())
                  .catch(() => {
                    var messageData = {
                      text: "Une erreur est survenue lors de la connexion au serveur",
                      type: "error",
                    };
                    this.$store.dispatch("displayMessage", messageData);
                  });
              } else {
                var messageData = {
                  text: "Une erreur est survenue, veuillez réessayer.",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              }
            })
            .catch(() => {
              var messageData = {
                text: "Une erreur est survenue, veuillez réessayer.",
                type: "error",
              };
              this.$store.dispatch("displayMessage", messageData);
            });
        })
        .catch(() => {
          var messageData = {
            text: "Une erreur est survenue, veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        });
    },
    // NOT USED
    async callStripeCheckout(response) {
      console.log(
        "ENTER this.stripe.redirectToCheckout",
        response,
        this.stripe
      );
      return this.stripe.redirectToCheckout({
        sessionId: response.data.session_id,
      });
    },
    dataSingleTasksPayment(singleTasks) {
      // Format the data for the payment of single tasks
      // Need to get user data first
      return new Promise((resolve, reject) => {
        var clientEmail = TokenService.getClientEmail();
        API.get(`user/search?email=${clientEmail}`)
          .then((response) => {
            console.log("GET USER DATA", response);
            if (response.status == 200) {
              var data = {
                client: {
                  client_firstname: response.data.client_firstname,
                  client_lastname: response.data.client_lastname,
                  client_email: clientEmail,
                  client_activity_domain: response.data.client_activity_domain,
                },
                product: "Single Search Automation",
                quantity: singleTasks.length,
                task_id: singleTasks.map((v) => v.task_id),
              };
              resolve(data);
            } else if (response.status == 499) {
              API.refreshToken()
                .then(() => this.dataSingleTasksPayment(singleTasks))
                .catch(() => {
                  reject();
                });
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    async activateSingleNotification(data) {
      // Process the payement for SINGLE task only
      data["client_id"] = parseInt(TokenService.getClientId());
      data["client_email"] = TokenService.getClientEmail();
      data["filename"] = "";
      console.log("ENTER activateSingleNotification", data, typeof data["task_id"]);
      try {
        await API.put("cron/enable", data);
        let activatedTaks = this.singleTasks.filter(task => data.task_id.includes(task.task_id))
        activatedTaks.forEach(task => {
          task.paid = true;
          task.enabled = true
        });
        this.tasksToPay -= data.task_id.length
        var messageSuccess = {
          text: "Activation réussie, vous serez notifié par email lorsque de nouveaux résultats seront trouvés !",
          type: "success",
        };
        this.$store.dispatch("displayMessage", messageSuccess);
      } catch (e) {
        console.log(e);
        if (e.status == 499) {
          await API.refreshToken();
          await this.activateSingleNotification(data);
        } else {
          var messageData = {
            text: "Une erreur est survenue, veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        }
      } finally {
        this.dialogPayTasks = false
      }
    },
  },
};
</script>

<style scoped>
.notification-table {
  position: relative;
  width: 90vw;
  margin: 30px auto 20px auto;
  text-align: center;
}

.message-div {
  width: 90vw;
  margin: 30px auto;
  padding: 25px;
}

.message-div-payment {
  padding: 20px;
  border-radius: 5px;
}

.payment-btn {
  width: 550px;
  margin: auto;
}

.box-message {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 30px;
}

.payment-table {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f4f4f4;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.space-between {
  justify-content: space-between;
}
</style>