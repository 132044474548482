<template>
  <div>
    <v-app-bar app color="accent" class="app-navbar" flat hide-on-scroll>
      <router-link
        class="app-bar-title"
        style="color: white; text-decoration: none"
        to="/"
      >
        La société Risoft présente la solution DCD
      </router-link>
      <v-spacer></v-spacer>
      <router-link
        class="mr-8"
        style="color: white; text-decoration: none"
        to="/about"
        >À propos</router-link
      >
      <div v-if="!isAuthenticated">
        <v-btn
          color="accent"
          class="connection-button reset-color-focus"
          @click="dialogConnection = true"
          style="box-shadow: none; border: 1px solid #fff !important"
        >
          <span class="basic-button-text">Se connecter</span>
        </v-btn>
        <v-dialog v-model="dialogConnection" max-width="450px">
          <Connection
            v-on:switchToInscription="openLogin"
            v-bind:is-modal="true"
          />
        </v-dialog>
        <v-btn
          color="white"
          class="inscription-button reset-color-focus ml-3"
          style="box-shadow: none"
          @click="dialogInscription = true"
        >
          <span class="basic-button-text">S'inscrire</span>
        </v-btn>
        <v-dialog v-model="dialogInscription" max-width="750px">
          <Inscription
            v-on:switchToConnection="openConnection"
            v-bind:is-modal="true"
          />
        </v-dialog>
      </div>
      <div v-if="isAuthenticated">
        <router-link
          to="/application/singlesearch"
          class="application-button px-3 py-2"
        >
          Recherche unitaire<v-icon color="accent" class="ml-1 mb-1">{{
            singleSearchIcon
          }}</v-icon>
        </router-link>
        <router-link
          to="/application/bulksearch"
          class="application-button px-3 py-2 ml-8"
        >
          Recherche groupée<v-icon color="accent" class="ml-1 mb-1">{{
            bulkSearchIcon
          }}</v-icon>
        </router-link>
        <router-link to="/logout" class="ml-8" title="Déconnexion">
          <v-icon color="white">{{ deconnexionIcon }}</v-icon>
        </router-link>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mdiAccountSearch, mdiFileSearchOutline, mdiLogout } from "@mdi/js";

import Inscription from "@/views/Inscription.vue";
import Connection from "@/views/Connection.vue";
import { SessionStorage } from '@/services/Storage';

export default {
  name: "Navbar",
  data() {
    return {
      dialogConnection: false,
      dialogInscription: false,
      singleSearchIcon: mdiAccountSearch,
      bulkSearchIcon: mdiFileSearchOutline,
      deconnexionIcon: mdiLogout,
    };
  },
  components: {
    Inscription,
    Connection,
  },
  methods: {
    openConnection() {
      this.dialogInscription = false;
      this.dialogConnection = true;
    },
    openLogin() {
      this.dialogConnection = false;
      this.dialogInscription = true;
    },
  },
  computed: {
    isAuthenticated() {
      return SessionStorage.getClientRefreshToken();
    },
  },
};
</script>

<style scoped>
.application-button {
  color: #258b9b !important;
  font-weight: 500;
  background-color: #fff;
  text-decoration: none !important;
  border: 1px solid #fff;
  border-radius: 5px;
}

.deconnexion-button {
  color: #fff !important;
  font-weight: 500;
  background-color: #258b9b;
  text-decoration: none !important;
  border: 1px solid #fff;
  border-radius: 5px;
}
</style>