const FileStorage = {
  removeFileData() {
    localStorage.removeItem('fileData')
  },
  hasFileStored() {
    return Object.prototype.hasOwnProperty.call(localStorage, 'fileData')
  },
  getFileData() {
    return localStorage.getItem('fileData') ? JSON.parse(localStorage.getItem('fileData')) : {}
  },
  saveFileData(fileData) {
    localStorage.setItem('fileData', JSON.stringify(fileData))
  }
}

export default FileStorage