<template>
  <div>
    <Sidebar />
    <div class="app-container">
      <div
        id="design-home-div"
        class="design-home-div"
        style="height: 500px"
      ></div>
      <div id="home-content-wrapper">
        <div id="home-before-search" class="content-center">
          <div id="home-search-before-headers">
            <div class="heading">Recherche de décès depuis 1970</div>
            <div class="font-size-18">(source INSEE)</div>
            <div class="home-description-text">
              Remplissez les champs que vous connaissez pour savoir si une
              personne est décédée.<br />
              Tous les champs ne sont pas nécessaires mais plus il y a de champs
              remplis, plus les résultats sont précis.<br /><br />
              Pour mettre en place une alarme et être notifié en cas de décès
              d’un individu, il faut d’abord le rechercher. La notification vous
              sera proposée lors de l’étape suivante.
            </div>
          </div>
          <div id="home-search-div">
            <form>
              <div class="form-title-part mb-2">PERSONNEL</div>
              <v-row>
                <v-col cols="3">
                  <input
                    id="lastName-search"
                    name="lastName-search"
                    v-model="lastName"
                    placeholder="NOM"
                    type="text"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="3">
                  <input
                    id="firstName-search"
                    name="firstName-search"
                    v-model="firstName"
                    placeholder="PRENOM"
                    type="text"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col class="align-self-center">
                  <span class="mr-3 center">GENRE :</span>
                  <input type="radio" name="gender" value="M" id="M" />
                  <label for="M" class="ml-1">Homme</label>
                  <input
                    type="radio"
                    name="gender"
                    value="F"
                    id="F"
                    class="ml-3"
                  />
                  <label for="F" class="ml-1">Femme</label>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
              <div class="form-title-part mt-5 mb-2">NAISSANCE</div>
              <v-row>
                <v-col cols="6">
                  <input
                    id="birthdate-search"
                    name="birthdate-search"
                    v-model="birthDate"
                    placeholder="DATE DE NAISSANCE"
                    type="date"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="6">
                  <input
                    id="city-birth-search"
                    name="city-birth-search"
                    v-model="birthCity"
                    placeholder="COMMUNE"
                    type="text"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="zipcode-birth-search"
                    name="zipcode-birth-search"
                    v-model="birthZipcode"
                    placeholder="CODE COMMUNE INSEE"
                    type="number"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="dep-birth-search"
                    name="dep-birth-search"
                    v-model="birthDep"
                    placeholder="DÉPARTEMENT"
                    type="number"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="country-birth-search"
                    name="country-birth-search"
                    v-model="birthCountry"
                    placeholder="PAYS"
                    type="text"
                    class="input-full-width font-size-14"
                  />
                </v-col>
              </v-row>

              <div class="form-title-part mt-5 mb-2">DÉCÈS</div>
              <v-row>
                <v-col cols="4">
                  <input
                    id="deathdate-search"
                    name="deathdate-search"
                    v-model="deathDate"
                    type="date"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="age-death-search"
                    name="age-death-search"
                    v-model="deathAge"
                    placeholder="Âge"
                    type="number"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="city-death-search"
                    name="city-death-search"
                    v-model="deathCity"
                    placeholder="COMMUNE"
                    type="text"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="zipcode-death-search"
                    name="zipcode-death-search"
                    v-model="deathZipcode"
                    placeholder="CODE COMMUNE INSEE"
                    type="number"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="dep-death-search"
                    name="dep-death-search"
                    v-model="deathDep"
                    placeholder="DEPARTEMENT"
                    type="number"
                    class="input-full-width font-size-14"
                  />
                </v-col>
                <v-col cols="4">
                  <input
                    id="country-death-search"
                    name="country-death-search"
                    v-model="deathCountry"
                    placeholder="PAYS"
                    type="text"
                    class="input-full-width font-size-14"
                  />
                </v-col>
              </v-row>
              <v-row class="center mt-3" dense style="margin: 0">
                <v-col cols="10" class="center">
                  <v-btn
                    text
                    @click="processSingleSearch"
                    color="white"
                    class="blue-background"
                    width="300"
                  >
                    <span class="basic-button-text">Rechercher</span>
                  </v-btn>
                </v-col>
                <v-col cols="10">
                  <div text @click="resetForm" class="mb-3">
                    <span class="basic-button-text underlined pointer-on-hover"
                      >Réinitialiser</span
                    >
                  </div>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
        <div id="home-after-search" class="content-not-center display-none">
          <div class="title-result">RÉSULTAT DE RECHERCHES</div>
          <div
            class="new-search-btn pointer-on-hover"
            :title="
              formDisplayed ? 'Cacher le formulaire' : 'Nouvelle recherche'
            "
            @click="displaySingleSearchForm"
          >
            <font-awesome-icon
              v-if="formDisplayed"
              icon="minus"
              id="button-new-query"
              color="white"
            />
            <v-icon v-else id="button-new-query" color="white">{{
              searchIcon
            }}</v-icon>
          </div>
          <div
            class="notification-btn pointer-on-hover"
            @click="dialogNotification = true"
            color="accent"
            title="Notification en cas de futurs résultats avec cette recherche"
          >
            <v-icon color="white" size="20">{{ notificationIcon }}</v-icon>
          </div>
          <v-dialog v-model="dialogNotification" max-width="800px">
            <NotificationModal
              v-on:close-dialog="dialogNotification = false"
              v-on:start-notification="createSingleNotification"
              :textArray="textNotification"
              :title="titleNotification"
              :validationText="validationText"
            />
          </v-dialog>
          <div class="filtre-title">FILTRES :</div>
          <div class="flex">
            <Tooltip
              v-if="results.length == 0"
              color="errorBody"
              :icon="warningIcon"
              :textArray="warningNoResultsText"
              class="mr-10"
            />
            <div class="filter-list">
              <div v-for="filter in filters" :key="filter.title">
                <v-icon class="icon-filter">{{ filter.icon }}</v-icon>
                <span class="label-filter font-size-14">{{
                  filter.title
                }}</span>
                <span class="font-size-14">{{ filter.value }}</span>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="results"
            item-key="matchid_numero_ligne_source"
            class="elevation-1 table-single-search"
            :search="search"
            :customSort="customSort"
            show-expand
            :expanded.sync="expanded"
            :single-expand="false"
          >
            <template v-slot:expanded-item="{ item }" class="extanded-row">
              <td :colspan="2" class="expanded-col">
                <tr class="expanded-title">
                  NAISSANCE
                </tr>
                <div class="expanded-categorie">Nom</div>
                <div class="expanded-categorie">Prénom(s)</div>
                <div class="expanded-categorie">Sexe</div>
                <div class="expanded-categorie">Date</div>
                <div class="expanded-categorie">Commune</div>
                <div class="expanded-categorie">Département</div>
                <div class="expanded-categorie">Pays</div>
              </td>
              <td :colspan="3" class="expanded-col">
                <div class="expanded-title"></div>
                <div class="expanded-value">
                  {{ item.matchid_patient_lastname }}
                </div>
                <div class="expanded-value">
                  {{ item.matchid_patient_prenom }}
                </div>
                <div class="expanded-value">{{ item.matchid_patient_sex }}</div>
                <div class="expanded-value">
                  {{ item.matchid_date_naissance }}
                </div>
                <div class="expanded-value">
                  {{ item.matchid_ville_naissance }}
                </div>
                <div class="expanded-value">
                  {{ item.matchid_departement_naissance }}
                </div>
                <div class="expanded-value">
                  {{ item.matchid_pays_naissance }}
                </div>
              </td>
              <td :colspan="2" class="border-left expanded-col">
                <div class="expanded-title">DÉCÈS</div>
                <div class="expanded-categorie">Date</div>
                <div class="expanded-categorie">Age</div>
                <div class="expanded-categorie">Commune</div>
                <div class="expanded-categorie">Département</div>
                <div class="expanded-categorie">Pays</div>
                <div class="expanded-categorie">Acte</div>
                <div class="expanded-categorie">Source INSEE</div>
              </td>
              <td :colspan="2" class="expanded-col">
                <div class="expanded-title"></div>
                <div class="expanded-value">{{ item.matchid_date_deces }}</div>
                <div class="expanded-value">
                  {{ item.matchid_age_deces }} ans
                </div>
                <div class="expanded-value">{{ item.matchid_ville_deces }}</div>
                <div class="expanded-value">
                  {{ item.matchid_departement_deces }}
                </div>
                <div class="expanded-value">{{ item.matchid_pays_deces }}</div>
                <div class="expanded-value"></div>
                <div class="expanded-value">
                  {{ item.matchid_numero_ligne_source }}
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <v-snackbar
      color="#f0ad4e"
      v-model="waitingMessage"
      timeout="-1"
      style="margin-left: 30px"
    >
      La recherche est en cours, veuillez patienter...
      <template v-slot:action>
        <v-icon @click="waitingMessage = false">
          {{ closeIcon }}
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import { API } from "../../services/Api";
import { TokenService } from "../../services/Storage";
import { utils } from "../../services/Utils";
import {
  mdiAccount,
  mdiMapMarker,
  mdiCalendarBlankOutline,
  mdiTag,
  mdiAlertCircle,
  mdiBellRing,
  mdiAccountSearch,
  mdiClose,
} from "@mdi/js";
import { mapFields } from "vuex-map-fields";
import NotificationModal from "../../components/layout/NotificationModal";
import Tooltip from "../../components/layout/Tooltip";

export default {
  name: "SingleSearch",
  components: {
    Sidebar,
    NotificationModal,
    Tooltip,
  },
  data: () => ({
    search: "",
    formDisplayed: false,
    expanded: [],
    dialogNotification: false,
    birthDateFormatted: "",
    deathDateFormatted: "",
    warningIcon: mdiAlertCircle,
    notificationIcon: mdiBellRing,
    searchIcon: mdiAccountSearch,
    closeIcon: mdiClose,
    waitingMessage: false,
    // warningText: [
    //     `Votre recherche comporte plus de 100 résultats. Nous affichons uniquement les 100 premiers.`,
    //     `Il se peut que le résultat que vous cherchiez ne soit pas compris dedans. Veuillez dans ce cas affiner votre recherche.`
    // ],
    warningNoResultsText: [
      `Aucun résultat n'a été trouvé pour votre recherche. Essayez de changer vos filtres.`,
    ],
    textNotification: [
      `Vous avez la possibilité d'être notifié par email si de nouveaux résultats sont trouvés pour votre 
            recherche.`,
      `En cliquant sur "notifier", vous ajoutez cette recherche à la liste des recherches 
            du centre de notifications, que vous pouvez consulter dans l'onglet "Centre de notifications" 
            (la petite cloche du menu latéral). 
            Vous devez ensuite sélectionner les recherches que vous voulez activer dans cet onglet Notification.`,
    ],
    titleNotification: `Notification des résultats d'une recherche`,
    validationText: `Je veux être notifié des résultats`,
  }),
  mounted() {
    this.manageDatePicker();
  },
  methods: {
    manageDatePicker() {
      // Used for safari browser
      var dateInputs = Array.from(document.querySelectorAll('[type="date"]'));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      console.log("IS SAFARI", isSafari, "ALL INPUTS", dateInputs);
      if (isSafari) {
        dateInputs.forEach((input) => {
          input.placeholder = "jj/mm/aaaa";
        });
      }
    },
    resetForm(evt) {
      evt.target.closest("FORM").reset();
      this.$store.commit("resetSingleSearch");
    },
    displaySingleSearchForm() {
      this.formDisplayed
        ? document
            .getElementById("home-search-div")
            .classList.add("display-none")
        : document
            .getElementById("home-search-div")
            .classList.remove("display-none");
      this.formDisplayed = !this.formDisplayed;
    },
    customSort: function (items, index, isDesc) {
      return utils.completeSorting(items, index, isDesc);
    },
    deleteEmptyFields(obj) {
      for (var key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      }
      return obj;
    },
    formatDate(date) {
      var split = date.split("-");
      return split.reverse().join("/");
    },
    createDataJSON() {
      var data = {
        patient_prenom: this.firstName,
        patient_nom_famille: this.lastName,
        patient_sexe: this.gender,
        patient_date_naissance: this.birthDateFormatted,
        patient_dep_naissance: this.birthDep,
        patient_pays_naissance: this.birthCountry,
        patient_code_commune_naissance: this.birthZipcode,
        patient_commune_naissance: this.birthCity,
        patient_date_deces: this.deathDateFormatted,
        patient_commune_deces: this.deathCity,
        patient_code_commune_deces: this.deathZipcode,
        patient_dep_deces: this.deathDep,
        patient_pays_deces: this.deathCountry,
        patient_age_deces: this.deathAge,
      };
      return data;
    },
    processSingleSearch() {
      this.formDisplayed = false;
      var genderInputs = Array.from(document.querySelectorAll("[name=gender]"));
      var genderSelected = genderInputs.filter((input) => input.checked);
      var genderVal = genderSelected.length == 1 ? genderSelected[0].value : "";
      this.$store.commit("setGenderValue", genderVal);
      this.deathDateFormatted = this.formatDate(this.deathDate);
      this.birthDateFormatted = this.formatDate(this.birthDate);
      console.log("DATTTE", this.birthDate, this.deathDate);
      var data = this.createDataJSON();
      data = this.deleteEmptyFields(data);
      console.log("DATA SINGLE SEARCH", data);
      this.requestSingleSearch(data);
    },
    requestSingleSearch(data) {
      this.waitingMessage = true;
      API.post("single/search", data)
        .then((response) => {
          this.waitingMessage = false;
          console.log("RESPONSE SINGLE SEARCH", response);
          if (response.status == 200) {
            document
              .getElementById("home-search-div")
              .classList.add("display-none");
            document
              .getElementById("home-search-before-headers")
              .classList.add("display-none");
            var valArray = this.setValArray();
            this.filters = valArray.filter((f) => f["value"].length >= 1);
            this.results =
              response["data"] &&
              Object.prototype.hasOwnProperty.call(response["data"], "matches")
                ? response["data"]["matches"]
                : [];
            document
              .getElementById("home-after-search")
              .classList.remove("display-none");
            document.getElementById("design-home-div").style.height = "150px";
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.requestSingleSearch(data))
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            var messageData = {
              text: "Une erreur est survenue lors de la requête au serveur, veuillez réessayer.",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageData);
          }
        })
        .catch(() => {
          this.waitingMessage = false;
          var messageData = {
            text: "Une erreur est survenue lors de la requête au serveur, veuillez réessayer.",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        });
    },
    setValArray() {
      return [
        { title: "NOM : ", value: this.lastName, icon: mdiAccount },
        { title: "PRÉNOM : ", value: this.firstName, icon: mdiAccount },
        { title: "GENRE : ", value: this.gender, icon: mdiAccount },
        {
          title: "NAISSANCE : ",
          value: `${this.birthCity}${
            this.birthZipcode ? " - " + this.birthZipcode : ""
          }${this.birthCountry ? ", " + this.birthCountry : ""}`,
          icon: mdiMapMarker,
        },
        {
          title: "DATE DE NAISSANCE : ",
          value: this.birthDateFormatted,
          icon: mdiCalendarBlankOutline,
        },
        { title: "ÂGE DU DÉCÈS : ", value: this.deathAge, icon: mdiTag },
        {
          title: "DÉCÈS : ",
          value: `${this.deathCity}${
            this.deathZipcode ? " - " + this.deathZipcode : ""
          }${this.deathCountry ? ", " + this.deathCountry : ""}`,
          icon: mdiMapMarker,
        },
        {
          title: "DATE DE DÉCÈS : ",
          value: this.deathDateFormatted,
          icon: mdiCalendarBlankOutline,
        },
      ];
    },
    createSingleNotification() {
      console.log("START AUTO SINGLE SEARCH");
      var data = this.createDataJSON();
      data = this.deleteEmptyFields(data);
      var clientId = TokenService.getClientId();
      var clientEmail = TokenService.getClientEmail();
      data["client_id"] = clientId;
      data["client_email"] = clientEmail
      API.post("cron/single/create", data)
        .then((response) => {
          console.log("RESPONSE NOTIF SINGLE", response);
          if (response.status == 200) {
            var messageData = {
              text: "Votre recherche a bien été ajoutée au centre des notifications",
              type: "success",
            };
            this.$store.dispatch("displayMessage", messageData);
          } else if (response.status == 499) {
            API.refreshToken()
              .then(() => this.createSingleNotification())
              .catch(() => {
                var messageData = {
                  text: "Une erreur est survenue lors de la connexion au serveur",
                  type: "error",
                };
                this.$store.dispatch("displayMessage", messageData);
              });
          } else {
            var messageDataFail = {
              text: "Une erreur est survenue, veuillez réessayer",
              type: "error",
            };
            this.$store.dispatch("displayMessage", messageDataFail);
          }
        })
        .catch(() => {
          var messageData = {
            text: "Une erreur est survenue, veuillez réessayer",
            type: "error",
          };
          this.$store.dispatch("displayMessage", messageData);
        });
      this.dialogNotification = false;
    },
  },
  computed: {
    headers() {
      return [
        { text: "Nom", value: "matchid_patient_lastname" },
        { text: "Prénom", value: "matchid_patient_prenom" },
        { text: "Sexe", value: "matchid_patient_sex" },
        { text: "Naisssance", value: "matchid_date_naissance" },
        { text: "Décès", value: "matchid_date_deces" },
        { text: "Ville du décès", value: "matchid_ville_deces" },
        { text: "Code postal du décès", value: "matchid_ville_code_deces" },
        { text: "Score de confiance", value: "matchid_score" },
        { text: `Plus d'information`, value: "data-table-expand" },
      ];
    },
    ...mapFields([
      "singleSearch.results",
      "singleSearch.filters",
      "singleSearch.lastName",
      "singleSearch.firstName",
      "singleSearch.gender",
      "singleSearch.birthDate",
      "singleSearch.birthCity",
      "singleSearch.birthZipcode",
      "singleSearch.birthDep",
      "singleSearch.birthCountry",
      "singleSearch.deathAge",
      "singleSearch.deathDate",
      "singleSearch.deathCity",
      "singleSearch.deathZipcode",
      "singleSearch.deathDep",
      "singleSearch.deathCountry",
    ]),
  },
};
</script>

<style scoped>
.design-home-div {
  position: absolute;
  background-color: #eff3f4;
  top: 0;
  width: calc(100% - 65px);
}

.content-center {
  width: 100%;
  position: relative;
  text-align: center;
  margin-top: 10px;
}

.content-not-center {
  width: 100%;
  position: relative;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.home-description-text {
  color: rgba(67, 98, 117, 0.55);
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 30px;
}

#home-search-div {
  position: relative;
  width: 800px;
  height: 490px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: auto;
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  padding: 20px 50px;
}

.form-title-part {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}

.title-result {
  font-weight: 600;
}

.notification-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px;
  background-color: #258b9b;
  border-radius: 50%;
}

.notification-btn > span {
  margin-top: 5px;
}

.new-search-btn {
  position: absolute;
  right: 0;
  top: 0px;
  margin-right: 100px;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px;
  background-color: #258b9b;
  border-radius: 50%;
}

.new-search-btn > svg {
  margin-top: 8px;
}

.filtre-title {
  color: #7d848b;
  font-size: 14px;
}

.filter-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 10px 10px 10px 5px;
  width: calc(100% - 220px);
  min-height: 50px;
}

.filter-list > div {
  background-color: white;
  padding: 5px 8px;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
}

.icon-filter {
  height: 18px;
  width: 18px;
  margin-top: -5px;
  color: #afbac4;
}

.label-filter {
  font-size: 14px;
  color: #7d848b;
}

.table-single-search {
  width: calc(100vw - 100px);
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 50px;
}

/* tr.v-data-table__expanded__content {
    box-shadow: none !important;
} */
</style>